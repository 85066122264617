import React from "react";
import {useHistory, useLocation} from "react-router";
import {useDispatch} from "react-redux";
import {
    GET,
    useCrudFetch,
    useGlobalParam,
    notificationShowBanner,
    WARNING_NOTIFICATION
} from "@cuda-react/core";

// import makeStyles from "@material-ui/core/styles/makeStyles";
import {SCsIcon} from "@cuda-react/icons";
import AppBarMenuButton from "./AppBarMenuButton";

import {useOnCrudComplete, useCurrentAccountId} from 'hooks';
import {getCrudArrayData} from 'utils';

// const styles = makeStyles((theme) => ({

// }));

export const AppBarWorkspaceSelector = (props) => {
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();
    // const classes = styles();

    const currentAccountId = useCurrentAccountId();

    // persistent, may not be valid, need to be validated and updated
    const [currentWorkspaceId, setCurrentWorkspaceGlobalParam] = useGlobalParam("workspace");
    
    const [userData, ] = useGlobalParam('userData');
    const isAuthenticated = !!userData;
    const isOnWorkspacesScreen = location.pathname && location.pathname.startsWith("/workspace");

    const [loadWorkspaceStatus, , loadWorkspaces] = useCrudFetch(GET, 'workspaces', {filter: {account: currentAccountId}}, true);
    const workspaces = getCrudArrayData(loadWorkspaceStatus);
    const currentWorkspace = workspaces.find((w) => {return w.id === currentWorkspaceId});

    const setCurrentWorkspaceId = (id) => {
        // Note: setting workspace to the same id triggers an infinite loop: a crud reload which will re-select
        // the same workspace, which triggers a reload and so on
        if(currentWorkspaceId !== id) {
            setCurrentWorkspaceGlobalParam(id);
        }
    };

    const onWorkspaceSelect = (w) => {
        setCurrentWorkspaceId(w.id);
    };

    // handle workspaces response
    useOnCrudComplete(() => {
        if(!loadWorkspaceStatus.error) {
            // finished fetching
            if(!workspaces.length) {
                setCurrentWorkspaceId(null);
                if(!isOnWorkspacesScreen) {
                    dispatch(notificationShowBanner("You have no workspaces. Please create a workspace before continuing.", WARNING_NOTIFICATION));
                    // history.push("/workspace");
                }
            } else {
                // select the first available workspace
                if(!currentWorkspace) {
                    setCurrentWorkspaceId(workspaces[0].id);
                }
            }
        } else {
            setCurrentWorkspaceId(null);
        }
    }, loadWorkspaceStatus);

    // reload workspaces when user logs back in
    React.useEffect(() => {
        if(isAuthenticated && currentAccountId) {
            loadWorkspaces();
        }
    }, [isAuthenticated, currentAccountId]);

    const getDisplayName = (w) => {
        let name = w.name;
        if(w.role === 'ReadOnly') {
            name += ' (Read Only)';
        }
        return name;
    };

    let items = ["Workspaces"];
    if(workspaces.length) {
        items = items.concat(workspaces.map((w) => {
            return {
                label: getDisplayName(w),
                selected: currentWorkspace && currentWorkspace.id === w.id,
                disabled: currentWorkspace && currentWorkspace.id === w.id,
                onClick: () => {onWorkspaceSelect(w)}
            }
        }));
    } else {
        items.push({
            label: 'No workspaces'
        });
    }

    items.push('-', {
        icon: SCsIcon,
        label: 'Manage Workspaces',
        onClick: () => { history.push("/workspace") }
    });

    return (
        <AppBarMenuButton
            label={workspaces.length ? (currentWorkspace ? getDisplayName(currentWorkspace) : 'None') : 'No workspaces'}
            icon={SCsIcon}
            items={items}
        />
    );
}

export default AppBarWorkspaceSelector;
