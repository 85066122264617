import { updateSettings } from "../actions";
import { get, set } from "lodash";
import { useDispatch, useSelector } from "react-redux";

// works just like global params except it doesn't trigger crud requests since global param
// changes triggers crud subscriptions to make requests. This gets reset on logout.
export const useSetting = (source, defaultValue) => {
    const dispatch = useDispatch();
    const value = useSelector((state) => {
        return get(state, "SettingsReducer" + (source ? "." + source : ""), defaultValue);
    });
    const updater = (value) => {
        return dispatch(updateSettings(source ? set({}, source, value) : value));
    };
    return [value, updater];
};