import React, {useEffect, useState} from "react";
// import {useDispatch} from 'react-redux';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// import LoadingBar from "components/common/LoadingBar";
import LoadingSpinner from "components/common/LoadingSpinner";

import {
    CREATE,
    useCrudFetch,
} from "@cuda-react/core";

import {
    // getTaskStatus, 
    // removeTask
} from 'actions/tasks';

import {
    useGetTask,
    useOnCrudComplete
} from 'hooks';

import {getCrudData, hasValue} from 'utils';


const styles = makeStyles({
    paramField: {
        marginBottom: 10
    },
});


const COMPLETED_STATUSES = ['succeeded', 'failed', 'success', 'failure', 'complete', 'completed'];

export const DeployWizardStep = (props) => {
    const {
        solution, // solution object
        deployment, // existing solution deployment
        refreshDeploymentStatus, // callback to reload step deployment status
        setDeploymentId, // callback to set the deployment after starting a new deployment

        stepInfo, // solution deployment info for this step        
        stepDeployment, // existing deployment for THIS STEP, not the solution deployment, so confusing!        
        
        onSubmit, // called when this step submits data
        isLoading, // true if the data required for this step is loading
        wizardProps,
    } = props;

    const classes = styles();
    // const dispatch = useDispatch();
    const isThisStepActive = props.active;
    const [formData, setFormData] = useState({});
    const {stepIndex, configs, updateStep} = wizardProps;

    // stepDeployment: holds existing deployment that can be in progress or has failed/succeeded
    // if it's in progress, we just keep refreshing the status
    const existingDeploymentState = stepDeployment ? String(stepDeployment.state).toLowerCase() : '';
    const isExistingDeploymentInProgress = existingDeploymentState !== '' && COMPLETED_STATUSES.indexOf(existingDeploymentState) === -1;
    const [refreshCounter, setRefreshCounter] = useState(0);
    const refreshTimeout = 15;

    // start a new deployment, which will return a task that we check
    const [deployStatus, isDeploying, deployToWorkspace] = useCrudFetch(CREATE, 'deployments');

    const [taskId, setTaskId] = React.useState('');    
    const deploymentTask = useGetTask(taskId);
    const deploymentTaskStatus = deploymentTask ? String(deploymentTask.status).toLowerCase() : '';
    const isDeploymentTaskInProgress = deploymentTaskStatus !== '' && COMPLETED_STATUSES.indexOf(deploymentTaskStatus) === -1;

    let requiredParams = [];
    if(stepInfo.params && Array.isArray(stepInfo.params)) {
        requiredParams = [...stepInfo.params];
    }
    
    // find submit button and get rid of it
    const hasSubmitButton = requiredParams.find(p => p.type === 'submit');  
    requiredParams = requiredParams.filter(p => p.type !== 'submit' && p.required !== false);
    // if there was a submit button or there's some required params, then we need to do an api submission
    const requireSubmit = solution.submit || Boolean(hasSubmitButton) || requiredParams.length > 0;

    // normalize requiredParams
    requiredParams = requiredParams.map((p) => {
        p.name = p.name || p.value; // api calls it "value", will be fixed later
        p.default = p.default || '';
        return p;
    });

    function handleFormChange(event, param) {
        let name = event.target.name;
        let value = String(event.target.value || '').replace(/^s+|\s+$/, '');
        
        if(name) {
            if(value !== '' && value !== undefined && value !== null ) {
                if(param && param.type) {
                    switch(param.type) {
                        case 'number':
                        case 'integer':
                            value = parseInt(value, 10);
                            if(isNaN(value)) {
                                value = 0;
                            }
                        break;
                        case 'float':
                            value = parseFloat(value);
                            if(isNaN(value)) {
                                value = 0.0;
                            }
                        break;
                    }
                }
            }

            formData[name] = value;
            setFormData({...formData});
        }
    }

    useEffect(() => {
        // basic form validation, if there's a value, it's validated
        let formValidated = true;
        requiredParams.forEach((p) => {            
            if(formData[p.name] === undefined || formData[p.name] === null || formData[p.name] === '') {
                formValidated = false;
            }
        });

        const newStepConfigs = {
            validated: !isLoading && !isExistingDeploymentInProgress && (!requireSubmit || !requiredParams.length || formValidated),
            // tell the wizard nav that this step will handle navigating to the next step
            willHandleNext: requireSubmit
        };
        updateStep(newStepConfigs);
    }, [requireSubmit, formData, isLoading, isExistingDeploymentInProgress, isThisStepActive]);

    // we check on the deployment status
    useEffect(() => {
        if(isExistingDeploymentInProgress) {
            if(isThisStepActive && typeof refreshDeploymentStatus === 'function') {
                let interval = setInterval(function() {
                    if(!isLoading) {
                        setRefreshCounter(refreshCounter => (refreshCounter + 1));
                    }
                }, 1000);
                return function cleanup() {
                    // console.log('cleanup');
                    clearInterval(interval);
                    setRefreshCounter(0);
                };
            }
        }
    }, [isExistingDeploymentInProgress, isThisStepActive]);

    useEffect(() => {
        if(refreshCounter > 0 && refreshCounter % refreshTimeout === 0) {
            refreshDeploymentStatus();
            setRefreshCounter(0);
        }    
    }, [refreshCounter]);

    function manualRefresh() {
        if(typeof refreshDeploymentStatus === 'function') {
            refreshDeploymentStatus();
            setRefreshCounter(0);
        }
    }
    

    // submission logic
    const submitData = () => {		
        // crudFetch expects this format, specifically the 'data' key
        let data = {
            id: deployment ? deployment.id : null,
            data: {
                step: stepInfo.stepNumber,
                solution: solution.id,
                ...formData
            }
        };
        if(typeof onSubmit === 'function') {
            onSubmit();
        }
        deployToWorkspace(data);
        updateStep({loading: true});
    };

    useOnCrudComplete(() => {
        let cleanupFn = null;
        if(!deployStatus.error && isThisStepActive) {
            const data = getCrudData(deployStatus);
            if(data.task) {
                // start tracking by deployment_id
                if(data.deployment_id) {
                    setDeploymentId(data.deployment_id);
                }

                // got a task id, use it to track status
                setTaskId(data.task);
                // dispatch(getTaskStatus(data.task)); //  no need to call this, all tasks are automatically monitored
                cleanupFn = () => {
                    // dispatch(removeTask(data.task)); 
                }
            } else {
                // we should have gotten a refresh id, this is a backup action
                // refresh the deployment status
                if(typeof refreshDeploymentStatus === 'function') {
                    refreshDeploymentStatus();
                }
            }
            // we're now synchronizing with the api, it will return the step
            // we should be at when we update the deployed resources
            // wizard.goNextStep();
        }
        if(cleanupFn) {
            return cleanupFn;
        }
    }, deployStatus);

    // listen for taskId to complete
    React.useEffect(() => {
        if(deploymentTask && !isDeploymentTaskInProgress) {            
            updateStep({loading: false});
            if(deploymentTaskStatus === 'success' 
              && typeof refreshDeploymentStatus === 'function') {
                // dispatch(removeTask(taskId));
                setTaskId('');
                // this would be the place to go to the next step, however
                // the wizard dialog will handle, we just need to refresh the deployment status
                refreshDeploymentStatus();
            }
        }
    }, [deploymentTask]);

    // listen for signal (from wizard nav) to go to next step
    useEffect(() => {
        if(configs.handleNext) {
            updateStep({handleNext: false});
            submitData();
        }
    }, [configs]);

    return (
        <div>      
            <Typography variant="h6" gutterBottom>{stepInfo.instructions}</Typography>
            <LoadingSpinner message="Existing deployment in progress..." loading={isExistingDeploymentInProgress} />
            <LoadingSpinner message="New deployment task started..." loading={!!isDeploying || isDeploymentTaskInProgress} />

            {stepDeployment && !isDeploymentTaskInProgress && <div style={{marginBottom: 30}}>
                {existingDeploymentState === 'failed' && <Typography variant="subtitle2" gutterBottom display="block" style={{color: 'red'}}>Deployment failed with the following details. Please try again.</Typography>}
                <pre style={{display: 'block', overflow: 'auto', maxHeight: 200, padding: 10, border: '1px solid #ccc', fontSize: '12px'}}>{JSON.stringify(stepDeployment, null, 2)}</pre>
            </div>}

            {deploymentTask && <div style={{marginBottom: 30}}>
                {deploymentTaskStatus === 'failure' && <Typography variant="subtitle2" gutterBottom display="block" style={{color: 'red'}}>Deployment task failed with the following details. Please try again.</Typography>}
                <pre style={{display: 'block', overflow: 'auto', maxHeight: 200, padding: 10, border: '1px solid #ccc', fontSize: '12px'}}>{JSON.stringify(deploymentTask, null, 2)}</pre>
            </div>}

            {!isExistingDeploymentInProgress && !isDeploymentTaskInProgress && <div>
                {/* Link to somewhere */ stepInfo.link && 
                    <div style={{marginBottom: '10px', marginTop: '10px'}}>
                        <Link target="_blank" rel="noreferrer" href={stepInfo.link.href}>
                            <Typography>{stepInfo.link.label || 'Click here'}</Typography>
                        </Link>
                    </div>
                }
                <div style={{marginBottom: '10px'}}>
                    <Typography variant="subtitle2">{requiredParams.length ? 'Fill out the following field(s):' : 'Click Next when you are ready to proceed.'}</Typography>
                </div>
                {requiredParams.map((param, i) => {
                    if(param.type === 'text' || param.type === 'string' || param.type === 'number' || param.type === 'integer') {
                        return <TextField variant="outlined" className={classes.paramField} key={i} label={param.label} type="text" name={param.name} value={hasValue(formData[param.name]) ? formData[param.name] : ''} onChange={(event) => {handleFormChange(event,param)}} fullWidth />
                    } else if(param.type === 'select') {
                        return <FormControl key={i} variant="outlined" className={classes.paramField} fullWidth>
                            <InputLabel>{param.label}</InputLabel>
                            <Select name={param.name} value={hasValue(formData[param.name]) ? formData[param.name] : ''} label={param.label} onChange={(event) => {handleFormChange(event,param)}}>
                                <MenuItem value=""><em>Select...</em></MenuItem>
                                {Object.keys(param.values).map(function(key, i) {
                                    return <MenuItem key={i} value={key}>{param.values[key]}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    } else {
                        return "Unknown required param type: " + JSON.stringify(param);
                    }
                })}
            </div>/* !isExistingDeploymentInProgress*/}

            {(isExistingDeploymentInProgress) && <Button color="primary" onClick={manualRefresh}>Refresh ({refreshTimeout - refreshCounter})</Button>}
        </div>
    );
};

export default DeployWizardStep;