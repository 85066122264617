import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';

import SearchString from "search-string";

import {
    GET,
    useCrudFetch,
    useDeepCompareEffect,
} from '@cuda-react/core';

import {
    useCurrentAccountId
} from 'hooks';

import {
    trim,
    getCrudArrayData
} from 'utils';


const pageStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'flex-start',
        width: '100%',
        height: 'calc(100vh - 225px)',
        minHeight: '400px',
    },
    graphPane: {
        height: '100%',
        flex: 1,
        position: 'relative',

        '& .loading': {
            position: 'absolute',
            zIndex: 10,
            top: 5,
            right: 5,
            bottom: 5,
            left: 5,
            backgroundColor: 'rgba(255,255,255,0.8)',
            padding: 10,
            paddingRight: 300
        }        
    },
    detailsPane: {
        width: 400,
        height: '100%',
        marginLeft: 10,
        padding: 10,
        boxSizing: 'border-box',
        overflow: 'auto',
    },
    graphContainer: {
        display: 'block',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        boxSizing: 'border-box',
    },
    toolbar: {
        position: 'absolute',
        top: 8,
        left: 8,
        padding: 5,
        '& .MuiDivider-root': {
            margin: '0 10px 0 0'
        }
    },
    searchInput: {
        width: 450,
        '& .MuiOutlinedInput-root': {
            height: 'auto', // override CudaTheme
            '& .MuiInputBase-input': {
                padding: 8
            }
        },
        // '& .MuiFormHelperText-root': {
        //     marginTop: -6
        // }
    },
    tooltip: {
        maxWidth: 'none',
        fontSize: '0.8em',
        fontWeight: 'normal',
        backgroundColor: '#222'
    },
}));


const AzureTypeIcons = {
    'x': '/images/icons/x.svg',
    'default': '/images/icons/Azure_logo.svg',
    'ResourceGroup': '/images/icons/Azure_ResourceGroups.svg',
    'Microsoft.Web/certificates': '/images/icons/Azure_Certificates.svg',
    'Microsoft.Compute/images': '/images/icons/Azure_ComputeImages.svg',
    'Microsoft.Compute/virtualMachines': '/images/icons/Azure_VirtualMachine.svg',
    'Microsoft.Compute/disks': '/images/icons/Azure_Disks.svg',
    'Microsoft.Compute/galleries': '/images/icons/Azure_GalleriesImages.svg',
    'Microsoft.Compute/galleries/images': '/images/icons/Azure_GalleriesImages.svg',
    'Microsoft.Compute/galleries/images/versions': '/images/icons/Azure_GalleriesImagesVersions.svg',
    'Microsoft.Cache/Redis': '/images/icons/Azure_Redis.svg',
    'Microsoft.CertificateRegistration/certificateOrders': '/images/icons/Azure_Certificates.svg',
    'Microsoft.ContainerRegistry/registries':  '/images/icons/Azure_ContainerRegistry.svg',
    'Microsoft.ContainerRegistry/registries/webhooks': '/images/icons/Azure_ContainerRegistry.svg',
    'Microsoft.DBforSQL/servers': '/images/icons/Azure_SQLDatabase.svg',
    'Microsoft.DBforMySQL/servers': '/images/icons/Azure_MySQLDatabase.svg',
    'Microsoft.KeyVault/vaults': '/images/icons/Azure_KeyVaults.svg',
    'Microsoft.Network/virtualNetworks': '/images/icons/Azure_VirtualNetworks.svg',
    'Microsoft.Network/networkWatchers': '/images/icons/Azure_NetworkWatcher.svg',
    'Microsoft.Network/networkInterfaces': '/images/icons/Azure_NetworkInterfaces.svg',
    'Microsoft.Network/networkSecurityGroups': '/images/icons/Azure_NetworkSecurityGroups.svg',    
    'Microsoft.Network/publicIPAddresses': '/images/icons/Azure_PublicIPAddresses.svg',
    'Microsoft.Network/virtualWans': '/images/icons/Azure_KeyVaults.svg',
    'Microsoft.Network/virtualHubs': '/images/icons/Azure_VirtualNetworks.svg',
    'Microsoft.Network/routeTables': '/images/icons/Azure_RouteTables.svg',
    'Microsoft.Storage/storageAccounts': '/images/icons/Azure_StorageAccounts.svg',
    'Microsoft.Web/serverFarms': '/images/icons/Azure_ServerFarm.svg',
    'Microsoft.Web/sites': '/images/icons/Azure_ServerFarm.svg',
};

const AwsTypeIcons = {
    'default': '/images/icons/Aws_Logo.svg',
    'region': '/images/icons/Aws_Cloud.svg',
    'instance': '/images/icons/Aws_EC2.svg',
    'ec2': '/images/icons/Aws_EC2.svg',
    'vpc': '/images/icons/Aws_Vpc.svg',
    's3': '/images/icons/Aws_S3.svg',
    'loadBalancer': '/images/icons/Aws_ClassicLoadBalancer.svg',
    'cache': '/images/icons/Aws_Cache.svg',
    'redis': '/images/icons/Aws_Redis.svg',
    'rds': '/images/icons/Aws_RDS.svg',
    'mysql': '/images/icons/Aws_MySql.svg',
    'lambda': '/images/icons/Aws_Lambda.svg',
    'dynamo': '/images/icons/Aws_DynamoDB.svg',
    'beanstalk': '/images/icons/Aws_Beanstalk.svg',
    'redshift': '/images/icons/Aws_Database_AmazonRedshift.svg',
};

export const Infrastructure = (props) => {
    const classes = pageStyles();
    const containerRef = React.useRef(null);
    const nodesRef = React.useRef([]);
    const graphRef = React.useRef(null);
    const currentAccountId = useCurrentAccountId();
    const [resourcesResponse, isLoadingResources, fetchResources] = useCrudFetch(GET, 'listResources', {filter: {account: currentAccountId}});
    const resources = getCrudArrayData(resourcesResponse);

    const [selectedResource, setSelectedResource] = React.useState(null);
    const onGraphClick = (nodeEl, node) => {
        setSelectedResource(node.resource || null);
    };

    const loadResources = () => {
        setSelectedResource(null);
        fetchResources();
    };

    const [resourceFilter, setResourceFilter] = React.useState('');

    // highlight specified nodes and mute all other nodes
	const highlightNodes = (nodeIds) => {
        const graph = graphRef.current;
        if(!graph) return;
		graph.blurNodes(null, true);
		graph.blurNodes(nodeIds, false);
		graph.focusNodes(nodeIds, true);
	};

	// remove all muting and hilighting
	const removeNodeHilighting = () => {
        const graph = graphRef.current;
        if(!graph) return;
		graph.blurNodes(null, false);
		graph.focusNodes(null, false);
    };

    // filters: an object of filter => value pairs
	const applyResourceFilters = () => {
        const graph = graphRef.current;
        if(!graph) return;
		
        let nodeIds = [];

        let resourceFilterLC = trim(resourceFilter).toLowerCase();

        // nothing to filter
        if(!resourceFilterLC) {
            removeNodeHilighting();
            return;
        }

        const search = SearchString.parse(resourceFilterLC);
        const searchTextSegments = search.getTextSegments()
        const searchConditions = search.getConditionArray();
        // console.log('search', searchTextSegments, searchConditions);

        let nodes = nodesRef.current;
        if(Array.isArray(nodes)) {
            nodes.filter((node) => {
                // no id or name can't use this node
                if(!node.id || !node.label) {
                    return false;
                }
                let label = String(node.label || '').toLowerCase();
                let resource = node.resource || {};

                // search requires all text and conditions be true, the first text or condition that is
                // false will disqualify this node
                for(let i = 0; i < searchTextSegments.length; ++i) {
                    let segment = searchTextSegments[i];
                    let segmentMatched = label.indexOf(segment.text) > -1;
                    if(segment.negated) {
                        segmentMatched = !segmentMatched;
                    }                    
                    if(!segmentMatched) {
                        return false;
                    }
                }

                for(let j = 0; j < searchConditions.length; ++j) {
                    let condition = searchConditions[j];
                    if(!resource[condition.keyword]) {
                        return false; // resource doesn't have this key
                    }
                    if(condition.value) {
                        let conditionMatched = String(resource[condition.keyword]).toLowerCase().indexOf(condition.value) > -1;
                        if(condition.negated) {
                            conditionMatched = !conditionMatched;
                        }
                        if(!conditionMatched) {
                            return false;
                        }
                    }
                }

                return true;

            }).forEach((node) => {
                nodeIds.push(node.id);
            });
        }

        // mute all nodes, unmuted & hilight matched nodes
        removeNodeHilighting();
        graph.blurNodes(null, true);
        graph.blurNodes(nodeIds, false);
        graph.focusNodes(nodeIds, true);
	};

	const clearResourceFilters = () => {
		removeNodeHilighting();
	};




    useDeepCompareEffect(() => {
        const container = containerRef.current;
        if(!container) {
            return;
        }
        if(!resources || !Array.isArray(resources) || !resources.length) {
            return;
        }

        // Root nodes
        let nodes = [{
            id: 'root',
            label: 'Infrastructure',
            icon: '/images/icons/cloud.svg',
            cls: 'root-node',
            draggable: false,
            clickable: false,
        }, {
            id: 'aws',
            label: 'AWS',
            icon: AwsTypeIcons.default,
            cls: 'primary-node',
            dragChildren: true,
        }, {
            id: 'azure',
            label: 'Azure',
            cls: 'primary-node',
            icon: AzureTypeIcons.default,
            dragChildren: true,
        }];
        
        let links = [
            {source: 'root', target: 'aws'},
            {source: 'root', target: 'azure'},
        ];

        let azureResourceGroups = [];
        let awsRegions = [];
        resources.forEach((resource) => {
            if(!resource.id) {
                return;
            }
            let cloudType =  String(resource.cloud).toLowerCase();

            if(cloudType === 'azure' && resource.resource_group) {
                if(azureResourceGroups.indexOf(resource.resource_group) === -1) {
                    azureResourceGroups.push(resource.resource_group);
                }
                nodes.push({
                    id: resource.id,
                    label: resource.name,
                    type: resource.type,
                    icon: AzureTypeIcons[resource.type] || AzureTypeIcons.default,
                    clickable: true,
                    resource: resource,
                });
                links.push({
                    source: resource.resource_group, 
                    target: resource.id
                });
            } else if(cloudType === 'aws' && resource.region) {
                if(awsRegions.indexOf(resource.region) === -1) {
                    awsRegions.push(resource.region);
                }
                nodes.push({
                    id: resource.id,
                    label: resource.name,
                    type: resource.type,
                    icon: AwsTypeIcons[resource.type] || AwsTypeIcons.default,
                    clickable: true,
                    resource: resource,
                });
                links.push({
                    source: resource.region, 
                    target: resource.id
                });
            } else {
                console.log("Bad resource", resource);
            }
        });

        awsRegions.forEach((region) => {
            nodes.push({
                id: region,
                label: region,
                icon: AwsTypeIcons['region'] || '',
                dragChildren: true,
                clickable: true,
                resource: {
                    type: 'AwsRegion',
                    name: region
                },
            });
            links.push({source: 'aws', target: region});
        });

        azureResourceGroups.forEach((resource_group) => {
            nodes.push({
                id: resource_group,
                label: resource_group,
                icon: AzureTypeIcons['ResourceGroup'] || '',
                dragChildren: true,
                clickable: true,
                resource: {
                    type: 'ResourceGroup',
                    name: resource_group
                },
            });
            links.push({source: 'azure', target: resource_group});
        });



        nodesRef.current = nodes;
        container.innerHTML = '';
		const graph = new window.d3fdGraph();
		graph.render(container, nodes, links);
		graph.onClick = onGraphClick;
        graphRef.current = graph;
    }, [resources]);

    React.useEffect(() => {
        // Save power, only load if resources have never been loaded. 
        // User must manually refresh
        if(!resourcesResponse) {
            loadResources();
        }
    }, []);

    React.useEffect(() => {        
        applyResourceFilters();        
    }, [resourceFilter]);

    return <div className={classes.container}>
        <Paper className={classes.graphPane} elevation={1}>
            <Paper className={'toolbar toolbar-left ' + classes.toolbar} elevation={2}>
                <IconButton 
                    style={{marginRight: 5}}
                    size="small" 
                    color="primary" 
                    title="Reload resources"
                    disabled={isLoadingResources > 0}
                    onClick={() => {loadResources();}}
                >
                    {isLoadingResources > 0 ? <CircularProgress size={20} /> : <RefreshIcon />}
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <div className="toolbar-item">
                    <TextField 
                        className={classes.searchInput}
                        placeholder="Search resource"
                        type="text"                        
                        value={resourceFilter}
                        onKeyUp={(event) => {
                            if(event.keyCode === 27) {
                                setResourceFilter('');
                            }
                        }}
                        onChange={(event) => {                            
                            setResourceFilter(event.target.value);
                        }}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: <InputAdornment position="end">                                
                                <IconButton
                                    color="primary"
                                    disabled={!Boolean(trim(resourceFilter))}
                                    onClick={() => {
                                        setResourceFilter('');
                                        clearResourceFilters();
                                    }}
                                >
                                    {Boolean(trim(resourceFilter)) ? <ClearIcon /> : <SearchIcon />}
                                </IconButton>
                                <Tooltip classes={{tooltip: classes.tooltip}} title="Search by resource name or attribute:value. Example: ip_address:1.2.3.4 -region:east">
                                    <IconButton color="secondary"><InfoIcon /></IconButton>
                                </Tooltip>
                            </InputAdornment>
                        }}
                    />
                </div>
            </Paper>
            <div ref={containerRef} className={classes.graphContainer}></div>
        </Paper>
        <Paper className={classes.detailsPane} elevation={1}>
            {selectedResource ? 
                <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                    <Typography variant="h6">{selectedResource.name || selectedResource.id}</Typography>
                    <div style={{flex: '1'}}>
                        <Table size="small">
                            <TableBody>
                                {Object.keys(selectedResource).sort().map((key, i) => {
                                    return <TableRow key={i}>
                                        <TableCell><strong>{key}</strong></TableCell>
                                        <TableCell>{selectedResource[key]}</TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </div>
                </div>
                : <Typography variant="body2">Select a resource to view its details here</Typography>
            }
        </Paper>
    </div>;
};

export default Infrastructure;