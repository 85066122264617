import React from 'react';
import {useRouteMatch} from 'react-router';

import {SCsIcon as WorkspacesIcon} from "@cuda-react/icons";
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { DataGrid } from '@material-ui/data-grid';

import RouterLink from 'components/common/RouterLink';
import Heading from 'components/common/Heading';
import Button from 'components/common/Button';

import DeploymentsDetailsDialog from './DeploymentsDetailsDialog';
import ConfirmDeleteDialog from "components/common/ConfirmDeleteDialog";
import OpenDialogButton from "components/common/OpenDialogButton";


import {
    ConnectedForm,
    TextInput,
    useCrudFetch,
    GET,
    DELETE
} from "@cuda-react/core";

import {
    getCrudArrayData,
    getCrudData,
    numberFormat
} from 'utils';

import { useOnCrudSuccess } from 'hooks';

const styles = makeStyles(theme => ({
    gridUndoCellRenderStyling: {
        display: 'block !important',
    }
}));

const sortModel = [{field: 'workspace', sort: 'asc'}];


export const WorkspacesPage = (props) => {
    const classes = styles();
    const match = useRouteMatch();

    const [workspaceResponse, workspaceStatus, fetchWorkspaces] = useCrudFetch(GET, 'adminWorkspaces');
    const workspacesArray = getCrudArrayData(workspaceResponse);

    let workspaceGridRows = [];
    if(workspacesArray && Array.isArray(workspacesArray)) {
        workspaceGridRows = workspacesArray.map((workspace, i) => {
            return {
                id: i+1,
                ...workspace
            }
        });
    }

    const [deploymentsData, setDeploymentsData] = React.useState(null);
    const viewDeployments = (data) => {
        setDeploymentsData(data);
    };

    const [workspaceToDelete, setWorkspaceToDelete] = React.useState('');;
    const [deleteWorkspaceStatus, isDeletingWorkspace, callDeleteWorkspace] = useCrudFetch(DELETE, 'adminWorkspaces', {id: workspaceToDelete});

    useOnCrudSuccess(() => {
        setWorkspaceToDelete('');
    }, deleteWorkspaceStatus);


    const workspacesGridColumns = [
        {
            field: 'workspace',
            headerName: 'Workspace',
            flex: true,
            renderCell: (params) => {
                const workspace = params.value;
                return <RouterLink  to={{pathname: `/admin/workspaces/${workspace}`, search: `?return=${match.path}`}}>{workspace}</RouterLink>;
            }
        }, {
            field: 'active', 
            headerName: 'Status', 
            width: 100,
            valueGetter: (params) => params.value ? 'Active' : 'Inactive',
            renderCell: (params) => {
                return params.value ? <span style={{color: '#090'}}>Active</span> : <span style={{color: '#aaa'}}>Inactive</span>;
            }
        }, {
            field: 'owner', 
            headerName: 'Owner', 
            width: 350
        }, {
            field: 'created',
            headerName: 'Created',
            width: 200,
        }, {
            field: 'expires',
            headerName: 'Expires',
            width: 200,
        }, {
            field: 'deployments_count', 
            headerName: 'Deployments', 
            type: 'number', 
            width: 150,
            cellClassName: classes.gridUndoCellRenderStyling,
            valueGetter: (params) => params.row.deployments.length,
            renderCell: (params) => {
                const deployments = params.row.deployments;
                const count = deployments.length;
                return count > 0 ? <Link component="button" onClick={() => {viewDeployments(deployments)}}>{count}</Link> : ' ';
            }
        }, {
            field: 'actions',
            headerName: 'Actions',
            width: 120,
            align: 'right',
            cellClassName: classes.gridUndoCellRenderStyling,
            headerAlign: 'right',
            renderCell: (params) => {
                const row = params.row;
                return <div>
                    {row.active ? <Button variant="text" color="danger" onClick={() => { setWorkspaceToDelete(row.workspace); }}>Delete</Button> : ''}
                </div>
            }
        }
    ];

    React.useEffect(() => {
        if(!workspaceResponse) {
            fetchWorkspaces();
        }
    }, []);


    return <React.Fragment>

        <DeploymentsDetailsDialog deployments={deploymentsData} open={Boolean(deploymentsData)} onClose={() => {setDeploymentsData(null);}} />
        <ConfirmDeleteDialog 
            open={Boolean(workspaceToDelete)} 
            message={workspaceToDelete ? `Delete workspace ${workspaceToDelete}?` : ''}
            handleClose={() => { setWorkspaceToDelete(''); }}
            onClose={() => { setWorkspaceToDelete(''); }}
            status={{deleting: isDeletingWorkspace > 0}}
            handleConfirm={() => {
                callDeleteWorkspace();
            }}
            />

        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Heading 
                    icon={WorkspacesIcon}
                    buttons={<Button onClick={fetchWorkspaces} disabled={workspaceStatus > 0} processing={workspaceStatus > 0}>Refresh</Button>}
                >Workspaces</Heading>
                <Card>                
                    <div style={{height: 600, width: '100%'}}>
                        <DataGrid 
                            loading={workspaceStatus > 0}
                            density="compact" 
                            rows={workspaceGridRows} 
                            columns={workspacesGridColumns} 
                            disableSelectionOnClick={true}
                            sortModel={sortModel}
                            pageSize={50} />
                    </div>
                </Card>
            </Grid>

            <Grid item xs={12}>
                <Heading>Grant User Workspace Access</Heading>
                <ConnectedForm
                    create
                    resource="adminWorkspaces"
                    form="admin-workspaces-form"
                >
                    <TextInput 
                        source="username" 
                        label="Username" 
                        isRequired 
                    />
                    <TextInput 
                        source="name"
                        label="Workspace"
                        isRequired />
                </ConnectedForm>
            </Grid>
        </Grid>
    </React.Fragment>
};

export default WorkspacesPage;
