// A generic dialog to display text with option to download

import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Typography from '@material-ui/core/Typography';
import Button from './Button';
import FileSaver from 'file-saver';

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF',
        '& > h2': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        '& button': {
            color: '#FFFFFF',
        },
    },
    content: {
        display: 'flex',
        alignItems: 'stretch',
        width: '50vw',
        maxWidth: '70vw',
        minWidth: 400,
        maxHeight: '70vh',
        minHeight: 400,
        padding: 0,        
        overflow: 'hidden',
        borderBottom: '1px solid #ccc',

        '& > textarea': {
            flex: 1,
            border: 'none',
            width: '100%',
            boxSizing: 'border-box'
        }
    }
}));

export default function TextViewerDialog(props) {
    const {
        title,
        text,
        download,
        downloadMime,
        handleClose,
        ...others
    } = props;
    const classes = useStyles();

    const handleDownload = () => {
        let blob = new Blob([text], {type: downloadMime || 'text/plain'});
        let filename = download;
        FileSaver.saveAs(blob, filename);
    };

    return (
        <Dialog maxWidth={false} {...others}>
            <DialogTitle className={classes.title}>{title}</DialogTitle>
            <DialogContent className={classes.content}>
                <textarea readonly="readonly">{text}</textarea>
            </DialogContent>
            <DialogActions>
                {download && <Button onClick={handleDownload} variant="contained" color="primary">Download</Button>}
                <Button onClick={handleClose} variant="contained" color="secondary">Close</Button>
            </DialogActions>
        </Dialog>
    );
}

TextViewerDialog.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    download: PropTypes.string, // file name to enable downloading
    downloadMime: PropTypes.string, // mime type of file to be downloaded
    // handle confirm is called when the confirm button is clicked, it is passed handleClose
    // so that you can close the dialog after deleting
    handleConfirm: PropTypes.func.isRequired,
};

TextViewerDialog.defaultProps = {
    title: "Dialog",
};
