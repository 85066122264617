import React from "react";

import {Tab, TabbedPage} from "@cuda-react/core";
import CloudsPage from "./clouds/CloudsPage";
import ThemePage from "./ThemePage";
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import ColorLensIcon from '@material-ui/icons/ColorLens';

export const SettingsPage = () => {
    return (
        <TabbedPage authenticated>
            <Tab
                hash="#clouds"
                label="Cloud Providers"
                icon={SubscriptionsIcon}
            >
                <CloudsPage />
            </Tab>
            <Tab
                hash="#theme"
                label="Color Scheme"
                icon={ColorLensIcon}
            >
                <ThemePage />
            </Tab>
        </TabbedPage>
    );
};

export default SettingsPage;