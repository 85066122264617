import {SET_LOCALSTORAGE, UPDATE_LOCALSTORAGE} from '../actions/localStorage';

export default (state = {}, action) => {
    if(action.type === SET_LOCALSTORAGE) {
        return {};
    }
    if(action.type === UPDATE_LOCALSTORAGE) {
        return {...state, ...(action.data || {}) };
    }
    return state;
}