import React from 'react';
import PropTypes from "prop-types";
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress'

const styles = makeStyles({
    spinner: {
        marginRight: 5
    }
});

const SmallSpinner = () => {
    const classes = styles();
    return <CircularProgress size={15} className={classes.spinner} />
};

// Basic nav buttons with validation
const WizardNav = ({wizard, ...props}) => {
    const totalSteps = wizard.totalSteps();
    const currentStep = wizard.currentStep();
    const isLastStep = wizard.isLastStep();
    const configs = wizard.getStepConfigs(currentStep);

    let canGoBack = currentStep > 0;
    let canSkip = !!configs.optional;
    let canGoForward = configs.validated;
    let isLoading = !!configs.loading;

    // if we don't need to validate, allow forward nav
    if(props.validate !== true) {
        canGoForward = true;
        canSkip = false; // no reason to skip anymore
    }

    // disable all nav if we're loading
    if(isLoading) {
        canGoForward = canSkip = false;
    }

    function handleNext() {
        if(configs.willHandleNext) {
            // this state will handle it, state must reset this flag to false after receiving
            wizard.updateStepConfigs(currentStep, {handleNext: true});      
        } else {
            wizard.goNextStep();
        }
    };

    function handleFinish() {
        if(props.onFinishBtnClick) {
            props.onFinishBtnClick();
        }
    };

    return (
        totalSteps > 0 &&
            <React.Fragment>
                {props.showFirstStep && <Button variant="contained" color="primary" disabled={currentStep === 0} onClick={() => {wizard.goFirstStep()}}>First</Button>}
                {props.showPreviousStep && <Button variant="contained" color="primary" disabled={!canGoBack} onClick={() => {wizard.goPrevStep()}}>Previous</Button>}
                {canSkip && !isLastStep && <Button variant="contained" color="primary" onClick={() => {wizard.skipStep()}}>Skip</Button>}
                {props.showNextStep && !isLastStep && <Button variant="contained" color="primary" disabled={!canGoForward} onClick={handleNext}>{isLoading && <SmallSpinner />}Next</Button>}
                {isLastStep && <Button variant="contained" color="primary" disabled={!canSkip && !canGoForward} onClick={handleFinish}>{isLoading && <SmallSpinner />}Finish</Button>}
                {props.showLastStep && <Button variant="contained" color="primary" disabled={!canGoForward || isLastStep} onClick={() => {wizard.goLastStep()}}>{isLoading && <SmallSpinner />}Last</Button>}
            </React.Fragment>
    );
}


WizardNav.propTypes = {
    validate: PropTypes.bool, // require validation
    showFirstStep: PropTypes.bool,
    showLastStep: PropTypes.bool,
    showNextStep: PropTypes.bool,
    showPreviousStep: PropTypes.bool,
};

WizardNav.defaultProps = {
    validate: false,
    showFirstStep: false,
    showLastStep: false,
    showNextStep: true,
    showPreviousStep: true,
};

export default WizardNav;