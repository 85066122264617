import React from "react";

import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { overrideableStyles } from "@cuda-react/theme";

const styles = makeStyles(overrideableStyles("AppBarButton", function (theme) {
    return {
        root: {
            padding: 0,
            marginRight: 10,
            right: 4,
            textTransform: "none",
            "&:disabled": {
                opacity: 0.5
            },
            '& .MuiSvgIcon-root': {
                width: 20,
                height: 20,
                margin: theme.spacing(1.75, 1.15, 1.75, 1.5),
                fill: theme.palette.background.paper
            }
        },
        colors: {
            backgroundColor: theme.palette.background.appbar,
            color: theme.palette.background.paper,
            "&:disabled": {
                color: theme.palette.background.paper
            }
        }
    };
}));

export const AppBarButton = (props) => {
    const classes = styles();
    const {children, ...btnProps} = props;
    return <Button className={[classes.root, classes.colors].join(' ')} {...btnProps}>{children}</Button>;
};

export default AppBarButton;