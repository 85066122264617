import {SET_SETTINGS, UPDATE_SETTINGS} from '../actions/settings';

export default (state = {}, action) => {
    if(action.type === SET_SETTINGS) {
        return {};
    }
    if(action.type === UPDATE_SETTINGS) {
        return {...state, ...(action.settings || {}) };
    }
    return state;
}