/*
* To override settings in CudaReact, put an object in the overrides object with the name
* CR<name_that_you_pass_to_the_overrideableStyles_function>
*/
export const baseTheme = {
    name: 'Default',
    palette: {
        primary: {
          main: "#0088ce"
        },
    },
    colors: {
        lightBlue: '#A6D0E5',
        blue: '#0088CE',
        darkBlue: '#007AB8',
        lightGray: '#BFBFBF',
        gray: '#7F7F7F',
        darkGray: '#5F5F5F',
        green: '#95CC66',
        darkGreen: '#5BA71B',
        orange: '#EEAA48',
        darkOrange: '#F18E00',
        red: '#E34050',
        darkRed: '#DD1E31',
        darkerRed: '#C50B1D',
        fuchsia: '#C7117A',
        purple: '#7A44D5',
        white: '#FFFFFF',
        black: '#000000',
        lightGray1: '#F7F7F7',
        lightGray2: '#DDDDDD',
        lightGray3: '#DBDBDB'
    },
    overrides: {
        CRTabs: {
            slider: {
                fontSize: '16px'
            }
        },
        CRDeleteDialog: {
            ok: {
                backgroundColor: '#b00',
                '&:hover': {
                    backgroundColor: '#900'
                }
            }
        },
        MuiOutlinedInput: {
            root: {
                "&.MuiInputBase-multiline": {
                    height: 'auto'
                },
            }
        },
        CRFooter: {
            barracudaLogo: {
                '& path:first-child': {
                    fill: '#0088ce !important'
                }
            }
        },
        CRAppBar: {
            muiAppBar: {
                // backgroundColor: '#e20074'
            },
            icon: {
                '& path:first-child': {
                    fill: '#0088ce !important'
                }
            }
        }
    }
};


export const barracudaBlueTheme = {
    name: 'Barracuda Blue',
    palette: {
        primary: {
            main: '#0088ce'
        }
    }
};

export const blueTheme = {
    name: 'Blue',
    palette: {
        primary: {
            main: '#2D509F'
        }
    }
    
};


export const pinkTheme = {
    name: 'Pink',
    palette: {
        primary: {
            main: '#e20074'
        }
    }
    
};

export const greenTheme = {
    name: 'Green',
    palette: {
        primary: {
            main: '#5BA71B'
        }
    }
    
};

export const lightBlueTheme = {
    name: 'Light Blue',
    palette: {
        primary: {
            main: '#1ecbe1'
        }
    }
    
};

export const orangeTheme = {
    name: 'Orange',
    palette: {
        primary: {
            main: '#ff8e00'
        }
    }
    
};


export const themeMapping = {
    'default': baseTheme,
    barracudaBlue: barracudaBlueTheme,
    blue: blueTheme,
    lightBlue: lightBlueTheme,
    pink: pinkTheme,
    green: greenTheme,
    orange: orangeTheme
};

export default baseTheme;