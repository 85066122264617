import React from 'react';
import {useRouteMatch} from 'react-router';
import { DataGrid } from '@material-ui/data-grid';
// import Link from '@material-ui/core/Link';

import RouterLink from 'components/common/RouterLink';
import OpenDialogButton from "components/common/OpenDialogButton";
import ConnectInstructionsDialog from './ConnectInstructionsDialog';
import ResourceDetailsDialog from './ResourceDetailsDialog';

export const ResourcesTable = ({resources, mode, ...props}) => {
    const routeMatch = useRouteMatch();

    let rows = resources.map((asset, i) => {
        return {
            ...asset,
            id: i + 1,
        };
    });

    let columns = [
        {
            field: 'name', 
            headerName: 'Name', 
            flex: true
        }, {
            field: 'type', 
            headerName: 'Type', 
            flex: true,
        }, {
            field: 'cloud', 
            headerName: 'Cloud', 
            width: 100,
        }, {
            field: 'region', 
            headerName: 'Region', 
            width: 100,
        }, {
            field: 'ip_address', 
            headerName: 'IP Address', 
            width: 120,
        }, {
            field: 'public_ip_address', 
            headerName: 'Public IP', 
            width: 120,
        }, {
            field: 'actions', 
            headerName: 'Actions', 
            width: 200,
            renderCell: (params) => {
                const asset = params.row;
                return <React.Fragment>
                    <OpenDialogButton variant="text" color="primary"  buttonLabel="Details" closeDialogPropName={['handleClose']}>
                        <ResourceDetailsDialog resource={asset} />
                    </OpenDialogButton>
                    {asset.connect === true &&
                    <OpenDialogButton variant="text" color="primary" buttonLabel="Connect" closeDialogPropName={['handleClose']}>
                        <ConnectInstructionsDialog resource={asset} />
                    </OpenDialogButton>}
                </React.Fragment>;
            }
        }
    ];

    if(mode === 'summary') {
        columns.unshift({
            field: '_deploymentName', 
            headerName: 'Deployment', 
            width: 200,
            renderCell: (params) => {
                const asset = params.row;
                return <RouterLink 
                        to={{pathname: routeMatch.url, hash: '#' + asset.deployment_id}}>
                        {asset._deploymentName}</RouterLink>
            }
        });
    }

    return <DataGrid
        density="compact"
        disableSelectionOnClick={true}
        pageSize={50}
        rows={rows} 
        columns={columns} />
};

export default ResourcesTable;