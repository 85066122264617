import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useRouteMatch } from 'react-router';
import { DataGrid } from '@material-ui/data-grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import RouterLink from 'components/common/RouterLink';

import {
    useCrudProps,
} from '@cuda-react/core';

import {
    numberFormat,
    getCrudArrayData
} from 'utils';

import Button from 'components/common/Button';

const styles = makeStyles(theme => ({
    gridContainer: {
        width: 850,
        height: 400
    },
    grid: {
        borderRadius: 0,
        border: 'none'
    },
}));

const sortModel = [{field: 'cost', sort: 'desc'}];

export const DeploymentsDetailsDialog = ({deployments, ...props}) => {
    const classes = styles();
    const match = useRouteMatch(); 

    // --- load solutions so we can get full name of solutions
    const solutions = getCrudArrayData(useCrudProps("allSolutions"));
    let solutionNamesById = {};
    if(solutions) {
        solutions.forEach((solution) => {
            solutionNamesById[solution.id] = solution.name;
        });
    }

    const columns = [
        {
            field: 'deployment', 
            headerName: 'ID', 
            width: 70,
        }, {
            field: 'workspace', 
            headerName: 'Workspace', 
            flex: true,
            renderCell: (params) => {
                const record = params.row;
                const workspace = params.value;
                return record.active ? <RouterLink  to={{pathname: `/admin/workspaces/${workspace}`, search: `?return=${match.path}`}}>{workspace}</RouterLink> : `${workspace}`;
            }
        }, {
            field: 'solution', 
            headerName: 'Solution', 
            flex: true,
            renderCell: (params) => {
                const solutionId = params.value;
                return solutionNamesById[solutionId] || solutionId || 'N/A';
            }
        }, {
            field: 'cloud', 
            headerName: 'Cloud', 
            width: 110,
        }, {
            field: 'active', 
            headerName: 'Active', 
            width: 100,
            renderCell: (params) => {
                return params.value ? <span style={{color: '#090'}}>Active</span> : <span style={{color: '#aaa'}}>Inactive</span>;
            }
        }, {
            field: 'cost', 
            type: 'number',
            width: 120,
            headerName: 'Cost', 
            valueFormatter: (params) => {
                return '$' + numberFormat(params.value, 2);
            }
        }
    ];

    // grid requires rows to have id
    let rows = (deployments || []).map((row, i) => {
        if(!row.id) {
            return {
                id: i + 1,
                ...row
            }
        }
        return row;
    });

    return (
        <Dialog maxWidth="md" {...props}>
            <DialogTitle className="dialog-title">Deployments</DialogTitle>
            <div className={classes.gridContainer}>
                <DataGrid 
                    className={classes.grid}
                    density="compact" 
                    rows={rows} 
                    columns={columns} 
                    disableSelectionOnClick={true}
                    sortModel={sortModel}
                    pageSize={50} />
            </div>
            <DialogActions>
                <Button onClick={props.onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeploymentsDetailsDialog;