import React from "react";
import PropTypes from "prop-types";

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import WizardDialog from "components/common/WizardDialog";
import LoadingBar from "components/common/LoadingBar";
import DeployWizardStep from "./DeployWizardStep";

import {
    GET,
    useCrudFetch,
} from "@cuda-react/core";

import {useOnCrudComplete} from 'hooks';
import {getCrudData, getCrudArrayData} from 'utils';

const LastStep = () => {
    return <React.Fragment>
        <Typography variant="h6" gutterBottom>Solution Deloyed</Typography>
        <Typography variant="body1" component="p">This solution has been successfully deployed.</Typography>
    </React.Fragment>
}


// deployment: existing solution deployment object or deployment id
// solution: solution object or solution id
export const DeployWizardDialog = ({deployment, solution, ...wizardDialogProps}) => {
    const isDeploymentDirtyRef = React.useRef(false);
    const wizardActiveStepRef = React.useRef(0); // wizard step, base 0
    const [newDeploymentId, setNewDeploymentId] = React.useState(0);

    let solutionId = '';
    if(typeof solution === 'string') {
        solutionId = solution;
        solution = null;
    }
    const [solutionsResponse, isLoadingSolutions, fetchSolutions] = useCrudFetch(GET, "solutions", {id: solutionId});
    solution = solutionId && !solution ? getCrudData(solutionsResponse) : solution;

    // same thing as solutions, but for deployment
    let deploymentId = newDeploymentId;
    if(deployment) {
        if(typeof deployment === 'string') {
            deploymentId = deployment;
            deployment = null; // trigger load
        } else if(deployment.id) {
            deploymentId = deployment.id
        }
    }
    const [deploymentStatusReponse, isLoadingDeploymentStatus, fetchDeploymentStatus] = useCrudFetch(GET, "deployments", {id: deploymentId}, true);
    if(deploymentId && (!deployment || isDeploymentDirtyRef.current)) {        
        deployment = getCrudData(deploymentStatusReponse);
    }

    // load cloud subscriptions list
    const [cloudsResponse, isLoadingClouds, fetchClouds] = useCrudFetch(GET, 'clouds');
    // const cloudSubscriptions = getCrudArrayData(cloudsResponse);

    let deployedAssets = [];
    let deployedSteps = {}; // deployments keyed by 'step'+index
    let wizardNavProps = {validate: true, showPreviousStep: false}; // disable going back, the deployed status will determine which step to display

    if(deployment) {
        // gather assets & steps
        if(deployment.step) {
            wizardActiveStepRef.current = deployment.step-1;
        }

        const deployedStepsArray = deployment.steps || deployment.deployments || []; // used to be called 'deployments', now property named 'steps'
        if(Array.isArray(deployedStepsArray)) {
            deployedStepsArray.forEach((step, i) => {
                if(step.step) { // step.step is the step number, base 1
                    // this is the latest step
                    if(step.step > (wizardActiveStepRef.current + 1)) {
                        wizardActiveStepRef.current = step.step - 1;
                    }
                    // store deployment status by step index
                    deployedSteps['step' + (step.step-1)] = step;
                }
                if(step.assets) {
                    step.assets.forEach((asset, j) => {
                        deployedAssets.push(asset);
                    })
                }
            })
        }

        const currentStep = deployedSteps['step'+wizardActiveStepRef.current] || null;
        if(currentStep && String(currentStep.state).toLowerCase() === 'succeeded') {
            // step completed, advance to next step
            wizardActiveStepRef.current += 1;
        }
    } else {
        wizardActiveStepRef.current = 0;
    }

    const refreshDeploymentStatus = () => {
        if(deploymentId) {
            fetchDeploymentStatus();
        }
    };

    const setDeploymentId = (id) => {
        setNewDeploymentId(id);
    };

    // reset the dirty since we have up-to-date deployment status
    useOnCrudComplete(() => {
        if(!deploymentStatusReponse.error) {
            isDeploymentDirtyRef.current = false;            
        }
    }, deploymentStatusReponse);

    const onDialogEnter = () => {
        wizardActiveStepRef.current = 0;
        // need to fetch or need to refresh?
        if(deploymentId && (!deployment || isDeploymentDirtyRef.current)) {
            fetchDeploymentStatus();
        }
        // a solution id was given, fetch its details
        if(solutionId && !solution) {
            fetchSolutions();
        }
        if(cloudsResponse === undefined) {
            fetchClouds();
        }
    }

    const onStepSubmit = () => {
        // the deployment is now "dirty" since a step has submitted data
        isDeploymentDirtyRef.current = true;
    }

    let deployedResourcesContent = <div>
        {Boolean(isLoadingDeploymentStatus || isLoadingSolutions || isLoadingClouds) && <div style={{padding: '15px 25px'}}>
            <LoadingBar message="Loading..." loading={true} />
        </div>}
        {deployedAssets.length > 0 && <div style={{padding: 25, borderBottom: '1px solid #d0d0d0'}}>
            <Typography variant="subtitle2" component="h4" gutterBottom>Deployed resources in this solution:</Typography>
            <TableContainer component={Paper} style={{borderBottom: '1px solid #ccc'}}>          
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Resource</TableCell>
                            <TableCell>Type</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody style={{maxHeight: 200, overflow: 'auto'}}>
                        {deployedAssets.map((asset, i) => {
                            return (
                                <TableRow key={i}>
                                    <TableCell>{asset.name}</TableCell>
                                    <TableCell>{asset.type}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>}
    </div>;

    let wizardSteps = [];
    if(solution && solution.steps) {
        const solutionSteps = solution.steps.map((step, index) => {
            // the api expect this step number, it's one based index.
            step.stepNumber = index + 1;
            return <DeployWizardStep 
                    key={index} 
                    label={step.label || null} 
                    solution={solution} 
                    deployment={deployment}
                    stepInfo={step} 
                    // wizardConfigs={{}}
                    isLoading={isLoadingDeploymentStatus}
                    onSubmit={onStepSubmit}
                    refreshDeploymentStatus={refreshDeploymentStatus}
                    setDeploymentId={setDeploymentId}
                    stepDeployment={deployedSteps['step'+index]} 
                />;
        });
        // wizardSteps.push(<FakeStep label="Select Subscription" step="1" wizardConfigs={{validated: true}} />);
        wizardSteps = wizardSteps.concat(solutionSteps);
        wizardSteps.push(<LastStep key={wizardSteps.length} label="Review" wizardConfigs={{name: 'lastStep', validated: true}} />);
    }

    // if a solution has only one step and it's done, the step isn't marked as complete by the api,
    // instead the deployment status is marked as 'complete'. If that's the case, advance to the last step
    if(deployment && String(deployment.status).toLowerCase() === 'complete') {
        if(solution.steps && solution.steps) {
            wizardActiveStepRef.current = wizardSteps.length;
        }
    }

    // initial loading is whenever we open the dialog and have to load things that will change the wizard.
    // use this flag to hide everything until all the data is ready
    const initialLoading = (!deployment || isDeploymentDirtyRef.current) 
        && (isLoadingDeploymentStatus || isLoadingSolutions || isLoadingClouds);
        
    return (
        <WizardDialog 
            title={solution ? solution.name : (deployment ? deployment.solution : '')} 
            onEnter={onDialogEnter}
            //onExit, note to self: onExit doesn't fire because this instance gets destroyed, see SolutionsPage
            beforeStepperContent={deployedResourcesContent} 
            showStepper={!initialLoading}
            cancelButtonLabel="Close"
            wizardProps={{activeStep: wizardActiveStepRef.current}}
            wizardNavProps={wizardNavProps} {...wizardDialogProps}>
                {initialLoading ? null : wizardSteps}
        </WizardDialog>
    );
};

DeployWizardDialog.propTypes = {
    solution: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};

export default DeployWizardDialog;