import React from "react";
// import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';

export const ResourceDetailsDialog = ({resource, ...props}) => {
    // const classes = pageStyles();

    // Note to self: this dialog is given a callback 'handleClose'. Call it to close the dialog.
	return (
        <Dialog maxWidth="md" open={props.open} onClose={props.handleClose || null}>
            <DialogTitle className="dialog-title">Resource Details</DialogTitle>
            <div className="code-box" style={{width: 650, minHeight: 300}}>{JSON.stringify(resource, null, 2)}</div>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={props.handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
	);
};

export default ResourceDetailsDialog;