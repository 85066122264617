import React from 'react';
import PropTypes from "prop-types";
import makeStyles from '@material-ui/core/styles/makeStyles';

const styles = makeStyles({
    step: {
        display: 'none',
    },
    active: {
        display: 'block'
    }
});

const WizardStep = ({active, ...props}) => {
    const classes = styles();
    return <div className={[classes.step, active ? classes.active : ''].join(' ')}>
        {props.children}
    </div>
}

WizardStep.propTypes = {
    active: PropTypes.bool
}
WizardStep.defaultProps = {
    active: false
}

export default WizardStep;