import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useRouteMatch } from 'react-router';

import { DataGrid } from '@material-ui/data-grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from 'components/common/Button';
import RouterLink from 'components/common/RouterLink';

import {
    numberFormat,
} from 'utils';

const styles = makeStyles(theme => ({
    gridContainer: {
        width: 800,
        height: 400
    },
    gridUndoCellRenderStyling: {
        display: 'block !important',
    },
    grid: {
        borderRadius: 0,
        border: 'none'
    },
}));

const sortModel = [{field: 'cost', sort: 'desc'}];

export const WorkspacesDetailsDialog = ({workspaces, ...props}) => {
    const classes = styles();
    const match = useRouteMatch();    

    const columns = [
        {
            field: 'workspace', 
            headerName: 'Workspace', 
            flex: true,
            renderCell: (params) => {
                const record = params.row;
                const workspace = params.value;
                return record.active ? <RouterLink  to={{pathname: `/admin/workspaces/${workspace}`, search: `?return=${match.path}`}}>{workspace}</RouterLink> : `${workspace}`;
            }
        }, {
            field: 'active', 
            headerName: 'Status', 
            width: 100,
            valueGetter: (params) => params.value ? 'Active' : 'Inactive',
            renderCell: (params) => {
                return params.value ? <span style={{color: '#090'}}>Active</span> : <span style={{color: '#aaa'}}>Inactive</span>;
            }
        }, {
            field: 'deployments_count', 
            headerName: 'Deployments', 
            type: 'number', 
            width: 150,
            cellClassName: classes.gridUndoCellRenderStyling,
            valueGetter: (params) => params.row.deployments.length,
            renderCell: (params) => {
                const deployments = params.row.deployments;
                return deployments.length;
            }
        }, {
            field: 'owner', 
            headerName: 'User', 
            flex: true
        }, {
            field: 'cost', 
            type: 'number',
            width: 120,
            headerName: 'Cost', 
            valueFormatter: (params) => {
                return '$' + numberFormat(params.value, 2);
            }
        }
    ];

    // grid requires rows to have id
    let rows = (workspaces || []).map((row, i) => {
        if(!row.id) {
            return {
                id: i + 1,
                ...row
            }
        }
        return row;
    });

    return (
        <Dialog maxWidth="md" {...props}>
            <DialogTitle className="dialog-title">Workspaces</DialogTitle>
            <div className={classes.gridContainer}>
                <DataGrid 
                    className={classes.grid}
                    density="compact" 
                    rows={rows} 
                    columns={columns} 
                    disableSelectionOnClick={true}
                    sortModel={sortModel}
                    pageSize={50} />
            </div>
            <DialogActions>
                <Button onClick={props.onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default WorkspacesDetailsDialog;