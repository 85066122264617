import React from "react";
// import {useDispatch} from 'react-redux';
import {useLocation} from "react-router";
import {useCrudMonitor, useGlobalParam} from '@cuda-react/core/lib/hooks';
import {parse} from 'query-string';

export * from './tasks';
export * from './globalData';
export * from './settings';
export * from './crud';
export * from './localStorage';

export const usePrevious = (value) => {
    var ref = React.useRef();
    var oldValue = ref.current;
    ref.current = value;
    return oldValue;
}

// load data required before UI render, returns true if loaded
export const usePreloadAppData = () => {
    // const dispatch = useDispatch();
    // workspaces are loaded by AppBarWorkspaceSelector, so we don't need to fetch, just monitor the status
    const [workspaceStatus] = useCrudMonitor('workspaces');
    const workspacesLoaded = workspaceStatus && workspaceStatus.loading === 0;

    return workspacesLoaded;
}

export const useQueryParams = () => {
    const location = useLocation();
    const query = parse(location.search);
    return typeof query === 'object' ? query : {};
};


// This is for getting cuda-react specific params from query string when working with
// ConnectedTables. It uses the same logic and returns the same format as in ConnectedTable.
export const useCudaReactQueryParams = ({itemsPerPage} = {itemsPerPage: 1000}) => {
    const query = useQueryParams();

    let filter = {};
    if(query.filter && typeof query.filter === "string") {
        filter = JSON.parse(query.filter);
        if(typeof filter !== 'object') {
            filter = {};
        }
    }

    let page = parseInt(query.page);
    if(isNaN(page)) {
        page = 1;
    }

    return {
        filter: filter,
        sort: {
            field: query.sort,
            order: query.order === 'ASC' ? 'ASC' : 'DESC'
        },
        pagination: {
            page: page,
            perPage: itemsPerPage
        }
    };
};

export const useCurrentAccountId = () => {
    const [defaultAccount, ] = useGlobalParam("userData.default_account");
    const [currentAccount, ] = useGlobalParam("currentAccount");
    return currentAccount || defaultAccount;
};