import React from "react";
import {BasicPage} from "@cuda-react/core";
import ColorLensIcon from '@material-ui/icons/ColorLens';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
// import FormLabel from '@material-ui/core/FormLabel';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from 'components/common/Button';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from '@material-ui/core/Typography';

import {themeMapping} from 'theme';

const style = makeStyles({
    group: {
        display: 'flex',
        flex: '1 1 0',
        marginBottom: 20,
        alignItems: 'center',
    },
    box: {
        width: 30,
        height: 30,
        marginRight: 10,
        display: 'inline-box',
        border: '1px solid #444',
    }
});

export const ThemePage = () => {
    const classes = style();
    const currentTheme = localStorage.getItem("theme");

    const [theme, setTheme] = React.useState(currentTheme);
    const handleChange = (event) => {
        setTheme(event.target.value);
    };

    const applyTheme = () => {
        localStorage.setItem('theme', theme);
        window.location.reload();
    };

    return <BasicPage
        title="Color Scheme"
        icon={ColorLensIcon}
        >
            <Card>
                <CardContent>
                    <FormControl component="fieldset">
                        <Typography>Choose color scheme</Typography>
                        <RadioGroup name="theme" value={theme} onChange={handleChange}>
                            {Object.keys(themeMapping).map((themeId, i) => {
                                const theme = themeMapping[themeId];
                                return <div className={classes.group} key={i}>
                                    <Radio value={themeId} /> 
                                    <div className={classes.box} style={{backgroundColor: themeId === 'default' ? '#fff' : theme.palette.primary.main}}></div>
                                    <Typography>{theme.name}</Typography>
                                </div>
                            })}
                        </RadioGroup>
                    </FormControl>
                    <div>
                        <Button variant="contained" color="primary" onClick={applyTheme}>Apply</Button>
                    </div>
                </CardContent>
            </Card>
        </BasicPage>
};

export default ThemePage;