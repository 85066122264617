import { all, takeLatest, put } from 'redux-saga/effects';
import tasksSagas from "./tasks";
import authSagas from "./auth";
import crudSagas from "./crud";

import {GET} from '@cuda-react/core/lib/clients';
import {crudFetch} from '@cuda-react/core/lib/actions';


function * initialPageLoadSaga() {
    // console.log('initial page load');
}

function * loadWorkspacesSaga() {
    yield put(crudFetch(GET, 'workspaces', {}, true));
}

export default function * rootSaga() {    
    yield all([
        takeLatest('INITIAL_PAGE_LOAD', initialPageLoadSaga),
        // debounce(10, 'LOAD_WORKSPACES', loadWorkspacesSaga),
        authSagas(),
        tasksSagas(),
        crudSagas()
    ]);
};