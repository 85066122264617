import React from 'react';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
//import StepButton from '@material-ui/core/StepButton';
//import Typography from '@material-ui/core/Typography';

import Wizard from "./Wizard";
import WizardNav from "./WizardNav";

// Works like the Wizard component except the steps are open in a dialog
class WizardDialog extends React.Component {

    constructor(props) {
        super(props);
        this.state = {wizard: null};
    }

    onDialogEnter() {
        if(this.props.onEnter) {
            this.props.onEnter();
        }
    }

    setWizard(wizard) {
        this.setState({wizard});
    }

    render() {
        const {
            classes, 
            children,
            title,
            showStepper,
            showCancelButton,
            cancelButtonLabel,
            handleCancel, 
            handleClose,
            handleFinish,
            beforeWizardContent,
            afterWizardContent,
            beforeStepperContent,
            afterStepperContent,
            onEnter,
            showNavigation,
            wizardNavProps,
            wizardProps,
            ...dialogProps} 
        = this.props;
        const {wizard} = this.state;

        const onCancelBtnClick = () => {
            if(handleCancel) {
                handleCancel();
            } else if(handleClose) {
                handleClose();
            }
        }

        const onFinishBtnClick = () => {
            if(handleFinish) {
                handleFinish();
            } else if(handleClose) {
                handleClose();
            }
        }

        if(wizardProps.setWizard) {
            const existingSetWizard = wizardProps.setWizard;
            wizardProps.setWizard = function(wizard) {
                this.setWizard(wizard);
                existingSetWizard(wizard);
            }.bind(this);
        } else {
            wizardProps.setWizard = this.setWizard.bind(this);
        }

        return (
            <Dialog maxWidth="md" onEnter={this.onDialogEnter.bind(this)} {...dialogProps}>
                {title && <DialogTitle className={classes.title}>{title}</DialogTitle>}
                {beforeStepperContent}
                {wizard && showStepper && (<Stepper activeStep={wizard.currentStep()} alternativeLabel className={classes.stepper}>
                    {wizard.getAllStepConfigs().map((configs, index) => {
                        return (
                            <Step key={'step'+index}>
                                <StepLabel>{configs.label || 'Step ' + (index+1)}</StepLabel>
                            </Step>
                        )
                    })}
                </Stepper>)}
                {afterStepperContent}
                <DialogContent className={classes.content}>
                    {beforeWizardContent}
                    <Wizard {...wizardProps}>
                        {children}
                    </Wizard>
                    {afterWizardContent}
                </DialogContent>
                {wizard && showNavigation && (<DialogActions>
                    {showCancelButton && <Button variant="contained" color="secondary" onClick={onCancelBtnClick}>{cancelButtonLabel || 'Cancel'}</Button>}
                    <WizardNav wizard={wizard} onFinishBtnClick={onFinishBtnClick} {...wizardNavProps} />
                </DialogActions>)}
            </Dialog>
        );
    }

}

WizardDialog.propTypes = {
    title: PropTypes.string,
    showCancelButton: PropTypes.bool,
    cancelButtonLabel: PropTypes.string,
    showStepper: PropTypes.bool,
    wizardProps: PropTypes.object,
    showNavigation: PropTypes.bool,  
    wizardNavProps: PropTypes.object,

    // these get addedd before and after the Wizard component
    beforeWizardContent: PropTypes.element,
    afterWizardContent: PropTypes.element,
    beforeStepperContent: PropTypes.element,
    afterStepperContent: PropTypes.element,

    onEnter: PropTypes.func,
    handleCancel: PropTypes.func,
    handleClose: PropTypes.func,
    handleFinish: PropTypes.func,
};

WizardDialog.defaultProps = {
    showCancelButton: true,
    cancelButtonLabel: 'Cancel',
    showStepper: true,
    showNavigation: true,
    wizardProps: {},
    wizardNavProps: {validate: true},
};

const styles = theme => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF',
        '& > h2': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        '& button': {
            color: '#FFFFFF',
        },
    },
    stepper: {

    },
    content: {
        minWidth: 750,
        padding: 25,
    },
});

export default withStyles(styles, {name: 'WizardDialog'})(WizardDialog);