import React, { useState } from "react";
import { useDispatch, ReactReduxContext } from 'react-redux'
import { persistStore } from "@cuda-react/core/lib/reducers/localStoragePersist";

import {
    GET,
    // useCrudSubscription,
    // useCrudFetch,
    useGlobalParam,
    crudSubscribe,
    // crudSubscribeCancel,
} from "@cuda-react/core";

import {useCurrentAccountId} from 'hooks';

// This component is used to initialize the CudaReactApp. It's loaded as a "nonRouteChildren" so that we can do some stuffs
// that the cuda-react framework doesn't provide
const AppLoader = (props) => {
    const dispatch = useDispatch();

    // Subscribe to crud requests here because this component is persistent. Subscribing
    // in pages/components that get unmounted will just trigger initial fetch when they get
    // mounted again. Use useCrudFetch or useCrudMonitor in those components to get the data.

    // Only fetch if workspace and account id are not empty.
    // Can't use useCrudSubscription because it makes an initial fetch when we don't have a workspace or account id selected. Instead
    // use useEffect and state flag to subscribe once we have the necessary parameters.
    const currentAccountId = useCurrentAccountId();
    const [workspace, ] = useGlobalParam('workspace');
    const [userData, ] = useGlobalParam('userData');
    const [subscribedToSolutions, setSubscribedToSolutions] = useState(false);
    const [subscribedToDeployments, setSubscribedToDeployments] = useState(false);

    React.useEffect(() => {
        if(userData) {
            if(workspace) {
                if(!subscribedToSolutions) {
                    setSubscribedToSolutions(true);
                    dispatch(crudSubscribe(GET, 'solutions'));
                }

                if(!subscribedToDeployments) {
                    setSubscribedToDeployments(true);
                    dispatch(crudSubscribe(GET, 'listDeployments', {}, ['deployments', 'deployToWorkspace']));
                }
            }
        }
    }, [userData, workspace, currentAccountId]);
  
    
    return (        
        <React.Fragment>
            <ReactReduxContext.Consumer>
            {({ store }) => {
                persistStore(store, ["GlobalParamsReducer", "AuthReducer", "SettingsReducer", "LocalStorageReducer"]);
                store.dispatch({type: 'INITIAL_PAGE_LOAD'});
            }}
            </ReactReduxContext.Consumer>
        </React.Fragment>
    );
};

export default AppLoader;