// A generic delete confirmation dialog. 
import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from './Button';

const useStyles = makeStyles((theme) => ({
    title: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF',
        '& > h2': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        '& button': {
            color: '#FFFFFF',
        },
    },
    confirmBtn: {
        backgroundColor: theme.colors.darkRed,
        color: theme.colors.white,
        '&:hover': {
            backgroundColor: theme.colors.darkerRed
        },
        '&:disabled': {
            backgroundColor: theme.colors.red
        }
    },
    dialogInnerContent: {
        minWidth: 400
    }
}));

export default function ConfirmDeleteDialog(props) {
    const {
        status,
        title,
        message,
        confirmLabel,
        handleClose,
        handleConfirm,
        ...others
    } = props;
    const classes = useStyles();

    const onConfirm = () => {
        if(handleConfirm) {
            handleConfirm(handleClose);
        }
    };

    return (
        <Dialog maxWidth="xs" {...others}>
            <DialogTitle className={classes.title}>{title}</DialogTitle>
            <DialogContent>
                <div className={classes.dialogInnerContent}>
                    <Typography>{message}</Typography>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained" color="secondary">Cancel</Button>
                <Button className={classes.confirmBtn} 
                    onClick={onConfirm} 
                    variant="contained" 
                    color="primary" 
                    autoFocus 
                    processing={Boolean(status.deleting)} 
                    disabled={Boolean(status.deleting)}>
                        {confirmLabel}
                    </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmDeleteDialog.propTypes = {
    status: PropTypes.object,
    title: PropTypes.string,
    message: PropTypes.string,
    confirmLabel: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
    // handle confirm is called when the confirm button is clicked, it is passed handleClose
    // so that you can close the dialog after deleting
    handleConfirm: PropTypes.func.isRequired,
};

ConfirmDeleteDialog.defaultProps = {
    title: "Confirm Delete",
    message: "Confirm delete?",
    confirmLabel: "Delete",
    status: {}, // pass addition status. For now only 'deleting' is supported. E.g. status={deleting: true}
};
