import React from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";

import makeStyles from "@material-ui/core/styles/makeStyles";
import Badge from '@material-ui/core/Badge';

import {
    notificationShowToast,
    ERROR_NOTIFICATION,
    OK_NOTIFICATION,
    INFO_NOTIFICATION
} from "@cuda-react/core";

import {useDeepCompareEffect} from '@cuda-react/core/lib/hooks'
import {useGetAllTasks} from 'hooks';
import {isEqual} from "lodash";
import {getTaskAction} from 'utils/';
import AppBarMenuButton from "./AppBarMenuButton";
import {taskStatusIcons} from 'iconMapping';

import {StatusGaugeIcon} from "@cuda-react/icons";


const styles = makeStyles((theme) => ({
    badge: {
        '&>.MuiBadge-badge': {
            top: 18,
            left: 5
        }
    }
}));

const getStatusIcon = (status) => {
    const s = String(status).toLowerCase();
    const icon = taskStatusIcons[s] || taskStatusIcons['default'];
    return icon;
};

export const AppBarTaskChecker = (props) => {
    const history = useHistory();
    const classes = styles();
    const dispatch = useDispatch();

    const showTaskCompletedNotification = (task) => {
        const taskStatus = task.status ? String(task.status).toLowerCase() : '';
        const taskAction = getTaskAction(task);
        let toastMsg = taskStatus === 'success' 
            ? `${taskAction} successfully completed`
            : `${taskAction} failed`;
        dispatch(notificationShowToast(toastMsg, taskStatus === 'success' ? OK_NOTIFICATION : ERROR_NOTIFICATION));
    };
    
    const showTaskStartedNotification = (task) => {
        const taskAction = getTaskAction(task);
        dispatch(notificationShowToast(`${taskAction} started`, INFO_NOTIFICATION));
    };

    const [tasks, setTasks] = React.useState({});
    const [changesCount, setChangesCount] = React.useState(0);
    const currentTasks = useGetAllTasks();
    useDeepCompareEffect(() => {
        if(currentTasks) {      
            Object.keys(currentTasks).forEach((taskId) => {
                const task = currentTasks[taskId];
                if(!task) return;
                const taskStatus = task.status ? String(task.status).toLowerCase() : '';
                if(!tasks[taskId]) {
                    setTasks({...tasks, [taskId]: task});
                    if(taskStatus === 'success' || taskStatus === 'failure') {
                        // task finished immediately
                        showTaskCompletedNotification(task);
                    } else {
                        showTaskStartedNotification(task);
                    }
                    setChangesCount((count) => count +1);
                } else if(!isEqual(tasks[taskId], task)) {
                    setTasks({...tasks, [taskId]: task});
                    if(taskStatus === 'success' || taskStatus === 'failure') {
                        showTaskCompletedNotification(task);
                        setChangesCount((count) => count +1);
                    }
                }
            });
        }
    }, [currentTasks]);

    let tasksArray = Object.keys(tasks).map((taskId) => {
        return tasks[taskId];
    });
    tasksArray = tasksArray.reverse();
    tasksArray = tasksArray.slice(0, 20);

    // tasksArray = [
    //     {status: 'started', task_id: 1, action: 'deploy'},
    //     {status: 'success', task_id: 2, action: 'new-workspace'},
    //     {status: 'failure', task_id: 3, action: 'delete-workspace'},
    // ];

    let items = [];

    if(tasksArray.length) {
        items.push('Recent tasks');
        items = items.concat(tasksArray.map((task) => {
            return {
                label: getTaskAction(task),
                icon: getStatusIcon(task.status)
            }
        }));
    } else {
        items.push({
            label: 'No recent tasks',
        });
    }
    items.push('-', {
        icon: StatusGaugeIcon,
        label: 'View All Tasks',
        onClick: () => { history.push("/status") }
    });

    // Note: changeCount is accurate but might be confusing. If there's only 1 task, multiple updates to it
    // will show more than one changes, but the menu will only have one task shown. Resolve this later...
    return <Badge badgeContent={changesCount ? changesCount : null} color="primary" className={classes.badge} anchorOrigin={{vertical: 'top', horizontal: 'left'}}>
            <AppBarMenuButton
                label="Status"
                icon={StatusGaugeIcon}
                onClick={() => { setChangesCount(0)}}
                items={items} 
            />
            
        </Badge>
};

export default AppBarTaskChecker;