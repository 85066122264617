import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import FeedbackIcon from '@material-ui/icons/Feedback';
import Button from '@material-ui/core/Button';

import FeedbackDialog from './FeedbackDialog';

const styles = makeStyles((theme) => ({
    mainButton: {
        padding: 5,
        marginRight: 15,
        textTransform: "none",
        color: theme.palette.background.paper,
        "&:disabled": {
            opacity: 0.5
        }
    },
    mainButtonIcon: {
        marginRight: 5
    }
}));


export const AppBarFeedback = (props) => {
    const classes = styles();
    const [isDialogOpen, setDialogOpen] = React.useState(false);

    const handleBtnClick = () => {
        setDialogOpen(true);
    }

    const closeDialog = () => {
        setDialogOpen(false);
    }

    return <React.Fragment>
        <Button className={classes.mainButton} onClick={handleBtnClick}>
            <FeedbackIcon className={classes.mainButtonIcon} /> Feedback
        </Button>
        <FeedbackDialog open={isDialogOpen} handleClose={closeDialog} />
    </React.Fragment>
}

export default AppBarFeedback;