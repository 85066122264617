// This is basically a cut-n-paste of cuda-react's jsonClient because there's no way to extend it
// so we have to re-implement it. 

// This is so we can have a payload for DELETE requests.


import { get, merge } from "lodash";
import { stringify } from "query-string";
import { getDataContent,  ACTION, CREATE, DELETE, GET, UPDATE } from "@cuda-react/core";
export var AUTHENTICATION_FAILED = "authentication.failed";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }
function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var getQuery = function getQuery(params) {
  var queryMap = _objectSpread({}, params.filter || {});

  if (params.pagination) {
    queryMap.page = params.pagination.page - 1;
    queryMap.size = params.pagination.perPage;
  }

  if (params.sort && params.sort.field) {
    queryMap.sort = params.sort.field + "," + params.sort.order;
  }

  return Object.keys(queryMap).length > 0 ? "?".concat(stringify(queryMap)) : "";
};

var jsonHeader = {
  "Content-Type": "application/json;charset=UTF-8"
};
/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. "UPDATE"
 * @param {String} resourceUrl Name of the resource to fetch, e.g. "posts"
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} {url, options} The HTTP request parameters
 */

var convertRESTRequestToHTTP = function convertRESTRequestToHTTP(type, resourceUrl, params) {
  var endPoint = replaceEndPointParams(resourceUrl, params || {});
  var options = {
    method: "GET"
  };
  var query = getQuery(params);
  var url = "".concat(endPoint).concat(query);

  switch (type) {
    case GET:
      break;

    case ACTION:
      options.method = "PUT";
      options.headers = jsonHeader;
      break;

    case UPDATE:
      options.method = "PUT";
      options.headers = jsonHeader;
      options.body = JSON.stringify(params.data);
      break;

    case CREATE:
      options.method = "POST";
      options.headers = jsonHeader;
      options.body = JSON.stringify(params.data);
      break;

    case DELETE:
      options.method = "DELETE";
      options.headers = jsonHeader;
      options.body = JSON.stringify(params.data); // this is new
      break;

    default:
      throw new Error("Unsupported fetch action type ".concat(type));
  }

  return {
    url: url,
    options: options
  };
};
/**
 * @param {Object} json HTTP response json from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. "UPDATE"
 * @param {String} resource Name of the resource to fetch, e.g. "posts"
 * @param {Object} params The REST request params, depending on the type
 * @param {Object} reformatters A map of functions (with resource name as keys), that accept (type, {data}) and return {data} (used for reformatting data)
 * @returns {Object} REST response
 */


var convertHTTPResponseToREST = function convertHTTPResponseToREST(json, type, resource, params) {
  var reformatters = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  var data = json || {};
  var responseData = {
    data: data
  };

  if (Array.isArray(getDataContent(data))) {
    responseData.total = data && data.page && data.page.totalElements || getDataContent(data).length;
  }

  if (type === CREATE || type === UPDATE) {
    responseData.data = merge({}, params.data, data);
  }

  return reformatters && reformatters[resource] ? reformatters[resource](type, responseData, params) : responseData;
};

var replaceEndPointParams = function replaceEndPointParams(endpoint, params) {
  var regexParams = /{([^}]+)}/g;
  var matches = [];
  var match;

  while ((match = regexParams.exec(endpoint)) !== null) {
    if (match[1] && match[1] !== "") {
      matches.push(match[1]);
    }
  }

  matches.forEach(function (match) {
    var replacement = get(params, match);
    var paramPlaceholder = "{" + match + "}";

    if (replacement || replacement === 0) {
      endpoint = endpoint.replace(paramPlaceholder, replacement.toString());
    } else {
      endpoint = endpoint.replace(paramPlaceholder, "");
    }
  });
  endpoint = endpoint.replace(/\/*$/, "");
  return endpoint;
};
/**
 * Maps admin-on-rest queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 * @example
 * GET          => GET http : //my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * UPDATE       => PUT http : //my.api.url/posts/123
 * CREATE       => POST http : //my.api.url/posts/123
 * DELETE       => DELETE http : //my.api.url/posts/123
 */


export var jsonClient = function jsonClient(httpClient, resourceUrlMap, reformatters) {
  return function (type, resource, params, globalParams) {
    var mergedParams = merge({}, params, globalParams);

    if (!get(globalParams, "userData")) {
      throw AUTHENTICATION_FAILED;
    }

    var resourceUrl = resourceUrlMap[resource] || resource;

    var _convertRESTRequestTo = convertRESTRequestToHTTP(type, resourceUrl, mergedParams),
        url = _convertRESTRequestTo.url,
        options = _convertRESTRequestTo.options;

    return httpClient(url, options).then(function (response) {
      // Simply return empty object for "No Content" or empty responses
      if (response.status === 204 || response.headers.get("Content-Length") + "" === "0" || !response.headers.get("Content-Type")) {
        return {};
      }

      if (response.headers.get("Content-Type") && response.headers.get("Content-Type").includes("application/json")) {
        return response.json();
      }

      return response.text();
    }).then(function (json) {
      if (typeof json === "string") {
        return {
          data: json
        };
      }

      return convertHTTPResponseToREST(json, type, resource, mergedParams, reformatters);
    });
  };
};
export default jsonClient;