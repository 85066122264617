import {parse} from 'query-string';

export const hasValue = (value) => {
    return value !== undefined && value !== null;
};

// Extract the data portion from cuda-react's crud status object.
// This is a rewrite of cuda-react's getDataContent because it has a 'bug' that causes
// it to return 'data' when it should be returning empty
export const getCrudData = (status, defaultData) => {
    if(hasValue(status)) {
        if(hasValue(status.data)) {
            return hasValue(status.data.content) ? status.data.content : status.data;
        }
        return hasValue(status.content) ? status.content : defaultData;
    }
    return defaultData;
}

// Same, this returns an empty array if the data is NOT an array
export const getCrudArrayData = (status) => {
    const arrayData = getCrudData(status, []);
    return Array.isArray(arrayData) ? arrayData : [];
};


export const getQueryParams = () => {
    var reduxRouterPath = window.location.href.substring(window.location.href.indexOf("#") + 1);
    if(reduxRouterPath.indexOf("?") > -1) {
        return parse(reduxRouterPath.substring(reduxRouterPath.indexOf("?") + 1));
    }
    return {};
};

export const getTaskAction = (task) => {
    let taskAction = task.action || `Task "${task.task_id}"`;
    switch(taskAction) {
        case 'deploy': 
            taskAction = `Deploy solution "${task.solution}" (${task.deployment_id})`;
        break;
        case 'delete-deployment': 
            taskAction = `Remove deployment "${task.solution}" (${task.deployment_id})`; 
        break;
        case 'new-workspace': 
            taskAction = `Create workspace "${task.workspace}"`; 
        break;
        case 'delete-workspace':
            taskAction = `Delete workspace "${task.workspace}"`; 
        break;        
    }
    return taskAction;
};

export const roundNumber = (num, precision) => Number(Math.round(num + "e+" + precision) + "e-" + precision);

// https://locutus.io/php/strings/number_format/
export const numberFormat = (number, decimals, dec_point, thousands_sep) => {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const trim = (str) => {
    return String(str || '').replace(/^\s+|\s+$/, '');
}

export const shuffleArray = (array) => {
    var currentIndex = array.length, randomIndex;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
    return array;
};