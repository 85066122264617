import {
    AUTH_CHECK, 
    AUTH_LOGIN, 
    AUTH_LOGOUT,
    AUTH_ERROR
} from "@cuda-react/core";
import Api, {resourceUrlMap} from "services/api";
// import httpClient from "services/httpClient";
import {get} from "lodash";

export const LOGIN_URL = 'https://auth.barracudanetworks.com/oauth2/authorize?response_type=code&client_id=fWk94ew8ahkhD38V0cmaHoijUIMRpZIXM2pHD4hCyFZmyt5nGDVZ1q3L4k3DzTR&redirect_uri=https%3A%2F%2Fcec.cudasvc.com%2F%23%2Flogin&scope=openid+basic+email+session&state=XrsIN5Cig4Ns1xDHjbRa7oRzfCuXno&nonce=zRcDdXB9NftFWhURlqUd';

const parseToken = (token) => {
    var tokenData = null;
    try {
        var parts = String(token).split('.');
        if(parts[1]) {
            var obj = JSON.parse(window.atob(parts[1]));
            if(typeof obj !== 'object') {
                throw new Error("Decoded token is not valid JSON");
            }
            tokenData = obj;
        } else {
            throw new Error("No data at index 1. Probably invalid JWT format.");
        }
    } catch(error) {
        console.log("Token decoding error: ", error);
        throw error;
    }
    return tokenData;
};

// returns a promise that resolves with a redirect url (to a login screen)
export const getLoginRedirect = () => {
    return Api.get(resourceUrlMap.authRedirectUrl).then((data) => {
        let redirectUrl = LOGIN_URL;
        if(data && data.redirectURL) {
            redirectUrl = data.redirectURL;
        }
        return Promise.resolve({
            redirect: redirectUrl
        });
    }).catch((error) => {
        // workaround: /auth/redirect should not be returning 401, should be 200 all the time
        let redirectUrl = LOGIN_URL;
        if(error && (typeof error.body === 'object') && error.body.redirectURL) {
            redirectUrl = error.body.redirectURL;
        }
        return Promise.resolve({
            redirect: redirectUrl
        });
    });
};


export default (type, params, globalParams) => {

    // console.log("bccAuthClient:", type, 'params:', params, 'globalParams:', globalParams, window.location.href);

    if(type === AUTH_ERROR) {
        // console.log('uh oh, auth error', params, globalParams);

    } else if(type === AUTH_CHECK) {
        const userData = get(globalParams, 'userData');
        if(!userData) {
            return Promise.reject();
        }
        // todo: use refresh token
        const expiration = userData.expires_at;
        const currentTimestamp = Math.floor(new Date().getTime() / 1000);
        if(!expiration || expiration - currentTimestamp <= 0) {
            return Promise.reject();
        }
        return Promise.resolve();

    } else if(type === AUTH_LOGOUT) {
        // todo: determine BCC logout url based on environment
        return Promise.resolve({
            redirect: 'https://login.barracudanetworks.com/logout'
        });

    } else if(type === AUTH_LOGIN) {

        if(!params.code) {
            return getLoginRedirect();
        }

        // return new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //         reject({
        //             message: 'Fake error',
        //         });
        //     }, 3000);
        // });
        
        return Api.post(resourceUrlMap.auth, params).then((data) => {
            if(data.id_token) {
                try {
                    const identity = parseToken(data.id_token);
                    const userData = {
                        ...data,
                        identity: identity,
                        currentUser: identity.email,
                        apiToken: data.id_token,
                    }
                    return {
                        userData,
                    };
                } catch(error) {
                    console.log('Unable to parse token data', error.message);
                    return Promise.reject({message: "Unable to sign in", error: error});
                }                        
            } else {
                return Promise.reject({
                    message: 'No token in response data'
                });
            }
        }).catch((error) => {
            return Promise.reject({
                message: error ? error.message : 'HTTP Error',
                redirect: error.redirectUrl || error.redirect || LOGIN_URL
            });
        });

    } else {
        return Promise.reject({message: "Unknown Method"});
    }
    
}