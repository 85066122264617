import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { overrideableStyles } from "@cuda-react/theme";
import { useGlobalParam } from '@cuda-react/core';

const styles = makeStyles(overrideableStyles("AppBarCustomTitle", function (theme) {
    return {
        root: {
            height: 48,
            display: "flex",
            alignItems: 'center',
            flex: "1 1 0"            
        },
        hasLogo: {
            marginLeft: -64
        },
        title: {
            maxWidth: 280,
            color: '#fff',
        },
        customLogoContainer: {
            height: 48,
            minWidth: 100,
            marginLeft: -64,
            marginRight: 16,
            overflow: 'hidden'
        },
        logo: {            
            display: 'inline-block',
            height: 48
        }
    };
}));

const AppBarCustomTitle = () => {
    const classes = styles();
    const [logoData, ] = useGlobalParam('userData.appearance.logo');
    // const logoData = 1;

    return <div className={classes.root}>
        {logoData && 
            <div className={classes.customLogoContainer}>
                <img className={classes.logo} src={'data:image/png;base64,' + logoData} />
                {/* <img className={classes.logo} src="/images/logos/tmobile.png" /> */}
            </div>
        }
        <Typography variant="subtitle1" justify="center" className={classes.title}>
            Customer Experience Center
        </Typography>
    </div>;
}

export default AppBarCustomTitle;