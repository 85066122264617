// Works just like Material UI's Button component, with some additional functionalities.
import React from 'react';
import PropTypes from "prop-types";

import { makeStyles } from '@material-ui/core/styles';
import MaterialButton from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    textDanger: {
        color: theme.colors.darkRed
    },
    containedDanger: {
        backgroundColor: theme.colors.darkRed,
        color: theme.colors.white,
        '&:hover': {
            backgroundColor: theme.colors.darkerRed
        },
        '&:disabled': {
            backgroundColor: theme.colors.red
        }
    },
    progress: {
        marginLeft: -5,
        marginRight: 5
    }
}));

export const Button = (props) => {
    const classes = useStyles();

    const {
        children,
        processing,
        color,
        ...others
    } = props;

    let buttonProps = {...others};

    // enable 'danger' color
    if(color === 'danger') {
        if(props.variant === 'text') {
            buttonProps.className = classes.textDanger;
        }
        if(props.variant === 'contained') {
            buttonProps.className = classes.containedDanger;
        }
    } else {
        buttonProps.color = color;
    }

    return <MaterialButton {...buttonProps}>
        {processing && <CircularProgress size={15} color="secondary" className={classes.progress} />}
        {children}
    </MaterialButton>
};


Button.propTypes = {
    processing: PropTypes.bool,
};

Button.defaultProps = {
    variant: 'contained',
    color: 'secondary',
    processing: false
};

export default Button;