import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ListPage from "@cuda-react/core/lib/components/pages/ListPage";
import TextField from "@cuda-react/core/lib/components/fields/TextField";
import ActionButtonsField from "@cuda-react/core/lib/components/fields/ActionButtonsField";
import CustomField from "@cuda-react/core/lib/components/fields/CustomField";
import DeleteDialog from "@cuda-react/core/lib/components/dialog/DeleteDialog";
import {useCrudFetch} from "@cuda-react/core/lib/hooks";
import {SCsIcon, OkayIcon, TickIcon} from "@cuda-react/icons";
import RefreshResourceBtn from "components/common/RefreshResourceBtn";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {CreateWorkspaceBtn} from "./CreateWorkspaceForm";
import ActionButton from "components/common/ActionButton";

import {
    GET,
    CREATE,    
    DELETE,
    useGlobalParam
} from "@cuda-react/core";

// import {getTaskStatus, removeTask} from 'actions/tasks';
import {
    useGetCompletedTask, 
    useCurrentAccountId
} from 'hooks';

const styles = makeStyles({
    statusCell: {
        width: 25
    },
});

export const WorkspacesPage = (props) => {
    const classes = styles();
    
    const currentAccountId = useCurrentAccountId();
    const [currentWorkspaceId, setCurrentWorkspaceId] = useGlobalParam("workspace");
    const [, , fetchWorkspaces] = useCrudFetch(GET, 'workspaces', {filter: {account: currentAccountId}});

    // use this hook to get specific crud action status
    const [createResult, isCreating, ] = useCrudFetch(CREATE, 'workspaces');
    const [taskId, setTaskId] = React.useState('');
    const taskStatus = useGetCompletedTask(taskId);

    // we need the id to keep track of crud status, so we need to remember it
    const [workspaceIdtoDelete, setWorkspaceIdToDelete] = React.useState('');
    const [deleteResult, isDeleting, callDeleteWorkspace] = useCrudFetch(DELETE, 'workspaces', {id: workspaceIdtoDelete});

    React.useEffect(() => {
        if(workspaceIdtoDelete) {
            callDeleteWorkspace();
        }
    }, [workspaceIdtoDelete]);

    const deleteWorkspace = (id) => {
        setWorkspaceIdToDelete(id);
    };

    // because workspace action is  not immediate, we need to rely on the taskId
    // returned to know when it's done
    React.useEffect(() => {
        if(!isCreating && createResult && !createResult.error && createResult.data) {
            if(createResult.data.task) {
                setTaskId(createResult.data.task);
            }
        }
    }, [isCreating]);

    React.useEffect(() => {
        if(!isDeleting && deleteResult && !deleteResult.error && deleteResult.data) {            
            if(deleteResult.data.task) {
                setTaskId(deleteResult.data.task);
            }
        }
    }, [isDeleting]);

    // listen for taskId to complete
    React.useEffect(() => {
        if(taskStatus) {
            fetchWorkspaces();
            setTaskId('');
        }
    }, [taskStatus]);

    const onKlick = (event, data) => {
        setCurrentWorkspaceId(data.id);
    }

    const usersColumnRender = (users) => {
        const rows = users.map((user, i) => {
            return typeof user === 'object' 
                ? <li key={i}>{user.username} ({user.role})</li> 
                : <li key={i}>{user === '*' ? 'Everyone' : user}</li>;
        });
        return rows.length ? <ul className="flat">{rows}</ul> : '';
    };

    return (
        <ListPage		
            authenticated
            icon={SCsIcon}
            resource="workspaces"
            title="Workspaces"
            refreshInterval={0}
            paginate={false}
            params={{filter: {account: currentAccountId}}}
            actions={[<CreateWorkspaceBtn />, <RefreshResourceBtn resource="workspaces" params={{filter: {account: currentAccountId}}} />]}
            noRouter={true}

        >
            <CustomField
                source="id"   
                cellClassName={classes.statusCell}                 
                render={(id) => id === currentWorkspaceId ? <OkayIcon /> : ''}
            />
            <TextField source="name" label="Workspace Name" />
            <TextField source="role" label="Role" />
            <CustomField source="shared" label="Shared" render={(shared) => shared === true ? <TickIcon /> : ''} />
            <CustomField source="users" label="Privileges" render={usersColumnRender} />
            <ActionButtonsField>                                
                <DeleteDialog
                    resource="workspaces"
                    title="Confirm Delete"
                    onDelete={(data) => deleteWorkspace(data.id)}
                    message="Delete workspace {{name}}?"
                />
                <ActionButton 
                    // tooltip="Switch to this workspace"
                    icon={<ArrowForwardIcon />}
                    disabled={(data) => data.id === currentWorkspaceId}
                    onKlick={onKlick} 
                />
            </ActionButtonsField>
        </ListPage>
    );
}

export default WorkspacesPage;
