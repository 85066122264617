import React from "react";
import {useLocation, useHistory} from 'react-router';
import { stringify } from 'query-string';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useCrudFetch} from "@cuda-react/core/lib/hooks";
import {GET} from '@cuda-react/core/lib/clients';

import Toolbar from "@cuda-react/core/lib/components/layout/Toolbar"; 
import DialogBody from "@cuda-react/core/lib/components/dialog/DialogBody";

import MUITextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import Dialog from '@material-ui/core/Dialog';
import Card from '@material-ui/core/Card';
import { DataGrid } from '@material-ui/data-grid';

import Button from "components/common/Button";

import {taskStatusIcons} from 'iconMapping';

import {
    BasicPage,
    useCrudProps,
    useDeepCompareEffect,
} from "@cuda-react/core";

import {
    StatusGaugeIcon
} from "@cuda-react/icons";


import {
    getTaskAction, 
    getCrudArrayData
} from 'utils';

import { useQueryParams } from 'hooks';

const sortModel = [{field: 'date_started', sort: 'desc'}];

const styles = makeStyles(theme => ({
    dialogContent: {
        maxWidth: 800,
        overflow: 'auto'
    },
    statusCell: {
        width: 110,
        '& .MuiSvgIcon-root': {
            height: 18,
            width: 18,
            marginRight: 5
        }
    },
}));

export const StatusPage = (props) => {
    const classes = styles();
    const location = useLocation();
    const history = useHistory();
    const response = useCrudProps('workspaces');
    const workspaces = getCrudArrayData(response);

    const queryParams = useQueryParams();
    const [workspaceFilter, setWorkspaceFilter] = React.useState(queryParams.workspace);

    let filters = {};
    
    // only filter by days if it's set
    if(workspaceFilter) {
        filters.workspace = workspaceFilter;
    }

    const [taskResponse, fetchTaskStatus, fetchTasks] = useCrudFetch(GET, 'tasks', {filter: filters});
    const tasks = getCrudArrayData(taskResponse);
    

    useDeepCompareEffect(() => {
        location.search = '?'.concat(stringify(filters));
        history.push(location);
        fetchTasks();
    }, [filters]);

    const onWorkspaceSelectChange = (event) => {
        const value = event.target.value;
        const workspace = value && value !== ':all:' ? value : '';
        setWorkspaceFilter(workspace);
    };

    const [selectedTask, setSelectedTask] = React.useState(null);
    const showTaskDetails = (task) => {
        setSelectedTask(task);
    };
    const hideTaskDetails = () => {
        setSelectedTask(null);
    };

    const gridColumns = [
        {
            field: 'status', 
            headerName: 'Status', 
            width: 150,
            cellClassName: classes.statusCell,
            renderCell: (params) => {
                const status = params.value;
                const s = String(status).toLowerCase();
                const Icon = taskStatusIcons[s] || taskStatusIcons['default'];
                return (
                    <React.Fragment>
                        <Icon /> {status}
                    </React.Fragment>
                );
            }
        }, {
            field: 'action', 
            headerName: 'Action', 
            flex: true,
            valueGetter: (params) => {
                const task = params.row;
                return getTaskAction(task);
            }
        }, {
            field: 'workspace', 
            headerName: 'Workspace', 
            width: 250,
        }, {
            type: 'dateTime',
            field: 'date_started', 
            headerName: 'Started', 
            width: 220,
            valueGetter: (params) => new Date(params.value)
        }, {
            type: 'dateTime',
            field: 'date_done', 
            headerName: 'Completed', 
            width: 220,
            valueGetter: (params) => new Date(params.value)
        }, {
            field: 'actions', 
            headerName: 'Details', 
            width: 150,
            renderCell: (params) => {
                const task = params.row;
                return <Button variant="text" color="primary" onClick={() => {showTaskDetails(task)}}>Details</Button>
            }
        },
    ];

    return (      
        <React.Fragment>
            <BasicPage
                authenticated
                icon={StatusGaugeIcon}
                // params={params}
                // defaultSort={{field: 'date_done', order: 'DESC'}}
                title="Status"                
            >            
                <div className="toolbar">
                    <MUITextField
                        className="toolbar-item"
                        style={{minWidth: 150, backgroundColor: '#fff'}}
                        select
                        label="Workspace"
                        variant="outlined"
                        value={workspaceFilter || ':all:'}
                        onChange={onWorkspaceSelectChange}
                    >
                        <MenuItem value={':all:'}>All</MenuItem>
                        {workspaces.map((w, i) => {
                            return <MenuItem key={w.id} value={w.id}>{w.name}</MenuItem>
                        })}
                    </MUITextField>

                    <Button variant="contained" color="secondary" onClick={fetchTasks} processing={fetchTaskStatus > 0} disabled={fetchTaskStatus > 0}>
                        Refresh
                    </Button>
                </div>

                <Card>
                    <div style={{height: 600, width: '100%'}}>
                        <DataGrid 
                            loading={fetchTaskStatus > 0}
                            density="compact" 
                            rows={tasks} 
                            columns={gridColumns} 
                            disableSelectionOnClick={true}
                            sortModel={sortModel}
                            pageSize={50} />
                    </div>
                </Card>
            </BasicPage>

            <Dialog open={Boolean(selectedTask)} maxWidth="md">
                <DialogBody title="Task Details" onClose={hideTaskDetails} classes={{dialogContent: classes.dialogContent}}>
                    <pre>{JSON.stringify(selectedTask, null, 2)}</pre>
                </DialogBody>
                <Toolbar>
                    <Button variant="contained" color="primary" onClick={hideTaskDetails}>Okay</Button>
                </Toolbar>
            </Dialog>
     </React.Fragment>
    );
}

export default StatusPage;
