import React from "react";
import {
    ConnectedForm,
    TextInput,
    SelectInput,
    ButtonDialog, 
    DialogBody,
    Input,
    useCrudFetch,
    CREATE
} from "@cuda-react/core";


import makeStyles from "@material-ui/core/styles/makeStyles";
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {getFormValues} from "redux-form";
import {useSelector} from "react-redux";

import Button from "components/common/Button";
import {getCrudData} from 'utils';
import {useOnCrudSuccess} from 'hooks';
import {cloudProviderIcons} from 'iconMapping';


export const cloudProviders = [
    {key: 'Azure', name: 'Azure'},
    {key: 'AWS', name: 'AWS'}
];

const AddCloudDialogStyles = makeStyles({
    dialogContent: {
        width: 950
    }
});

export const AddCloudBtn = () => {
	return (
		<ButtonDialog buttonText="Add Provider">
			<AddCloudDialog />
		</ButtonDialog>
	);
};

export const AddCloudDialog = (props) => {
    const classes = AddCloudDialogStyles();
    // Note to self: ButtonDialog gives its child  a callback 'onClose'. Call it to close the dialog.
	return (
		<DialogBody title="Add Provider" form className={classes.dialogContent}>
			<AddCloudForm create onCancel={props.onClose} onSubmitSuccess={props.onClose} />
		</DialogBody>
	);
};





const AddCloudFormStyles = makeStyles({
    testResultContents: {
        paddingTop: 20,
        paddingBottom: 20,
    }
});

export const AddCloudForm = (props) => {
    const classes = AddCloudFormStyles();
    const [cloudsTestResponse, isTestingClouds, submitCloudsTest] = useCrudFetch(CREATE, 'cloudsTest');
    const [testResult, setTestResult] = React.useState(null);

    useOnCrudSuccess(() => {
        const testResponseData = getCrudData(cloudsTestResponse);
        if(testResponseData) {
            setTestResult(testResponseData);
        }
    }, cloudsTestResponse);

    const formData = useSelector((state) => {
        return getFormValues('add-cloud-form')(state);
    });

    const handleTest = () => {
        submitCloudsTest({data: formData});
    };

    return ( 
        <ConnectedForm 
            resource="clouds" 
            form="add-cloud-form"
            {...props}
        >
            <TextInput 
                source="name" 
                label="Name" 
                description="An alias for this subscription"
                isRequired 
            />
            <SelectInput
                source="cloud"
                label="Cloud Provider"
                choices={cloudProviders}
                icons={cloudProviderIcons}
                isRequired
            />
            
            <TextInput 
                source="subscription_id" 
                label="Subscription Id" 
                isRequired 
                require={(value, allValues) => allValues.cloud === 'Azure'}
                hide={(value, allValues) => allValues.cloud !== 'Azure'}
            />
            <TextInput 
                source="client_id" 
                label="Client Id" 
                require={(value, allValues) => allValues.cloud === 'Azure'}
                hide={(value, allValues) => allValues.cloud !== 'Azure'}
            />
            <TextInput 
                source="client_secret" 
                label="Client Secret" 
                require={(value, allValues) => allValues.cloud === 'Azure'}
                hide={(value, allValues) => allValues.cloud !== 'Azure'}
            />
            <TextInput 
                source="tenant_id" 
                label="Tenant Id" 
                require={(value, allValues) => allValues.cloud === 'Azure'}
                hide={(value, allValues) => allValues.cloud !== 'Azure'}
            />

            <TextInput 
                source="subscription_id" 
                label="Account Number"                 
                require={(value, allValues) => allValues.cloud === 'AWS'}
                hide={(value, allValues) => allValues.cloud !== 'AWS'}
            />
            <TextInput 
                source="client_id" 
                label="Client Id" 
                // require={(value, allValues) => allValues.cloud === 'AWS'}
                hide={(value, allValues) => allValues.cloud !== 'AWS'}
            />
            <TextInput 
                source="client_secret" 
                label="Access Secret" 
                // require={(value, allValues) => allValues.cloud === 'AWS'}
                hide={(value, allValues) => allValues.cloud !== 'AWS'}
            />

            <Dialog open={Boolean(testResult)}>
                <DialogTitle className="dialog-title">Test Result</DialogTitle>
                <DialogContent className={classes.testResultContents}>
                    {testResult && <div>
                        <Alert severity={testResult.success ? 'success' : 'error'} style={{marginBottom: 15}}>
                            <div>Result: {testResult.success ? 'Success' : 'Failure'}</div>
                            {testResult.message && typeof testResult.message === 'string' ? <div>{testResult.message}</div> : null}
                        </Alert>
                        <Typography variant="subtitle1">Details:</Typography>
                        <div className="code-box">{JSON.stringify(testResult, null, 2)}</div>
                    </div>}
                </DialogContent>
                <DialogActions className="dialog-actions">
                    <Button onClick={() => setTestResult(null)}>Okay</Button>
                </DialogActions>
            </Dialog>
            
            <Input label="Test Credentials" description="Click to test the specified credentials">                
                <Button processing={isTestingClouds > 0} disabled={isTestingClouds > 0} variant="contained" color="secondary" onClick={handleTest}>Test Credentials</Button>
            </Input>
        </ConnectedForm>
    );
};

export default AddCloudForm;