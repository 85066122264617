import React from "react";
import {useDispatch} from 'react-redux';
import {useHistory} from "react-router";
import {authLogout, useGlobalParam} from "@cuda-react/core";

import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ExitIcon from "@material-ui/icons/PowerSettingsNew";
import { UserIcon } from "@cuda-react/icons";

import AppBarMenuButton from './AppBarMenuButton';

// Renders the main app bar menu for Accounts, Login, Admin.
// This would replace cuda-react's logout menu
export const AppBarMainMenu = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [username, ] = useGlobalParam('userData.currentUser', 'Guest');
    const [isAdmin, ] = useGlobalParam("userData.privileges.admin");
    const [accounts, ] = useGlobalParam("userData.accounts");
    const [defaultAccount, ] = useGlobalParam("userData.default_account");
    const [currentAccount, setCurrentAccount] = useGlobalParam("currentAccount");

    let menuItems = [];
    let currentAccountId = currentAccount || defaultAccount;
    let currentAccountObject = null;

    if(Array.isArray(accounts)) {
        menuItems.push("Accounts");

        accounts.forEach((account) => {
            if(account.account_id === currentAccountId) {
                currentAccountObject = account;
            }
            menuItems.push({
                selected: account.account_id === currentAccountId,
                label: account.account_name + (account.account_id === defaultAccount ? ' (default)' : ''),
                onClick: () => {
                    setCurrentAccount(account.account_id);
                }
            });
        });
        
        menuItems.push('-');
    }

    if(isAdmin) {
        menuItems.push({
            label: "Administration",
            icon: SupervisorAccountIcon,
            onClick: () => {
                history.push("/admin");
            }
        });
    }

    menuItems.push({
        label: "Log Out",
        icon: ExitIcon,
        onClick: () => {
            dispatch(authLogout());
        }
    });

    let buttonLabel = username;
    if(currentAccountObject) {
        buttonLabel += ` (${currentAccountObject.account_name})`;
    }    

    return <AppBarMenuButton icon={UserIcon} label={buttonLabel} items={menuItems} />;
}

export default AppBarMainMenu;