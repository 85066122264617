import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import MUIList from '@material-ui/core/List';

const listStyles = makeStyles(theme => ({
    list: {
      
    },
    navigationList: {
        '&> .Mui-selected': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.alternate,
            "&:hover": {
                backgroundColor: theme.palette.primary.main
            }, 
            '& .MuiListItemIcon-root > svg': {
                fill: '#fff'
            }
        }
    }
}));

const List = (props) => {
    const classes = listStyles();
    const children = React.Children.toArray(props.children);
    const listItems = React.Children.map(children, (child, i) => {
        return child;
    }, this);
    return <MUIList className={[classes.list, props.navigation ? classes.navigationList : ''].join(' ')}>{listItems}</MUIList>
}

List.propTypes = {
    navigation: PropTypes.bool
};

List.defaultProps = {
    navigation: false
};

export default List;