import {useSelector} from "react-redux";
import {get} from "lodash";

export const useGetTask = (taskId) =>  {
    return useSelector((state) => {
        const task = taskId ? get(state, 'TasksReducer.' + taskId, null) : null;
        return task;
    });
};

export const useGetAllTasks = () =>  {
    return useSelector((state) => {
        return get(state, 'TasksReducer') || [];
    });
};

// returns completed task only if the 'status' code is SUCCESS or FAILURE
export const useGetCompletedTask = (taskId) =>  {
    return useSelector((state) => {
        const task = taskId ? get(state, 'TasksReducer.' + taskId, null) : null;
        if(task && (task.status === 'SUCCESS' || task.status === 'FAILURE')) {
            return task;
        }
        return null;
    });
};