import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import WidgetsOutlinedIcon from '@material-ui/icons/WidgetsOutlined';
import MemoryIcon from '@material-ui/icons/Memory';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import AddFrsSerialDialog from './AddFrsSerialDialog';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import Linkify from 'components/common/Linkify';
import ResourcesTable from './ResourcesTable';
import ExportIcon from '@material-ui/icons/ImportExport';

import {
    DELETE,
    useCrudFetch,
    Tabs,
    Tab, 
} from "@cuda-react/core";

import Heading from 'components/common/Heading';
import DeployWizardDialog from "components/Deploy/DeployWizardDialog";
import OpenDialogButton from "components/common/OpenDialogButton";
import ConfirmDeleteDialog from "components/common/ConfirmDeleteDialog";
import TextViewerDialog from 'components/common/TextViewerDialog';

import {useSetting} from 'hooks';

const pageStyles = makeStyles({
    section: {
        marginBottom: 40
    },
    assetsTbody: {
        maxHeight: 200, 
        overflow: 'auto'
    },
    resourcesActionColumn: {
        textAlign: 'right'
    }
});

// Display and manage a solution deployment details
const DeploymentDetails = ({deployment, isAdmin, ...props}) => {
    const classes = pageStyles();
    const deployedAssets = deployment.resources || [];
    const deploymentStatus = String(deployment.status).toLowerCase();
    const stepDeployments = deployment.steps && Array.isArray(deployment.steps) ? deployment.steps.sort((a, b) => a.step < b.step) : [];
    const frsAddedSerials = deployment.sns ? [].concat(deployment.sns) : [];
    const instructions = deployment.instructions || [];

    const [deletedDeployments, setDeletedDeployments] = useSetting('deletedDeployments', []);
    // console.log('deletedDeployments', deletedDeployments);

    // gather up assets that were deployed in each step
    // stepDeployments.forEach((deployment, i) => {
    //     if(deployment.assets) {
    //         deployment.assets.forEach((asset, j) => {
    //             deployedAssets.push(asset);
    //         })
    //     }
    // });

    // handle deletion of this deployment
    const [/*deleteResult*/, isDeleting, callDeleteDeployment] = useCrudFetch(DELETE, 'deployments', {id: deployment.id});

    const handleRemoveDeployment = () => {
        callDeleteDeployment();
        setDeletedDeployments([...deletedDeployments, deployment.id]);
    };

    return (
        <div>   
            {!isAdmin && Array.isArray(instructions) && instructions.length > 0 && <div className={classes.section}>
                <Heading icon={SettingsApplicationsIcon}>Configuration Instructions</Heading>
                <Card>
                    <CardContent>
                        {instructions.map((inst, i) => {
                            return <div key={i} style={{marginBottom:5}}>
                                <Typography><Linkify >{inst}</Linkify></Typography>
                            </div>
                        })}
                    </CardContent>
                </Card>
            </div>}

            <div className={classes.section}>
                <Heading icon={InfoIcon}>Deployment Details</Heading>
                <Card>
                    <CardContent>
                    <Table size="small">                        
                        <TableBody>
                            {deployment.name ? <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>{deployment.name || 'N/A'}</TableCell>
                            </TableRow> : null}
                            {deployment.cloud ? <TableRow>
                                <TableCell>Cloud</TableCell>
                                <TableCell>{deployment.cloud}</TableCell>
                            </TableRow> : null}
                            <TableRow>
                                <TableCell>Status</TableCell>
                                <TableCell>{deployment.status}</TableCell>
                            </TableRow>
                            {deployment.solution === 'frscloud' ? <TableRow>
                                <TableCell>URL</TableCell>
                                <TableCell>{deployment.url}</TableCell>
                            </TableRow> : null}
                        </TableBody>
                    </Table>
    
                    </CardContent>
                </Card>
            </div>         

            {deployment.solution === 'frscloud' && <div className={classes.section}>
                <Heading icon={SettingsApplicationsIcon}>Configurations</Heading>
                <Card>
                    <CardContent>
                        <Typography variant="body1">
                            Added Serials: {frsAddedSerials.length ? frsAddedSerials.join(', ') : 'None'}
                        </Typography>
                        <br />
                        <Typography variant="body2">
                            <OpenDialogButton variant="contained" color="primary" buttonLabel={<React.Fragment><AddIcon /> Add Serial</React.Fragment>} title="Add Cloudgen Firewall Serial to FRS" closeDialogPropName={['handleClose']}>
                                <AddFrsSerialDialog deployment={deployment} />
                            </OpenDialogButton>
                            <br />
                            Click on the button above to add your Cloudgen Firewall serial to this Firewall Insights deployment.
                        </Typography>
                    </CardContent>
                </Card>
            </div>}

            <div className={classes.section}>
                <Heading icon={MemoryIcon}>Resources</Heading>
                <Card>
                    <div style={{width: '100%', height: 300}}>
                        <ResourcesTable resources={deployedAssets} />
                    </div>
                </Card>
            </div>


            {Array.isArray(deployment.steps) && <div className={classes.section}>
                <Heading icon={WidgetsOutlinedIcon}>Progress</Heading>
                <Card>
                    <CardContent>
                        <div style={{float: 'right'}}>
                            {deployment && 
                                <OpenDialogButton
                                    color="danger"
                                    style={{marginRight: 10}}
                                    buttonLabel="Remove Deployment"
                                    dialog={ConfirmDeleteDialog}
                                    disabled={deletedDeployments.indexOf(deployment.id) > -1}
                                    dialogProps={{
                                        title: "Confirm",
                                        message: "Remove this deployment?",
                                        confirmLabel: "Remove Deployment",
                                        status: {deleting: isDeleting},
                                        handleConfirm: (handleClose) => {
                                            handleRemoveDeployment();
                                            handleClose();
                                        }
                                    }}  
                                />
                            }
                            {deploymentStatus !== 'complete' &&
                                <OpenDialogButton variant="contained" color="primary" buttonLabel="Resume Deployment" title="Resume deployment of this solution" closeDialogPropName={['handleClose', 'handleFinish']}>
                                    <DeployWizardDialog deployment={deployment} solution={deployment.solution} />
                                </OpenDialogButton>
                            }
                        </div>
                        <div style={{clear: 'both'}}></div>
                        {stepDeployments.length 
                        ? <div>                            
                            <Tabs>
                                {stepDeployments.map((deployment, i) => {
                                    const hasError = String(deployment.state).toLowerCase() === 'failed';
                                    let stepNum = deployment.step !== undefined ? deployment.step : i + 1;
                                    let label = `Step ${stepNum}`;
                                    return <Tab key={i} label={label} error={hasError}>
                                        <pre style={{maxHeight: 300, overflow: 'auto', border: '1px solid #ccc', padding: 10}}>{JSON.stringify(deployment, null, 2)}</pre>
                                    </Tab>
                                })}
                            </Tabs>
                        </div> 
                        : <Typography>Not started yet</Typography>}
                    </CardContent>
                </Card>
            </div>}

            <div className={classes.section}>
                <Heading icon={AttachMoneyIcon}>Estimated Cost</Heading>
                <Card>
                    <CardContent>
                        <Typography variant="body1">
                            Estimated cost to run this solution: <strong>${Number(deployment.cost || 0).toFixed(2)}</strong>
                        </Typography>
                    </CardContent>
                </Card>
            </div>

            <div className={classes.section}>
                <Heading icon={ExportIcon}>Export</Heading>
                <Card>
                    <CardContent>
                        <OpenDialogButton buttonLabel="Export" variant="contained" color="primary">
                            <TextViewerDialog 
                                title="Deployment Export" 
                                text={JSON.stringify(deployment, null, 2)} 
                                download={"CEC-Deployment-" + deployment.id + ".json"} 
                                downloadMime="application/json"
                                />
                        </OpenDialogButton>
                        <Typography variant="body1">Click to view JSON export of this deployment.</Typography>
                    </CardContent>
                </Card>
            </div>

        </div>
    );
}

DeploymentDetails.propTypes = {
    deployment: PropTypes.object.isRequired
}

DeploymentDetails.defaultProps = {
}

export default DeploymentDetails;