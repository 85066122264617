// this is a clone of the default httpClient but with some tweaks
// import {httpClient as httpClientOrig} from "@cuda-react/core";
import {loadState} from "@cuda-react/core/lib/reducers/localStoragePersist";
import {get} from "lodash";

// extend the framework's original httpClient to pass along the authorization header
const httpClient = (url, options) => {
    // console.log('httpClient', url);
    options = options || {};
    if(!options.headers) {
        options.headers = {};
    }
    options.headers["Accept"] = "application/json";

    if(options.noAuthorizationHeader !== true) {
        // it's kind of a hack to get the api token directly from the local storage state 
        // because there's no obvious hooks to get the global params data, where the apiToken is stored.
        const appState = loadState();
        if(appState) {
            let apiToken = get(appState, 'GlobalParamsReducer.userData.apiToken');
            if(apiToken) {
                if(window.apiToken) {
                    // apiToken = window.apiToken;
                }
                options.headers['Authorization'] = 'Bearer ' + apiToken;
            } else {
                // console.log('No api token');
            }
        }        
    }

    return fetch(url, options).then((response) => {
        if(response.ok) {
            return response;
        } else {
            // Not okay response
            if(response.headers.get("Content-Type") && response.headers.get("Content-Type").includes("application/json")) {
                // response is json???
                return response.json().then((json) => {
                    return Promise.reject({
                        body: json,
                        message: json.message,
                        status: response.status,
                        response: response,
                    });
                }).catch((error) => {
                    // because we return a rejection above, we have to figure out if this
                    // error is because of parsing error or just the same rejection
                    if(error instanceof SyntaxError) {
                        return Promise.reject({
                            body: 'Response body is not valid JSON format',
                            message: response.statusText,
                            status: response.status,
                            response: response,
                        });
                    } else {
                        return Promise.reject(error);
                    }
                });
            } else {
                // response is not json
                return Promise.reject({
                    body: response.text(),
                    message: response.statusText,
                    status: response.status,
                    response: response,
                });
            }
        }
    }).catch((error) => {
        // catch all errors rejected from above
        if(error.body) {
            if(error.body.localizedMessage) {
                error.message = error.body.localizedMessage;
            } else if(error.body.message) {
                error.message = error.body.message;
            } else if(error.body.error) {
                error.message = error.body.error;
            }
        }
        if(!error.message && error.status !== undefined) {
            error.message = "httpError." + error.status;
        }
        return Promise.reject(error);
    });
       
};


// Same as httpClient except the response is expected to be valid json
// and rejects otherwise.
export const httpJsonClient = (url, options) => {
    return httpClient(url, options).then((response) => {
        return response.json().then((data) => {
            return data;
        }).catch((error) => {
            return Promise.reject({
                body: 'Response body is not valid JSON format',
                message: response.statusText,
                status: response.status,
                response: response,
            });
        });
    });
};

export default httpClient;