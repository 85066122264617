import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert'
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import InfrastructureIcon from '@material-ui/icons/AccountTree';

import { SdWanIcon } from "@cuda-react/icons";
import {
    GET,
    // useCrudSubscription,
    useCrudMonitor,
    Tab, 
    BasicPage, 
    // TabbedPage,
    SideTabs,
} from "@cuda-react/core";

import LoadingPage from 'components/common/LoadingPage';
import DeploymentDetails from './DeploymentDetails';
import DeploymentSummary from './DeploymentSummary';
import Infrastructure from "./Infrastructure";
import LoadingContent from 'components/common/LoadingContent';
import { getCrudData, getCrudArrayData } from 'utils';


const pageStyles = makeStyles((theme) => ({
    link: {
        color: theme.palette.primary.main
    }
}));


// This component is split out because we reuse it for for admin UI
export const DeploymentsPageContent = ({solutions, deploymentData, ...props}) => {
    const deployments = deploymentData && deploymentData.deployments ? deploymentData.deployments : [];

    // build solutions mapping by solution id
    let solutionsById = {};
    if(solutions) {
        solutions.forEach((solution) => {
            solutionsById[solution.id] = solution;
        });
    }

    // build tabs for every deployment
    const tabs = deployments.map((deployment, i) => {
        let label = deployment.name || '';
        if(!label) {
            const solution = solutionsById[deployment.solution];
            let solutionName = solution ? solution.name : deployment.solution;
            label = `${solutionName} (${deployment.id})`;
        }
        return (<Tab key={i} hash={'#' + deployment.id} label={label} icon={SdWanIcon}>
            <DeploymentDetails deployment={deployment} {...props} />
        </Tab>);
    });

    // infra tab
    tabs.unshift(<Tab key={tabs.length} hash="#infra" label="Infrastructure" icon={InfrastructureIcon}>
        <Infrastructure data={deploymentData} solutions={solutions} />
    </Tab>);

    // summary tab
    tabs.unshift(<Tab key={tabs.length} hash="#summary" label="Summary" icon={AssessmentOutlinedIcon}>
        <DeploymentSummary data={deploymentData} solutions={solutions} {...props} />
    </Tab>);

    return <SideTabs>{tabs}</SideTabs>;
};

const DeploymentsPageLoaded = (props) => {
    const classes = pageStyles();
    const [solutionsResponse, isLoadingSolutions] = useCrudMonitor("solutions", GET);

    // fetching is now done through a subscription in DeploymentMenuIcon, we can just use its reponse here
    // const [deploymentsResponse, isLoadingDeployments] = useCrudSubscription(GET, "listDeployments");
    const [deploymentsResponse, isLoadingDeployments] = useCrudMonitor("listDeployments", GET);
    
    const solutions = getCrudArrayData(solutionsResponse);
    const deploymentData = getCrudData(deploymentsResponse);
    const deployments = deploymentData && deploymentData.deployments ? deploymentData.deployments : [];

    const isLoading = isLoadingDeployments || isLoadingSolutions;
    return (        
        <BasicPage authenticated icon={SdWanIcon} {...props} title="Deployed Solutions">        
            <LoadingContent loading={Boolean(isLoading)} loadingMessage="Loading deployed solutions...">
                {(!deployments.length || !solutions.length 
                    ? <div>
                        {!isLoadingSolutions && solutionsResponse &&
                            (solutionsResponse.error 
                                ? <Alert severity="error" style={{marginBottom:15}}>Unable to load solutions</Alert>
                                : (!solutions.length && <Typography>No solutions defined</Typography>))}

                        {!isLoadingDeployments && deploymentsResponse &&
                            (deploymentsResponse.error 
                                ? <Alert severity="error">Unable to load deployments</Alert>
                                : (!deployments.length && <Typography>You have not deployed anything yet. Explore <RouterLink className={classes.link} to={{pathname: '/templates'}}>Templates</RouterLink> to deploy a solution or resource.</Typography>))
                        }
                    </div>
                    : <DeploymentsPageContent solutions={solutions} deploymentData={deploymentData} />)
                }
            </LoadingContent>
        </BasicPage>
    );
};

export const DeploymentsPage = (props) => {
    return <LoadingPage 
                authenticated
                title='Deployed Solutions'
                icon={SdWanIcon}
                content={DeploymentsPageLoaded} />
};

export default DeploymentsPage;