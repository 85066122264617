export const SET_SETTINGS = "SET_SETTINGS";
export const UPDATE_SETTINGS = "UPDATE_SETTINGS";

export const setSettings = (settings) => {
    return {type: SET_SETTINGS, settings};
}

export const updateSettings = (settings) => {
    return {type: UPDATE_SETTINGS, settings};
}
