import React from "react";
import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import SchoolOutlinedIcon from '@material-ui/icons/SchoolOutlined';
import SendIcon from '@material-ui/icons/Send';

const BETA_SOLUTIONS = ['frscloud'];

const styles = makeStyles(theme => ({
    root: {
        width: 450,
        borderRadius: 4,
        border: '1px solid #f0f0f0',
        '&:hover:not([disabled])': {
            borderColor: theme.colors.lightBlue,            
        },
        // '&:hover $topBadgesContainer': {
        //     opacity: 0
        // }
    },
    hasDeployment: {
        borderColor: '#aaa'
    },
    completeDeployment: {
        borderColor: 'green'
    },
    isBarracudaSolution: {
        // borderColor: theme.colors.lightBlue,
    },
    media: {
        height: 200,
        backgroundSize: '100% 100%',
        backgroundColor: '#fafafa',
        transition: 'background-size 0.2s ease-in',
        '&:hover': {
            backgroundSize: '110% 110%',
        }
    },
    content: {
        height: 80,        
        backgroundColor: theme.colors.lightGray1,
        color: '#222'
    },
    buttonsContainer: {
        justifyContent: 'flex-end',
        backgroundColor: '#f0f0f0'
    },
    topBadgesContainer: {
        float: 'left',
        width: '100%',
        padding: '8px',
        background: 'rgba(0,0,0,0.2)',
        '&:empty': {
            display: 'none'
        }
    },
    topBadge: {
        display: 'inline-block',
        marginRight: 10,
        padding: '1px 4px',
        borderRadius: 2,
        backgroundColor: '#007AB8',
        color: '#fff',
    }
}));

const solutionLogoMap = {
    cgwanbasic: '/images/cgwan.png',
    cgfwbasic: '/images/cgfw.png',
    wafbasic: '/images/waf.png',
    cloudvm: '/images/remote_office.png',
    frscloud: '/images/frscloud.png',
    frs: '/images/frscloud.png',
    cgaccess: '/images/cloudgen-access.png',
    sentinel: '/images/sentinel.png',
    forensics: '/images/forensics.png',
    default: '/images/cloud.png'
};

const SolutionTile = ({solution, handleOpenDeployment, ...props}) => {
    const classes = styles();

    // const handleClose = () => {
    //     setDialogOpen(false);
    // };
    
    const handleClick = (e) => {        
        if((!solution.steps || !solution.steps.length) && solution.link) {
            return;
        }
        e.preventDefault();  
        if(typeof handleOpenDeployment === 'function') {
            handleOpenDeployment(solution);
        }
    }

    return (
        <React.Fragment>
            
            <Card disabled={!solution.deployable} className={[classes.root, solution.deployment ? classes.hasDeployment : '', solution.barracuda ? classes.isBarracudaSolution : ''].join(' ')}>
                <CardActionArea onClick={handleClick} disabled={!solution.deployable} href={(!solution.steps || !solution.steps.length) && solution.link ? solution.link : null} target="_blank">
                    <div className={classes.topBadgesContainer}>
                        {solution.barracuda && <div className={classes.topBadge}><Typography variant="subtitle2">Barracuda Solution</Typography></div>}
                    </div>
                    <CardMedia className={classes.media} image={solutionLogoMap[solution.id] || solutionLogoMap['default']} title={solution.name} />
                    <CardContent className={classes.content}>
                        {solution.beta || BETA_SOLUTIONS.indexOf(solution.id) > -1 ?
                        <Badge badgeContent="BETA" color="primary">
                            <Typography gutterBottom variant="h6">
                                {solution.name}
                            </Typography>
                        </Badge> : <Typography gutterBottom variant="h6">
                                {solution.name}
                            </Typography>}
                        <Typography gutterBottom variant="body2" component="p">
                            {solution.description}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions className={classes.buttonsContainer}>
                    {solution.training && <Button color="secondary" href={solution.training} target="_blank" startIcon={<SchoolOutlinedIcon />}>Training</Button>}
                    {solution.link && <Button color="secondary" href={solution.link} target="_blank" startIcon={<HelpOutlineIcon />}>Documentation</Button>}
                    {solution.steps && solution.steps.length > 0 ? <Button color="primary" onClick={handleClick} startIcon={<SendIcon />} disabled={!solution.deployable}>Deploy</Button> : null}
                </CardActions>
            </Card>
        </React.Fragment>
    );
}

export default SolutionTile;
