// see hooks/globalData for details

export const SET_GLOBAL_DATA = "SET_GLOBAL_DATA";
export const UPDATE_GLOBAL_DATA = "UPDATE_GLOBAL_DATA";

export const setGlobalData = (data) => {
    return {type: SET_GLOBAL_DATA, data};
}

export const updateGlobalData = (data) => {
    return {type: UPDATE_GLOBAL_DATA, data};
}
