import React, {useState} from "react";
import { useHistory } from 'react-router';
import { parse, stringify } from 'query-string';
import { isEqual } from "lodash";
// import { makeStyles } from '@material-ui/core/styles';
// import {useDispatch, useSelector} from 'react-redux';
// import WizardDialog from "components/common/WizardDialog";
import Dialog from "@material-ui/core/Dialog";
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import TextField from '@material-ui/core/TextField';

// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';

// import CloudOutlinedIcon from '@material-ui/icons/CloudOutlined';
// import Typography from '@material-ui/core/Typography';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
// import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
// import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import List from 'components/common/List';
import ListItem from 'components/common/ListItem';

// import Heading from 'components/common/Heading';
// import LoadingSpinner from 'components/common/LoadingSpinner';
// import OpenDialogButton from 'components/common/OpenDialogButton';
// import DeleteDialog from 'components/common/DeleteDialog';
// import DeployWizardDialog from "components/Deploy/DeployWizardDialog";

// import {useGetTask, useSetting} from 'hooks';
// import {getCrudData} from 'utils';
// import {getTaskStatus, stopGetTaskStatus, removeTask} from 'actions/tasks';


import {
    SdWanIcon 
} from '@cuda-react/icons';

import {
    saveOrigin,
    useCrudMonitor,
    ButtonDialog,
    DialogBody,
    useCrudProps,
    useCrudSubscription,
    GET
} from '@cuda-react/core';

import { usePrevious, useQueryParams } from 'hooks';


const WizardStep = (props) => {
    return <h1>I am a wizard step</h1>
}



export const TestPage = (props) => {
    const history = useHistory();
    const location = history.location;
    const queryParams = useQueryParams();
    // const result = useCrudSubscription(GET, 'solutions');
    // const result = useCrudProps('solutions');
    // console.log('useCrudSubscription solutsion', result);

    const [dayFilter, setDayFilter] = React.useState(queryParams.days || 1);

    const filters = {
        days: dayFilter
    };

    const prevFilters = usePrevious(filters);
    React.useEffect(() => {
        if(!isEqual(prevFilters, filters)) {
            location.search = '?'.concat(stringify(filters));
            history.push(history.location);
        }
    }, [filters]);

    function handleConfirm(closeDialog) {
        alert("Confirmed!");
        if(closeDialog) closeDialog();
    }

    const [wizardSteps, setWizardSteps] = React.useState([]);
    function onDialogEnter() {
        let wizardSteps = [];
        for(let i = 0; i < 10; ++i) {
            wizardSteps.push(<WizardStep key={i} title={'Step ' + i} wizardConfigs={{validated: true}} />);
        }
        setWizardSteps(wizardSteps);
    }

    const [isOpen, setOpen] = React.useState(false);
    function openDialog() {
        setOpen(true);
    }

    function closeDialog() {
        setOpen(false);
    }

    function onDialogClose() {
        console.log('dialog exited');
    }

    function setTheme(theme) {
        localStorage.setItem('theme', theme);
    }

    return (
        <div>
            <button onClick={() => setTheme('default')}>Default theme</button>
            <button onClick={() => setTheme('pink')}>Pink theme</button>
            <button onClick={() => setTheme('green')}>Green theme</button>
            <button onClick={() => setTheme('yellow')}>Yellow theme</button>

            <List>
                <ListItem>One</ListItem>
                <ListItem>Two</ListItem>
                <ListItem>Three</ListItem>
            </List>

            <button onClick={openDialog}>Open Dialog</button>
            <Dialog open={isOpen} onExited={()=>{console.log('exited')}}>
                Test
                <button onClick={closeDialog}>Close Dialog</button>
            </Dialog>
            {/* <OpenDialogButton 
                buttonLabel="Delete" variant="contained" color="primary"
                dialog={DeleteDialog}
                dialogProps={{handleConfirm: handleConfirm, status: {deleting: true}}} /> */}
        </div>
    );
};

export default TestPage;