import React from 'react';
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

// This component is meant to work inside of the cuda-react's <ActionButtonsField> component.

const ActionButton = ({data, disabled, label, icon, tooltip, onKlick, ...buttonProps}) => {
    data = data || {};
    label = typeof label === "function" ? label(data) : label;
    tooltip = typeof tooltip === "function" ? tooltip(data) : tooltip;
    icon = typeof icon === "function" ? icon(data) : icon;
    disabled = typeof disabled === "function" ? disabled(data) : disabled;

    const button = icon 
        ? <IconButton onClick={(event) => onKlick(event, data)} disabled={disabled} {...buttonProps}>
            {icon}
        </IconButton>
        : <Button onClick={(event) => onKlick(event, data)} disabled={disabled} {...buttonProps}>
            {label}
        </Button>;

    if(tooltip) {
        return (
            <Tooltip title={tooltip}>{button}</Tooltip>
        );
    }
    return button;
};

ActionButton.propTypes = {
    // Note the weird naming here because ActionButtonsField automatically wraps any element with an 'onClick'
    // prop into a IconButton, which will result in Button element being nested inside another Button element. 
    // React will complain and it's not clean, so we do it this way.
    onKlick: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]), // If specified, this will be an IconButton and 'label' will be ignored
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
};

export default ActionButton;