import React from "react";
import PropTypes from "prop-types";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export const LoadingSpinner = ({loading, message, gutterBottom, ...props}) => {
    return <div className="LoadingSpinner-root" style={{display: loading ? 'block' : 'none', marginBottom: gutterBottom ? 20 : 0, padding: '5px 0'}}>
        <Box display="flex" alignItems="center">
            <Box  minWidth={20}>
                <CircularProgress size={15} />
            </Box>
            <Box width="100%" mr={1}>
                <Typography gutterBottom variant="body2" display="block">{message || 'Loading...'}</Typography>
            </Box>
        </Box>
    </div>;
}

LoadingSpinner.propTypes = {
    loading: PropTypes.bool,
    message: PropTypes.string,
    gutterBottom: PropTypes.bool,
}

LoadingSpinner.defaultProps = {
    loading: false,
    message: 'Loading...'
}

export default LoadingSpinner;