import {
    AwsIcon,
    AzureIcon,
    OkayIcon,
    ErrorIcon,
    UnknownIcon,
} from '@cuda-react/icons';

export const cloudProviderIcons = {
    aws: AwsIcon,
    Aws: AwsIcon,
    AWS: AwsIcon,
    
    azure: AzureIcon,
    Azure: AzureIcon,
};

export const taskStatusIcons = {
    success: OkayIcon,
    failed: ErrorIcon,
    failure: ErrorIcon,
    "default": UnknownIcon
};