// cuda-react's SideTabs works on the second hash (location.hash), so we can't use it as first level navigation.
// For example, we have to use #/tabbedPage/#tab instead of being able to use #/tabbedPage/tab.
// This component wraps around it so we can use the side tabs for first level navigation.

import React from 'react';
import {useLocation} from 'react-router';

import {
    SideTabs
} from '@cuda-react/core';


export const BetterSideTabs = ({children, props}) => {
    const location = useLocation();

    // return the index of the tab with the path that matches the current location.pathname
    const getCurrentTab = () => {
        let index = children.findIndex((tab) => {
            const hash = tab.props.hash;
            if(typeof tab.props.match === 'function') {
                return tab.props.match(location.pathname);
            }
            const pattern = new RegExp("^" + hash + "($|[/#?])");
            return pattern.test(location.pathname);
        });
        if(index === -1) {
            // support original cuda-react behavior
            index = children.findIndex((tab) => {
                return tab.props.hash === location.hash;
            });
        }
        return Math.max(0, index); // default to first item
    }

    return <SideTabs defaultTab={getCurrentTab} {...props}>{children}</SideTabs>
};

export default BetterSideTabs;