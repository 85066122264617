import { put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_CHECK,
    AUTH_ERROR,
    SAVE_ORIGIN,
    setGlobalParams,
    // redirect
} from "@cuda-react/core";

// import authClient from 'services/bccAuthClient';
import {setGlobalData, setSettings} from 'actions';

function* loginSaga(action) {
    // console.log("loginSaga", action);
}

function* loginErrorSaga(action) {
    // console.log("loginErrorSaga", action);
}

function* authCheckSaga(action) {
    // console.log("auth check saga");
}

function* logoutSaga(action) {
    yield put(setGlobalParams({}));
    yield put(setGlobalData({}));
    yield put(setSettings({}));
}

function * saveOriginSaga(action) {
    // console.log('save origin saga', action);
}

export default function* () {    
    yield takeLatest(AUTH_LOGOUT, logoutSaga);    
    yield takeLatest(AUTH_LOGIN, loginSaga);
    yield takeLatest(AUTH_ERROR, loginErrorSaga);
    yield takeLatest(AUTH_CHECK, authCheckSaga);
    yield takeEvery(SAVE_ORIGIN, saveOriginSaga);
}