import React from 'react';
import PropTypes from "prop-types";
import LoadingBar from 'components/common/LoadingBar';

// Display a loading bar or children. Use this component instead of ternary operator rendering. 
// Wrap any contents that should only be rendered once the loading is compeleted inside this component.
// Example: 
// <LoadingContent loading={isLoadingFlag}>
//    Content loaded!
// </LoadingContent>
export const LoadingContent = (props) => {
    const {loading, loadingMessage, children} = props;
    return loading ? <LoadingBar loading={true} message={loadingMessage} /> : children;
};

LoadingContent.propTypes = {
    loading: PropTypes.bool,
    loadingMessage: PropTypes.string,
}

LoadingContent.defaultProps = {
    loading: false,
    loadingMessage: 'Loading...'
}

export default LoadingContent;