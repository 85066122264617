import * as taskActions from '../actions/tasks';
// import {getTaskAction} from '../utils/';
import {put, call, cancel, take, delay, fork} from 'redux-saga/effects';
import api from "services/api";

import { 
    notificationShowToast,
    ERROR_NOTIFICATION,
} from "@cuda-react/core";

const MAX_RETRIES_ACOUNT = 5;

// const taskSelector = (state, taskId) =>  {
//     return taskId ? get(state, 'TasksReducer.' + taskId, null) : null;
// };

function* getTaskStatusWorker(taskId) {
    let retries = MAX_RETRIES_ACOUNT;
    while(true) {
        const task = yield call(api.get, '/tasks/'+taskId);        
        const taskStatus = task && task.status ? String(task.status).toLowerCase() : '';
        yield put({type: taskActions.UPDATE_TASK_STATUS, taskId, status: task});
        
        if(!task) {
            // sometimes the API returns null because we the task hasn't been picked up and started yet, so
            // we retry just in case this is a valid task.
            --retries;
            if(!retries) {
                yield put(notificationShowToast(`Task status check failed for task "${taskId}"`, ERROR_NOTIFICATION, 10000));
                yield put({type: taskActions.REMOVE_TASK, taskId});
                break;
            }
        } else {    
            if(!taskStatus || taskStatus === 'success' || taskStatus === 'failure') {
                break;
            }
        }
        // keep going
        yield delay(2000);
    }
}

// got the idea from: https://stackoverflow.com/questions/45572331/redux-sagas-fetch-only-once-when-same-id-is-dispatched-multiple-times
// track every task polling action by its taskId so we can cancel specific request
function tasksSaga(action) {
    return fork(function* () {        
        const runningTasks = {};
        while(true) {
            const action = yield take([
                taskActions.GET_TASK_STATUS,
                taskActions.REMOVE_TASK,
                taskActions.STOP_GETTING_TASK_STATUS
            ], tasksSaga);
            const taskId = action.taskId;

            if(action.type === taskActions.GET_TASK_STATUS) {
                if(runningTasks[taskId]) {
                    yield cancel(runningTasks[taskId]);            
                }
                runningTasks[taskId] = yield fork(getTaskStatusWorker, taskId);
            } else if(action.type === taskActions.REMOVE_TASK) {
                yield put({type: taskActions.REMOVE_TASK, taskId});
                if(runningTasks[taskId]) {
                    yield cancel(runningTasks[taskId]);
                }
            } else if(action.type === taskActions.STOP_GETTING_TASK_STATUS) {
                if(runningTasks[taskId]) {
                    yield cancel(runningTasks[taskId]);
                }
            }
        }
    })
}


export default function* () {    
    yield tasksSaga();
}