import React from 'react';
import Badge from '@material-ui/core/Badge';

import {SdWanIcon} from "@cuda-react/icons";
import {
    GET,
    useCrudMonitor,
} from "@cuda-react/core";
import { getCrudData } from 'utils';

export const DeploymentMenuIcon = (props) => {
    const [deploymentsResponse, ] = useCrudMonitor('listDeployments', GET);
    const deploymentData = getCrudData(deploymentsResponse);
    const deployments = deploymentData && deploymentData.deployments ? deploymentData.deployments : [];
    return deployments.length > 0  ?
        <Badge badgeContent={deployments.length} color="primary" anchorOrigin={{vertical: 'top', horizontal: 'left'}}>
            <SdWanIcon />
        </Badge>
        : <SdWanIcon />;
};

export default DeploymentMenuIcon;

