// This renders a refresh button to manuall perform a GET request of an api resource
import React from "react";
import PropTypes from "prop-types";

import {GET, useCrudFetch} from "@cuda-react/core";
import Button from "@material-ui/core/Button";
// import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles({
    progress: {
        marginLeft: -5,
        marginRight: 5
    }
});

export const RefreshResourceBtn = (props) => {
    const classes = styles();
    const {label, resource, params, ...buttonProps} = props;
    const [, isLoading, fetch] = useCrudFetch(GET, resource, params || {});
    const handleClick = () => {
        fetch();
    }
    
    return (
        <Button variant={props.variant || 'contained'} color={props.color || 'secondary'} onClick={handleClick} disabled={isLoading > 0} {...buttonProps}>
            {isLoading > 0 && <CircularProgress size={15} color="secondary" className={classes.progress} />}
            {label}
        </Button>
    );
};

RefreshResourceBtn.propTypes = {
    label: PropTypes.string.isRequired,
    resource: PropTypes.string.isRequired,
    params: PropTypes.object
};

RefreshResourceBtn.defaultProps = {
    label: 'Refresh',
};

export default RefreshResourceBtn;