import {useSelector} from "react-redux";
import {get} from "lodash";

import {getCrudId} from '@cuda-react/core/lib/utils/crudUtils';
import {useDeepCompareEffect} from '@cuda-react/core/lib/hooks'
import {getCrudData} from 'utils';
import {usePrevious} from './index';

// returns the crud state of a resource.
// resource: required
// restType: optional request type, e.g. GET, POST, etc... If not specified, all request types will be returned.
// params: object of params containing the key 'id' for an UPDATE/DELETE/ACTION request. This is required for
// these types of actions because of how a crudId is generated. See getCrudId().
export const useCrudStatus = function(resource, restType, params) {  
    return useSelector((state) => {
        let selector = ['CrudReducer', resource];
        if(restType) {
            const crudId = getCrudId(restType, params);
            selector.push(crudId);
        }
        // console.log('selector: ', selector.join('.'));
        return get(state, selector.join('.'), {});
    });
};

// A side-effect hook that executes whenever the status of a @cuda-react's 
// crud request goes from start to finished, i.e. the 'loading|saving|deleting|actioning' flag goes from non-zero to 0.
// Note that this doesn't check if the status is successful, 
// you need to check if stats.error is null.
// The crud status object is from hooks like useCrudProps, useCrudFetch..., as
// long as it has the .loading flag.
export const useOnCrudComplete = function(callback, status) {
    const prevStatus = usePrevious(status);
    return useDeepCompareEffect(() => {
        if(prevStatus && status && !(status.loading || status.saving || status.deleting || status.actioning)) {
            return callback(status);
        }
    }, [status]);
};

// Same as useOnCrudComplete except there's an additional check that the status is 'successful' (no errors)
// and the callback is given the crud data and original status
export const useOnCrudSuccess = function(callback, status) {
    return useOnCrudComplete(() => {
        if(!status.error && !status.errorData && !status.errorSource) {
            return callback(getCrudData(status), status);
        }
    }, status)
};