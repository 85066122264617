import React from 'react';
import PropTypes from "prop-types";
import {BasicPage} from "@cuda-react/core";
import {usePreloadAppData} from 'hooks';
import LoadingBar from 'components/common/LoadingBar';

// Since the cuda-react framework doesn't have a way to cleanly load data before routing, we
// wrap pages inside this component to make sure that some resources are loaded first. The
// loading page is just a BasicPage component. Props outside of those defined in propTypes will
// be forwarded to the BasicPage component.
export const LoadingPage = (props) => {
    const {content, contentProps, loading, loadingMessage, ...loadingPageProps} = props;
    const isLoaded = usePreloadAppData();

    return !isLoaded 
        ? <BasicPage {...loadingPageProps}>
                <LoadingBar loading={true} message={loadingMessage} />
            </BasicPage>
        : React.createElement(content, contentProps);
};

LoadingPage.propTypes = {
    content: PropTypes.elementType.isRequired,
    contentProps: PropTypes.object,

    // additional flag to indicate the page is still loading, 
    // useful if you're loading more than what's checked in this component
    loading: PropTypes.bool,
    loadingMessage: PropTypes.string,
}

LoadingPage.defaultProps = {
    loading: false,
    loadingMessage: 'Loading resources...'
}

export default LoadingPage;