import React from 'react';
import PropTypes from "prop-types";
import Button from './Button';

// A component that renders a button that opens and closes dialog(s).
// This component is similar to cuda-react's ButtonDialog except this doesn't render the <Dialog> element like that component does.

// Usage 1: wrap this element around a Dialog element. 
// Ex. <OpenButtonDialog> 
//        <Dialog>...</Dialog>
//    </OpenButtonDialog>

// Usage 2: pass a dialog component to props
// Ex: <OpenButtonDialog dialog={Dialog} dialogProps={...} />

// Most props passed to this component are passed to the underlying <Button> element. 
// closeDialogPropName: string or array of strings of the dialog prop name that the dialog expects to call to close itself, e.g. "handleClose"
const OpenDialogButton = ({buttonLabel, children, closeDialogPropName, dialog, dialogProps, ...buttonProps}) => {
    const [openFlag, setOpen] = React.useState(false);

    const openDialog = () => {
        setOpen(true);
    }
    const closeDialog = () => {
        setOpen(false);
    }

    let baseProps = {open: openFlag};
    (Array.isArray(closeDialogPropName) ? closeDialogPropName : [closeDialogPropName]).forEach((name) => {
        if(name) {
            baseProps[name] = closeDialog;
        }
    });

    return <React.Fragment>
        <Button {...buttonProps} onClick={openDialog}>{buttonLabel}</Button>
        {React.Children.map(children, (child,  i) => {
            let props = {
                ...child.props,
                ...baseProps
            };
            return React.cloneElement(child, props);
        })}
        {dialog && React.createElement(dialog, {...(dialogProps||{}), ...baseProps})}
    </React.Fragment>
};

OpenDialogButton.propTypes = {
    buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType, PropTypes.element]).isRequired,
    closeDialogPropName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    dialog: PropTypes.elementType,
    dialogProps: PropTypes.object
}

OpenDialogButton.defaultProps = {
    closeDialogPropName: ['handleClose', 'onClose'],
}

export default OpenDialogButton;