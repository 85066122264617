export const SET_LOCALSTORAGE = "SET_LOCALSTORAGE";
export const UPDATE_LOCALSTORAGE = "UPDATE_LOCALSTORAGE";

export const setLocalStorage = (data) => {
    return {type: SET_LOCALSTORAGE, data};
}

export const updateLocalStorage = (data) => {
    return {type: UPDATE_LOCALSTORAGE, data};
}
