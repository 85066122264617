import React from 'react';
import Link from '@material-ui/core/Link';

// A verify simple component to parse urls in text and make them clickable.
// usage: <Linkify>Go to this site https://google.com to search</Linkify>

export const Linkify = (props) => {
    if(typeof props.children === 'string') {
        const components = props.children.split(/(https?:\/\/[^\s]+)/);
        const rebuilt = [];
        components.forEach((item, i) => {
            if(item.indexOf('http') > -1) {
                rebuilt.push(<Link key={i} href={item} target="_blank">{item}</Link>);
            } else {
                rebuilt.push(item);
            }
        });
        return rebuilt;
    }
    return props.children;
};

export default Linkify;