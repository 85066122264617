import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import MUIListItem from '@material-ui/core/ListItem';
import MUIListItemIcon from '@material-ui/core/ListItemIcon';
import MUIListItemText from '@material-ui/core/ListItemText';

const styles = makeStyles(theme => ({
    listItem: {
        margin: 4,
        borderRadius: 2,
        padding: "6px 5px",
        width: "calc(100% - 8px)",
        display: "flex",
        alignItems: "center"
    },
    listItemIcon: {
        minWidth: 0,
        marginRight: 15
    },
    listItemText: {
        margin: 0,
        fontSize: 12,
        fontWeight: 400,
        textTransform: "uppercase"
    }
}));

// usage: <ListItem onClick={}>Click me</ListItem>

const ListItem = (props) => {
    const classes = styles();
    const {icon, children, ...listItemProps} = props;
    return <MUIListItem className={classes.listItem} {...listItemProps}>
        {icon ? <MUIListItemIcon className={classes.listItemIcon}>{icon}</MUIListItemIcon> : null}
        <MUIListItemText className={classes.listItemText} primaryTypographyProps={{variant: 'body2'}}>
            {children}
        </MUIListItemText>
    </MUIListItem>
}

ListItem.propTypes = {
    icon: PropTypes.element
};

ListItem.defaultProps = {
};

export default ListItem;