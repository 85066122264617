import React from "react";
import {set, merge} from 'lodash';
import {Redirect, Route} from "react-router";
import {CudaReactApp} from "@cuda-react/core";
import {StatusGaugeIcon, SettingsIcon, AuditLogIcon} from "@cuda-react/icons";
import CloudQueueIcon from '@material-ui/icons/CloudQueue';

import AppLoader from "./AppLoader";
import AdminPage from "./components/Admin/AdminPage";
import AuditPage from "./components/Audit/AuditPage";
import BccLoginPage from "./components/BccLoginPage";
import DeploymentsPage from "./components/Deploy/DeploymentsPage";
import DeploymentMenuIcon from "./components/Deploy/DeploymentMenuIcon";
import SolutionsPage from "./components/SolutionsPage";
import StatusPage from "./components/Status/StatusPage";
import SettingsPage from "./components/Settings/SettingsPage";
import WorkspacesPage from "./components/Workspaces/WorkspacesPage";
import TestPage from "./components/TestPage";

import customSagas from "./sagas/"

import AppBarCustomTitle from "./components/common/AppBarCustomTitle";
import CustomReducer from "./reducers/custom";
import CustomAuthReducer from "./reducers/auth";
import TasksReducer from "./reducers/tasks";
import GlobalDataReducer from "./reducers/globalData";
import SettingsReducer from "./reducers/settings";
import LocalStorageReducer from "./reducers/localStorage";

import CustomLogoutButton from "./components/common/CustomLogoutButton";
import bccAuthClient from "./services/bccAuthClient";
import jsonClient from "./services/jsonClient";
import httpClient from "./services/httpClient";
import {resourceUrlMap, reformatters} from "./services/api";

import {
    baseTheme, 
    themeMapping
} from './theme';

const App = (props) => {
    const theme = localStorage.getItem("theme");
    const customColor = localStorage.getItem("color") || '';

    let customTheme = merge({}, baseTheme, themeMapping[theme] || {});
    if(customColor && (!theme || theme === 'default')) {
        set(customTheme, 'palette.primary.main', customColor);
    }

    return(
        <CudaReactApp
            title={<AppBarCustomTitle />}
            // title="Barracuda Customer Experience Center"
            customLogoutButton={CustomLogoutButton}
            authClient={bccAuthClient}
            jsonClient={jsonClient}
            httpClient={httpClient}
            resourceUrlMap={resourceUrlMap}
            reformatters={reformatters}
            customTheme={customTheme}
            customSagas={[null, customSagas]}
            customReducers={{CustomAuthReducer, CustomReducer, GlobalDataReducer, SettingsReducer, LocalStorageReducer, TasksReducer}}
            customLoginPage={BccLoginPage}
            nonRouteChildren={<AppLoader />}
        >
                <Route
                    exact
                    name="Templates"
                    path="/templates"
                    component={SolutionsPage}
                    icon={CloudQueueIcon}
                />
                <Route
                    exact
                    name="Deployments"
                    path="/deployments"
                    component={DeploymentsPage}
                    icon={DeploymentMenuIcon}
                />
                <Route
                    exact
                    icon={SettingsIcon}
                    name="Settings"
                    path="/settings"
                    component={SettingsPage}
                />
                <Route
                    name="Status"                
                    path="/status"
                    icon={StatusGaugeIcon}
                    component={StatusPage}
                />
                <Route 
                    name="Audit Log"
                    path="/audit"
                    icon={AuditLogIcon}
                    component={AuditPage}
                />
                <Route 
                    exact
                    path="/workspace"
                    component={WorkspacesPage}
                />
                <Route 
                    path="/admin"
                    component={AdminPage}
                />
                <Route 
                    exact
                    path="/test"
                    component={TestPage}
                />
                <Redirect to="/templates"/>
        </CudaReactApp>
    );
};

export default App;