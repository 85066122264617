import React from "react";
import {
    ConnectedForm,
    TextInput,
    ButtonDialog, 
    DialogBody
} from "@cuda-react/core";

import {useCurrentAccountId} from 'hooks';


export const CreateWorkspaceBtn = () => {
	return (
		<ButtonDialog buttonText="New Workspace">
			<CreateWorkspaceDialog />
		</ButtonDialog>
	);
};

export const CreateWorkspaceDialog = (props) => {
    // Note to self: ButtonDialog gives its child  a callback 'onClose'. Call it to close the dialog.
	return (
		<DialogBody title="New Workspace">
			<CreateWorkspaceForm create onCancel={props.onClose} onSubmitSuccess={props.onClose} />
		</DialogBody>
	);
};

export const CreateWorkspaceForm = (props) => {
    const currentAccountId = useCurrentAccountId();

    // inject current account
    function formatRequestData(data) {
        data.account = currentAccountId;
        return data;
    }

    return ( 
        <ConnectedForm         
            resource="workspaces" 
            formatRequestData={formatRequestData}
            form="create-workspace"
            {...props}
        >
            <TextInput source="name" label="Workspace Name" required />
        </ConnectedForm>
    );
};

export default CreateWorkspaceForm;