import {REMOVE_TASK, UPDATE_TASK_STATUS} from '../actions/tasks';
const initialState = {}

export default (state = initialState, action) => {
    if(action.type === REMOVE_TASK) {
        let newState = {...state};
        delete newState[action.taskId];
        return newState;
    }
    if(action.type === UPDATE_TASK_STATUS) {
        let newState = {...state};
        newState[action.taskId] = action.status;
        return newState;
    }
    // if(action.type === GET_TASK_STATUS) {
    //     let newState = {...state};
    //     newState[action.taskId] = 1;
    //     return newState;
    // }
    return state;
}