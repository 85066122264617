// see hooks/globalData for details

import {SET_GLOBAL_DATA, UPDATE_GLOBAL_DATA} from '../actions/globalData';

export default (state = {}, action) => {
    if(action.type === SET_GLOBAL_DATA) {
        return {};
    }
    if(action.type === UPDATE_GLOBAL_DATA) {
        return {...state, ...(action.data || {}) };
    }
    return state;
}