import React from "react";
import PropTypes from "prop-types";
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

export const LoadingBar = ({loading, message, gutterBottom, ...props}) => {
    return <div className="LoadingBar-root" style={{display: loading ? 'block' : 'none', marginBottom: gutterBottom ? 20 : 0}}>
        <Typography gutterBottom variant="body2" display="block">{message || 'Loading...'}</Typography>
        <LinearProgress />
    </div>;
}

LoadingBar.propTypes = {
    loading: PropTypes.bool,
    message: PropTypes.string,
    gutterBottom: PropTypes.bool,
}

LoadingBar.defaultProps = {
    loading: false,
    message: 'Loading...'
}

export default LoadingBar;