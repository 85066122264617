// Kinda similar to cuda-react's AppBarMenuButton except this support additional menu item types.

import React from "react";
import PropTypes from "prop-types";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";

import Typography from "@material-ui/core/Typography";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { overrideableStyles } from "@cuda-react/theme";
import AppBarButton from './AppBarButton';

const styles = makeStyles(overrideableStyles("AppBarMenuButton", function (theme) {
    return {
        menu: {
            maxHeight: 300
        },
        iconColors: {
            fill: theme.palette.background.paper
        },
        arrow: {
            marginLeft: '3px !important'
        },
        listSubheader: {
            paddingTop: 5,
            paddingBottom: 5,
            lineHeight: 'inherit'
        },
        listItem: {
            '& .MuiListItemText-inset': {
                paddingLeft: 25
            }
        },
        listItemIcon: {
            minWidth: 25,
            '&>.MuiSvgIcon-root': {
                height: 18,
                width: 18
            }
        },
        listItemText: {
        }
    };
}));

export const AppBarMenuButton = (props) => {
    const classes = styles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isOpen, setMenuOpen] = React.useState(false);

    const onBtnClick = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
        if(props.onClick) {
            props.onClick(Boolean(event.currentTarget));
        }
    };

    const closeMenu = () => {        
        setAnchorEl(null);
        setMenuOpen(false);
        if(props.onClose) {
            props.onClose();
        }
    };

    let items = [];
    if(props.items && props.items.length) {
        items = items.concat(props.items.map((item, i) => {
            if(item === '-') {
                return <Divider key={i} />;
            } else if(typeof item === 'string') {
                return <ListSubheader key={i} className={classes.listSubheader}>{item}</ListSubheader>
            } else if(item) {
                const ItemType = item.onClick ? MenuItem : ListItem;
                const ItemIcon = item.icon;
                const onClick = (event) => {
                    item.onClick(event);
                    if(item.keepMenuOpen !== true) {
                        closeMenu();
                    }
                };
                return <ItemType 
                        key={i}
                        dense={item.dense !== false}               
                        className={classes.listItem}
                        disabled={item.disabled === true}
                        selected={item.selected === true}
                        divider={item.divider === true}
                        onClick={item.onClick ? onClick : null}
                    >
                        {ItemIcon && <ListItemIcon className={classes.listItemIcon}><ItemIcon /></ListItemIcon>}
                        <ListItemText inset={item.inset === true} className={classes.listItemText}>{item.label}</ListItemText>
                </ItemType>
            }
        }));
    }

    const ButtonIcon = props.icon;
    return (
        <React.Fragment>            
            <AppBarButton onClick={onBtnClick} disabled={props.disabled}>
                {ButtonIcon &&  <ButtonIcon classes={{primaryPath: classes.iconColors}} />}
                <Typography variant="body2" className={classes.colors}>{(props.label)}</Typography>
                <ArrowDropDown className={classes.arrow} />
            </AppBarButton>
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{horizontal: "right", vertical: "bottom"}}
                transformOrigin={{horizontal: "right", vertical: "top"}}
                open={Boolean(anchorEl) && (props.manualOpen ? props.open : isOpen)}
                onClose={closeMenu}
                classes={{paper: classes.menu}}
            >
                {items}
            </Menu>
        </React.Fragment>
    )

};


AppBarMenuButton.propTypes = {
    // by default, menu open/closing is self-handled. Set manual open to false and the 'open' flag will be used instead
    manualOpen: PropTypes.bool,
    open: PropTypes.bool,

    icon: PropTypes.elementType,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    onClick: PropTypes.func, // when the button is clicked (right before the menu opens)
    onClose: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.oneOfType(
        PropTypes.string, // "-" for divider, otherwise item is considered to be subheader (for now)
        PropTypes.shape({
            label: PropTypes.string,
            icon: PropTypes.elementType,
            onClick: PropTypes.func,
            keepMenuOpen: PropTypes.bool,
            props: PropTypes.object
        })
    ))
};

AppBarMenuButton.defaultProps = {
    manualOpen: false,
    items: []
}

export default AppBarMenuButton;