import React from "react";
import { Switch, Route, Redirect } from "react-router";

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import {Tab, BasicPage, useGlobalParam} from "@cuda-react/core";
import {SCsIcon as WorkspacesIcon} from "@cuda-react/icons";

import BetterSideTabs from 'components/common/BetterSideTabs';
import CostPage from './Cost/CostPage';
import WorkspacesPage from './Workspaces/WorkspacesPage';
import WorkspaceDetailsPage from './Workspaces/WorkspaceDetailsPage';
import Alert from '@material-ui/lab/Alert';

// check if user is admin before rendering component, display error page otherwise
const CheckAdminPersmissionPageWrap = ({component, ...props}) => {
    const [isAdmin, ] = useGlobalParam("userData.privileges.admin");
    const Component = component;
    return isAdmin
    ? <Component {...props} />
    : <BasicPage 
        title="Administration" 
        authenticated>
            <Alert severity="error" style={{marginBottom: 15}}>You do not have permission to view this page</Alert>
        </BasicPage>;
};

export const AdminPage = (props) => {
    const [isAdmin, ] = useGlobalParam("userData.privileges.admin");
    const match = props.match;

    return (
        <Switch>
            <Route exact path={[`${match.path}/costs`, `${match.path}/workspaces`]}>
                <BasicPage 
                    title="Administration" 
                    authenticated>
                        {isAdmin ? 
                            <BetterSideTabs>
                                <Tab
                                    hash={`${match.path}/costs`}
                                    label="Cost Explorer"
                                    icon={AttachMoneyIcon}
                                >
                                    <CostPage />
                                </Tab>
                                <Tab
                                    hash={`${match.path}/workspaces`}
                                    label="Workspaces"
                                    icon={WorkspacesIcon}
                                >
                                    <WorkspacesPage />
                                </Tab>
                            </BetterSideTabs>
                            : <Alert severity="error" style={{marginBottom: 15}}>You do not have permission to view this page</Alert>}
                </BasicPage>
            </Route>

            <Route exact path={`${match.path}/workspaces/:workspace`} render={(routeProps) => { 
                return <CheckAdminPersmissionPageWrap component={WorkspaceDetailsPage} {...routeProps} />
            }} />

            <Redirect to={`${match.path}/costs`} />
        </Switch>
    )
};

export default AdminPage;