import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

import {AuditLogIcon} from "@cuda-react/icons";

import {
    ListPage,
    TextField,
} from "@cuda-react/core";

import RefreshResourceBtn from "components/common/RefreshResourceBtn";
import {
    useGetCompletedTask, 
    useCurrentAccountId
} from 'hooks';

const styles = makeStyles({
    statusCell: {
        width: 25
    },
});




export const AuditPage = (props) => {
    const classes = styles();

    const currentAccountId = useCurrentAccountId();

    return (
        <ListPage		
            authenticated
            icon={AuditLogIcon}
            resource="audit"
            title="Audit Log"
            refreshInterval={0}
            paginate={false}
            params={{filter: {account: currentAccountId}}}
            actions={[<RefreshResourceBtn resource="audit" params={{filter: {account: currentAccountId}}} />]}
            noRouter={true}
        >
            <TextField source="time" label="Time" />
            <TextField source="username" label="User" />
            <TextField source="action" label="Action" />
            <TextField source="workspace" label="Workspace" />
        </ListPage>
    )

}

export default AuditPage;