// Styled heading with some basic decorations.
// Usage: <Heading icon={StartIcon} endIcon={EndingIcon}>I am a heading!</Heading>

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Typography from "@material-ui/core/Typography";
const styles = makeStyles(theme => ({
    root: {
        color: theme.palette.text.primary,
        margin: theme.spacing(0, 1, 0.5),        
    },
    
    mediumRoot: {
        '& $iconContainer': {
            height: 25,
            width: 25,
            marginRight: theme.spacing(1),
            '& > svg': {
                height: "25px !important",
                width: "25px !important",
                marginTop: 4
            },
        },
        '& $title': {
            fontSize: 25,
            fontWeight: 300,
        }
    },

    smallRoot: {
        '& $iconContainer': {
            height: 20,
            width: 20,
            marginRight: theme.spacing(1),
            '&> svg': {
                height: "20px !important",
                width: "20px !important",
                marginTop: 2
            },
        },
        '& $title': {
            fontSize: 20,
            fontWeight: 300,
        }
    },

    iconContainer: {
        "& > svg": {
          height: "32px !important",
          width: "32px !important",
          marginTop: 4
        },        
        verticalAlign: "top",
        height: 32,
        width: 32,
        display: "inline-block"
    },
    startIcon: {
        marginRight: theme.spacing(1),
    },
    endIcon: {
        marginLeft: theme.spacing(1),
    },
    title: {
        display: 'inline-block',
        fontWeight: 300,
        color: theme.palette.text.tertiary
    },

    buttons: {
        display: "inline-flex",
        "float": "right"
    }
}));

const Heading = (props) => {
    const classes = styles();
    const {icon, endIcon, size, className, variant, buttons, ...others} = props;
    const StartIcon = icon || null;
    const EndIcon = endIcon || null;

    let sizeCls = '';
    if(['medium', 'small'].indexOf(size) > -1) {
        sizeCls = classes[size + 'Root'];
    }

    return <div className={[classes.root, sizeCls, className||''].join(' ')} {...others}>
        {StartIcon && <div className={[classes.iconContainer, classes.startIcon].join(' ')}><StartIcon /></div>}
        <Typography variant={variant || 'h4'} component="div" className={classes.title}>{props.children}</Typography>
        {EndIcon && <div className={[classes.iconContainer, classes.endIcon].join(' ')}><EndIcon /></div>}
        {buttons && <span className={classes.buttons}>{buttons}</span>}
    </div>
};

Heading.propTypes = {
    icon: PropTypes.object,
    endIcon: PropTypes.object,
    className: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    buttons: PropTypes.node,
}

Heading.defaultProps = {
    variant: 'h4'
};

export default Heading;