import React from "react";
// import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import Link from '@material-ui/core/Link';

// const pageStyles = makeStyles({
    
// });

export const ConnectInstructionsDialog = ({resource, ...props}) => {
    // const classes = pageStyles();

    // Note to self: this dialog is given a callback 'handleClose'. Call it to close the dialog.
	return (
        <Dialog maxWidth="md" open={props.open} onClose={props.handleClose}>dd
            <DialogTitle className="dialog-title">Connect to Resource</DialogTitle>
            <div>
                {resource.type === 'Windows' && <React.Fragment>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>Remote Administration</TableCell>
                                <TableCell><Link href={`rdp://${resource.username}@${resource.public_ip_address}`}>Launch Remote Desktop</Link></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>IP Address</TableCell>
                                <TableCell>{resource.public_ip_address || resource.ip_address || 'NA'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Username</TableCell>
                                <TableCell>{resource.username}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Password</TableCell>
                                <TableCell>{resource.password}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </React.Fragment>}
                {(resource.type === 'Linux' || resource.type === 'CGFW' || resource.type === 'WAF') && <React.Fragment>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                <TableCell>Remote Administration</TableCell>
                                <TableCell><Link href={`ssh://${resource.username}@${resource.public_ip_address}`}>Launch SSH Client</Link></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>IP Address</TableCell>
                                <TableCell>{resource.public_ip_address || resource.ip_address || 'NA'}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Username</TableCell>
                                <TableCell>{resource.username}</TableCell>
                            </TableRow>                            
                            {resource.password && <TableRow>
                                <TableCell>Password</TableCell>
                                <TableCell>{resource.password}</TableCell>
                            </TableRow>}
                            {resource.key && <TableRow>
                                <TableCell>Private Key</TableCell>
                                <TableCell><div className="code-box">{resource.key}</div></TableCell>
                            </TableRow>}
                        </TableBody>
                    </Table>
                </React.Fragment>}
            </div>
            <DialogActions>
                <Button variant="contained" color="primary" onClick={props.handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
	);
};

export default ConnectInstructionsDialog;