import {httpJsonClient} from './httpClient';

// const apiBase = "http://localhost/proxy.php?csurl=http://cecapp-api.azurewebsites.net";
const apiBase = "https://cecapp-api.azurewebsites.net";

// this is exported for the cuda-react framework
export const resourceUrlMap = {
    // note to self: only one URL is needed for all CRUD operations, the framework inject (params+globalParams)
    // into the URL, especially the 'id' param for UPDATE calls
    exampleUrl: apiBase + "/rules/{id}/{changeOrder}",

    allSolutions: apiBase + '/solutions',
    audit: apiBase + "/audit",
    
    workspaces: apiBase + "/workspaces/{id}",
    solutions: apiBase + "/workspaces/{workspace}/solutions/{id}",
    clouds: apiBase + "/clouds/{id}",
    cloudsTest: apiBase + "/clouds/test",
    tasks: apiBase + "/tasks/{id}", 
    login: apiBase + "/login",
    
    auth: apiBase + "/auth/login", // new bcc login endpoint
    authRedirectUrl: apiBase + '/auth/redirect',

    deployments: apiBase + '/workspaces/{workspace}/deployments/{id}',
    deployToWorkspace: apiBase + '/workspaces/{workspace}/deployments/{id}',
    listDeployments: apiBase + '/workspaces/{workspace}/deployments',
    listResources: apiBase + '/resources',
    addBrsSerial: apiBase + '/workspaces/{workspace}/deployments/{id}/brs',
    feedback: apiBase + '/feedback',

    adminCost: apiBase + '/admin/cost',
    adminCostByUsers: apiBase + '/admin/cost/users',
    adminCostBySolutions: apiBase + '/admin/cost/solutions',
    adminCostByWorkspace: apiBase + '/admin/cost/workspace',

    adminWorkspaces: apiBase + '/admin/workspaces/{id}',
};

// this is exported for the cuda-react framework
export const reformatters = {
    
    workspaces: (type, response, params) => {
        if(type === 'GET' && response.data && Array.isArray(response.data)) {
            let workspaces = response.data.map((w) => {
                w.id = w.name;
                return w;
            });
            response.data = workspaces;
        }
        return response;
    },

    tasks: (type, response, params) => {
        if(type === 'GET' && response.data) {
            // api returns object keyed by task_id, convert to array
            let tasks = Object.keys(response.data).map((key) => {
                return response.data[key];
            });

            // backend doesn't support sorting and there's no local sorting
            /*
            const sorting = params.sort || {field: 'date_started', order: 'DESC'};
            if(sorting.field) {
                const compareFn = (a, b) => {
                    if(a && b) {
                        let va = a[sorting.field], 
                            vb = b[sorting.field];
                        if(sorting.field === 'date_started' || sorting.field === 'date_done') {
                            va = Number(Date.parse(a.date_started));
                            vb = Number(Date.parse(b.date_started));
                        }
                        if(sorting.order === 'DESC') {
                            return vb > va;
                        }
                        return va > vb;
                    }
                    return 0;
                }
                tasks = tasks.sort(compareFn);
            }*/

            response.data = tasks;
        }
        return response;
    },

    listDeployments: (type, response, params) => {
        // convert deployments object into array
        if(type === 'GET' && response.data && typeof response.data === 'object') {
            if(typeof response.data.deployments === 'object') {
                const deploymentsObj = response.data.deployments;
                const deployments = Object.keys(deploymentsObj).map((id) => {
                    return {id, ...deploymentsObj[id]}
                });
                response.data.deployments = deployments;
            }
        }
        return response;
    },

    // API has been fixed, this isn't needed anymore
    XXXXXXXXdeploymentsXXXX: (type, response, params) => {
        // api returns an object where the keys are the deployment ids, put the key inside the deployment
        // object and return an array instead
        if(type === 'GET' && response.data && typeof response.data === 'object') {
            if(params.id) {
                response.data = response.data[params.id];
                response.data.id = params.id;
            } else if(typeof response.data.deployments === 'object') {
                const deploymentsObj = response.data.deployments;
                const deployments = Object.keys(deploymentsObj).map((id) => {
                    return {id, ...deploymentsObj[id]}
                });
                response.data.deployments = deployments;
            }
        }
        return response;
    }

};


// make request with query params
export const get = function(path, params) {
    return request(path, {
        method: 'GET',
        params: params
    });
};

export const del = function(path, params) {
    return request(path, {
        method: 'DELETE',
        params: params
    });
};

export const post = function(path, data) {
    return request(path, {
        method : 'POST',
        body: JSON.stringify(data)
    });
};

export const put = function(path, data) {
    return request(path, {
        method: 'PUT',
        body: JSON.stringify(data)
    });
};

export const patch = function(path, data) {
    return request(path, {
        method: 'PATCH',
        body: JSON.stringify(data)
    });
};

export const request = function(path, options) {
    let url = (path.startsWith('http') ? '' : apiBase) + path;
    if(options && options.params) {
        url += '?' + new URLSearchParams(options.params).toString();
        delete options.params;
    }
    const clientOptions = {
        headers: {"Content-Type": "application/json;charset=UTF-8"},
        ...(options || {})
    };
    return httpJsonClient(url, clientOptions);
}

const api = {
    get,
    post,
    put,
    del,
    patch,
    request
}

export default api;