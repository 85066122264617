import React from "react";
// import {useRouteMatch} from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import MemoryIcon from '@material-ui/icons/Memory';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';

import Heading from 'components/common/Heading';
import Linkify from 'components/common/Linkify';
import ResourcesTable from './ResourcesTable';
import ExportIcon from '@material-ui/icons/ImportExport';

//import Button from 'components/common/Button';
import OpenDialogButton from 'components/common/OpenDialogButton';
import TextViewerDialog from 'components/common/TextViewerDialog';


const pageStyles = makeStyles((theme) => ({
    section: {
        marginBottom: 40
    },
    assetsTbody: {
        maxHeight: 400, 
        overflow: 'auto'
    },
    resourcesActionColumn: {
        textAlign: 'right'
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main
    }
}));

export const DeploymentSummary = ({data, solutions, isAdmin, ...props}) => {
    const classes = pageStyles();    
    // const routeMatch = useRouteMatch();
    const deployments = data.deployments || [];
    const deploymentsById = {};
    // const instructions = data.instructions || [];

    let instructionsBySolution = {};
    let hasInstructions = false;
    deployments.forEach(function(d) {
        const id = parseInt(d.id, 10) || 0;
        if(id) {
            deploymentsById[id] = d;
        }
        if(d.solution && d.instructions) {
            if(instructionsBySolution[d.solution] === undefined) {
                instructionsBySolution[d.solution] = [];
            }
            d.instructions.forEach((inst) => {
                if(instructionsBySolution[d.solution].indexOf(inst) === -1) {
                    instructionsBySolution[d.solution].push(inst);
                    hasInstructions = true;
                }
            });
        }
    });

    const resources = (data.resources || []).map(function(r) {
        r._deploymentName = '';
        const deploymentId = parseInt(r.deployment_id, 10) || 0;
        if(deploymentId && deploymentsById[deploymentId]) {
            const d = deploymentsById[deploymentId];
            r._deploymentName = d.name || `${d.solution} (${d.id})`
        }
        return r;
    });

    const solutionNameMapping = {};
    if(solutions) {
        solutions.forEach((solution) => {
            solutionNameMapping[solution.id] = solution.name;
        });
    }

    return <div>
        {!isAdmin && hasInstructions && <div className={classes.section}>
            <Heading icon={SettingsApplicationsIcon}>Configuration Instructions</Heading>
            <Card>
                <CardContent>
                    {Object.keys(instructionsBySolution).map((solution, x) => {
                        return <div key={x} style={{marginBottom: 10}}>
                            <Typography variant="h6">{solutionNameMapping[solution] || solution}</Typography>
                            {instructionsBySolution[solution].map((inst, i) => {
                                return <div key={i} style={{marginBottom:5}}>
                                    <Typography><Linkify >{inst}</Linkify></Typography>
                                </div>
                            })}
                        </div>
                    })}
                </CardContent>
            </Card>
        </div>}

        <div className={classes.section}>
            <Heading icon={MemoryIcon}>Resources</Heading>
            <Card>
                <div style={{width: '100%', height: 400}}>
                    <ResourcesTable mode="summary" resources={resources} />
                </div>
            </Card>
        </div>

        <div className={classes.section}>
            <Heading icon={AttachMoneyIcon}>Total Cost</Heading>
            <Card>
                <CardContent>
                    <Typography variant="body1">
                        Estimated cost to run all deployed resources: <strong>${Number(data.cost).toFixed(2)}</strong>
                    </Typography>
                </CardContent>
            </Card>
        </div>

        <div className={classes.section}>
            <Heading icon={ExportIcon}>Export</Heading>
            <Card>
                <CardContent>
                    <OpenDialogButton buttonLabel="Export" variant="contained" color="primary">
                        <TextViewerDialog 
                            title="Deployment Summary Export" 
                            text={JSON.stringify(deployments, null, 2)} 
                            download="CEC-All-Deployments.json" 
                            downloadMime="application/json"
                            />
                    </OpenDialogButton>
                    <Typography variant="body1">Click to view JSON export of all deployments.</Typography>
                </CardContent>
            </Card>
        </div>
    </div>
};

export default DeploymentSummary;