import React from "react";
import {ListPage, CustomField} from "@cuda-react/core";
import TextField from "@cuda-react/core/lib/components/fields/TextField";
import ActionButtonsField from "@cuda-react/core/lib/components/fields/ActionButtonsField";
import DeleteDialog from "@cuda-react/core/lib/components/dialog/DeleteDialog";
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import {AddCloudBtn} from "./AddCloudForm";
import RefreshResourceBtn from "components/common/RefreshResourceBtn";
import {cloudProviderIcons} from 'iconMapping';

export const CloudsPage = () => {
    return (
        <ListPage 
            title="Cloud Providers" 
            icon={SubscriptionsIcon}
            resource="clouds"
            refreshInterval={0}
            paginate={false}
            noRouter={true}
            actions={[<AddCloudBtn />, <RefreshResourceBtn resource="clouds" />]}
            >   
                <CustomField
                    label="Cloud Provider"
                    source="cloud"
                    // cellClassName={classes.statusCell}
                    render={(cloud) => {
                        const Icon = cloudProviderIcons[String(cloud).toLowerCase()];
                        return <React.Fragment>
                            {Icon && <Icon />}&nbsp; {cloud}
                        </React.Fragment>;
                    }}
                />             
                <TextField source="name" label="Name" />
                
                <TextField source="subscription_id" label="Subscription Id" />
                <TextField source="client_id" label="Client Id" />
                <TextField source="tenant_id" label="Tenant Id" />
                <ActionButtonsField>
                    <DeleteDialog
                        resource="clouds"
                        title="Confirm Removal"
                        message="Remove subscription {{name}}?"
                    />
                </ActionButtonsField>
        </ListPage>
    );
}

export default CloudsPage;
