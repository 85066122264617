import React from "react";
import AppBarWorkspaceSelector from './AppBarWorkspaceSelector';
import AppBarFeedback from './AppBarFeedback';
import AppBarTaskChecker from './AppBarTaskChecker';
import AppBarMainMenu from './AppBarMainMenu';

// Use this custom logout button component used by the CudaReactApp component to add additional 
// buttons to the App Bar at the top of the screen
export const CustomLogoutButton = (props) => {
    return (
        <React.Fragment>
            <AppBarFeedback />
            <AppBarTaskChecker />
            <AppBarWorkspaceSelector />
            <AppBarMainMenu />
        </React.Fragment>
    )
}

export default CustomLogoutButton;
