import React from "react";
import {get} from 'lodash';
import {} from "@cuda-react/core";
import {useDispatch, useSelector} from "react-redux";

import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Alert from '@material-ui/lab/Alert';

import {
    AppLayout, 
    AUTH_LOGIN, 
    useGlobalParam,
    setGlobalParams, 
    redirect,
    useDeepCompareEffect,
    crudResetAll    
} from '@cuda-react/core';

import bccAuthClient, {getLoginRedirect} from "services/bccAuthClient";
import LoadingBar from 'components/common/LoadingBar';
import {useQueryParams} from 'hooks';

import {setGlobalData, setSettings} from 'actions';


// note: we use authClient directly because using the built-in auth flow
// doesn't have a way to detect when the api request is finished. You either
// are logged in or an error is displayed. The AuthSage doesn't give any feedback.
const loginFormStyles = makeStyles({
    container: {
        minWidth: 400
    }
});

const LoginForm = (props) => {
    const classes = loginFormStyles();
    const dispatch = useDispatch();
    const queryParams = useQueryParams();
    const [authStatus, setAuthStatus] = React.useState({isLoading: false});
    const [globalParams, ] = useGlobalParam();

    const redirectUrl = useSelector((state) => {
        const auth = state['AuthReducer'] || {};
        return auth.origin && !auth.origin.startsWith('/login') ? auth.origin : '/';
    });

    // we do login here instead of using redux's authLogin action because it doesn't allow use to get the 
    // progress and result 
    const doLogin = () => {
        setAuthStatus({isLoading: true});
        bccAuthClient(AUTH_LOGIN, queryParams).then(function(result) {            
            if(result && result.userData) {
                // clear everything in case there's a previous session
                dispatch(setGlobalData({}));
                dispatch(setSettings({}));
                dispatch(crudResetAll());
                // note: useGlobalParam hooks uses the "update" method where it merges old with new params. We
                // want to replace all existing user data with new user data
                dispatch(setGlobalParams({
                    ...globalParams,
                    userData: result.userData
                }));
                setAuthStatus((state) => {
                    return {...state, result: result, error: null}
                });
            } else {
                setAuthStatus((state) => {
                    return {...state, result: null, error: "No auth result from auth client"}
                });
            }
        }).catch(function(error) {
            setAuthStatus((state) => {
                return {...state, result: null, error}
            });
        }).finally(function() {
            setAuthStatus((state) => {
                return {...state, isLoading: false}
            });
        });
    };

    useDeepCompareEffect(() => {
        if(queryParams.code) {
            doLogin();
        } else {
            getLoginRedirect().then((result) => {
                if(result.redirect) {
                    dispatch(redirect(result.redirect));
                }
            });            
        }
    }, [queryParams]);

    React.useEffect(() => {
        // redirect on successful login
        if(authStatus && authStatus.result && authStatus.result.userData) {
            const theme = get(authStatus.result.userData, 'appearance.theme');
            let color = get(authStatus.result.userData, 'appearance.color', '') || '';
            if(theme) {
                localStorage.setItem('theme', theme);
            }
            if(color) {
                if(color.charAt(0) !== '#') {
                    color = '#' + color;
                }
                localStorage.setItem('color', color);
            } else {
                localStorage.removeItem('color', color);
            }
            
            dispatch(redirect(redirectUrl));
            window.location.reload();
        }
    }, [authStatus]);

    const isLoading = authStatus.isLoading;
    return (
        <div className={classes.container}>
            {authStatus.error && authStatus.error.message && <Alert severity="error">{authStatus.error.message}</Alert>}
            <LoadingBar loading={isLoading || !queryParams.code} message="Signing in..." />
        </div>
    );
};





const pageStyles = makeStyles(theme => ({
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        color: '#FFFFFF',
    },
    dialogContent: {
        padding: 30
    }
}));

export const BccLoginPage = (props) => {
    const classes = pageStyles();
    return (
        <AppLayout>
            <Dialog open>
                <DialogContent className={classes.dialogContent}>
                    <LoginForm />
                </DialogContent>
            </Dialog>				
        </AppLayout>
    );
}


export default BccLoginPage;