import React from "react";
import Dialog from '@material-ui/core/Dialog';

import {
    ConnectedForm,
    TextInput,
    // ButtonDialog, 
    DialogBody,
    // useGlobalParam
} from "@cuda-react/core";


export const AddFrsSerialDialog = ({deployment, ...props}) => {
    // console.log('add frs serial', deployment);
    // const classes = styles();

    // const formatRequestData =  (data) => {
    //     data.id = deployment.id;
    //     return data;
    // };

    return (
        <Dialog open={props.open}>
            <DialogBody title="Firewall Insight Service Configuration">
                <ConnectedForm create 
                    params={{id: deployment.id}}
                    // formatRequestData={formatRequestData}
                    onCancel={props.handleClose} 
                    onSubmitSuccess={props.handleClose} 
                    resource="addBrsSerial" 
                    form="brs-serial-form">
                        <TextInput source="serial" label="Cloudgen Firewall Serial" />
                </ConnectedForm>
            </DialogBody>
        </Dialog>
    );
};

export default AddFrsSerialDialog;