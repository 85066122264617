import React from 'react';
import {useHistory, useLocation} from 'react-router';
import {parseQueryString} from '@cuda-react/core';
import { SdWanIcon } from "@cuda-react/icons";

import LoadingBar from 'components/common/LoadingBar';
import {DeploymentsPageContent} from 'components/Deploy/DeploymentsPage';

import {
    GET,
    useCrudFetch,
    useCrudProps,
    BasicPage,
} from '@cuda-react/core';

import {
    getCrudArrayData,
    getCrudData,
    numberFormat
} from 'utils';

export const WorkspaceDetailsPage = ({match, ...props}) => {
    const location = useLocation();
    const history = useHistory();
    const params = match && match.params ? match.params : {};    
    const queryParams = parseQueryString(location.search);
    const workspace = params.workspace || queryParams.workspace;

    const [returnTo, setReturnTo] = React.useState('');
    if(queryParams.return && !returnTo) {
        setReturnTo(queryParams.return);
    }

    const solutions = getCrudArrayData(useCrudProps('allSolutions'));

    const [workspaceDetailsResponse, workspaceDetailsStatus, fetchWorkspaceDetails] = useCrudFetch(GET, 'adminWorkspaces', {id: workspace});
    let deploymentData = getCrudData(workspaceDetailsResponse);

    // convert object to array
    if(deploymentData && deploymentData.deployments && typeof deploymentData.deployments === 'object') {
        let deployments = [];
        Object.keys(deploymentData.deployments).forEach((id) => {
            deployments.push({
                id,
                ...deploymentData.deployments[id]
            });
        });
        deploymentData.deployments = deployments;
    }


    if(!workspace) {
        history.push('/admin/workspaces');
    }

    React.useEffect(() => {
        fetchWorkspaceDetails();
    }, []);

    return <BasicPage 
        title={'Deployments: ' + workspace} 
        backLinkTo={returnTo || '/admin'}
        icon={SdWanIcon}
        authenticated>
            <LoadingBar loading={workspaceDetailsStatus > 0} />
            {deploymentData && <DeploymentsPageContent solutions={solutions} deploymentData={deploymentData} isAdmin={true} />}
    </BasicPage>
}

export default WorkspaceDetailsPage;