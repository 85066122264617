export const GET_TASK_STATUS = "GET_TASK_STATUS"; // start watching for task status
export const STOP_GETTING_TASK_STATUS = "STOP_GETTING_TASK_STATUS"; // stop watching for task status
export const UPDATE_TASK_STATUS = "UPDATE_TASK_STATUS";
export const REMOVE_TASK = "REMOVE_TASK"; // stop updating status and remove it from state

// add a task to be polled for status
export const getTaskStatus = (taskId) => {
    return {type: GET_TASK_STATUS, taskId};
}

export const stopGetTaskStatus = (taskId) => {
    return {type: STOP_GETTING_TASK_STATUS, taskId};
}

export const updateTaskStatus = (taskId, status) => {
    return {type: UPDATE_TASK_STATUS, taskId, status};
}

export const removeTask = (taskId) => {
    return {type: REMOVE_TASK, taskId};
}