import React from "react";
import BasicPage from "@cuda-react/core/lib/components/pages/BasicPage";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert'

import SolutionTile from 'components/common/SolutionTile';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';

import {
    GET,
    useCrudMonitor,
} from "@cuda-react/core";

import LoadingPage from 'components/common/LoadingPage';
import LoadingContent from 'components/common/LoadingContent';
import DeployWizardDialog from "components/Deploy/DeployWizardDialog";
import {getCrudArrayData} from 'utils';

export const SolutionsPageContent = (props) => {
    // fetching is done through subscription, see AppLoader
    const [solutionsResponse, isLoadingSolutions] = useCrudMonitor("solutions", GET);
    let solutions = getCrudArrayData(solutionsResponse);

    // use only one dialog instance for all solutions
    const [solutionToDeploy, setSolutionToDeploy] = React.useState(null);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const handleOpenDeployment = (solution) => {
        setSolutionToDeploy(solution);
        setDialogOpen(true);
    };
    const closeDialog = () => {
        setDialogOpen(false);
        // note to self: need to nullify this so we get a new DeployWizardDialog instance every time
        setSolutionToDeploy(null);
    }; 

    if(solutions.length) {
        solutions.sort(function(a, b) {
            return a.id === 'cloudvm' || a.id === 'cgwanbasic' ? -1 : a.id < b.id;
        });
    }

    return (
        <BasicPage authenticated title="Available Templates" icon={CloudQueueIcon}>
            <LoadingContent loading={isLoadingSolutions > 0} loadingMessage="Loading templates...">
                {solutions.length > 0 &&
                    <Grid container spacing={2}>
                        {solutions.map((solution) => (<Grid item key={solution.id}><SolutionTile solution={solution} handleOpenDeployment={handleOpenDeployment} /></Grid>))}
                    </Grid>
                }
            </LoadingContent>
            {!isLoadingSolutions && solutionsResponse &&
                (
                    solutionsResponse.error 
                    ? <Alert severity="error">Unable to load templates</Alert>
                    : (!solutions.length && <Typography>No templates defined</Typography>)
                )
            }
            {solutionToDeploy && <DeployWizardDialog solution={solutionToDeploy} open={dialogOpen} handleClose={closeDialog} handleFinish={closeDialog} />}
        </BasicPage>
    )
}

export const SolutionsPage = (props) => {
    return <LoadingPage 
                authenticated
                title='Available Templates'
                icon={CloudQueueIcon}
                content={SolutionsPageContent} />
}

export default SolutionsPage;
