import React from 'react';
import PropTypes from "prop-types";
import withStyles from '@material-ui/core/styles/withStyles';
import WizardStep from './WizardStep';

// A styleless skeleton wizard component that only has logic for navigating between steps as
// well as methods for letting step pass information configs (aka information) back to the wizard.
// Each step gets a "wizardProps" prop object that has details about that step as well as a reference to the wizard instance.
// You can also get this wizard instance externally by passing setWizard={callback} to the Wizard. This function will be
// called with the wizard prop when the wizard is mounted.
// Example:
// const saveWizardControl = (wizard) => { ... save wizard object somewhere };
// <Wizard setWizard={saveWizardControl} ...>
class Wizard extends React.Component {

    constructor(props) {
        super(props);
        this.state = this.initialState();
    }

    initialState = () => {
        const state = {
            activeStep: 0, 
            skipped: new Set(),
            stepConfigs: {}
        };
        return state;
    }

    initializeWizard() {
        if(typeof this.props.initialStep === 'number') {
            this.goToStep(this.props.initialStep, false); 
        }
        if(typeof this.props.activeStep === 'number') {
            this.goToStep(this.props.activeStep, false); 
        }
    }

    componentDidMount() {
        this.initializeWizard();
        this.callSetWizard();
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.state !== prevState) {
            this.callSetWizard();
        }
        // note: in order to support dynamic steps and activeStep, we have to allow step navigation if the number of children are different
        // because the number can be different when the component is mounted and when it's updated while the activeStep prop is the same.
        const currentSteps = React.Children.toArray(this.props.children);
        const previousSteps = React.Children.toArray(prevProps.children);
        if(typeof this.props.activeStep === 'number'
            && (this.props.activeStep !== prevProps.activeStep || (previousSteps.length !== currentSteps.length)) ) {
                this.goToStep(this.props.activeStep);
        }
    }

    callSetWizard() {
        if(this.props.setWizard) {
            this.props.setWizard(this);
        }
    }

    goToStep(index, fireStepChange = true) {
        const currentStep = this.state.activeStep;
        index = Math.max(0, Math.min(index, this.getSteps().length - 1));
        this.setState({ activeStep: index });
        if(fireStepChange && typeof this.props.onStepChange === 'function') {
            this.props.onStepChange(index, currentStep);
        }
    }

    goNextStep() {
        let newSkipped = this.state.skipped;
        if (this.isStepSkipped(this.state.activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(this.state.activeStep);
        }
        this.goToStep(this.state.activeStep + 1);
        this.setState({ skipped: newSkipped });
    }

    goPrevStep() {
        this.goToStep(this.state.activeStep - 1);
    }    

    goFirstStep() {
        this.goToStep(0);
    }
     
    goLastStep() {
        this.goToStep(this.totalSteps()-1);
    }

    skipStep() {
        this.goNextStep();
        const newSkipped = new Set(this.state.skipped.values());
        newSkipped.add(this.state.activeStep);
        this.setState({skipped: newSkipped});
    }

    isStepSkipped(index) {
        return this.state.skipped.has(index);
    }

    currentStep() {
        return this.state.activeStep;
    }

    // alias
    activeStep() {
        return this.state.activeStep;
    }

    isLastStep() {
        return this.state.activeStep >= this.totalSteps() - 1;
    }

    totalSteps() {
        return this.getSteps().length;
    }

    getSteps() {
        return React.Children.toArray(this.props.children);
    }

    updateStepConfigs(index, configs) {
        let stepConfigs = this.state.stepConfigs;
        stepConfigs['step'+index] = {
            ...(stepConfigs['step'+index] || {}), // current
            ...configs // new
        };
        this.setState({stepConfigs: stepConfigs});
    }
    
    getStepConfigs(index) {    
        var steps = this.getSteps();
        let configs = steps[index] ? this.extractStepConfigs(steps[index]) : {};
        let updatedConfigs = this.state.stepConfigs['step'+index] || {};
        return {
            ...configs,
            ...updatedConfigs
        }
    }

    getAllStepConfigs() {
        return React.Children.map(this.getSteps(), (child, index) => {
            let configs = this.extractStepConfigs(child);
            let updatedConfigs = this.state.stepConfigs['step'+index];
            return {
                ...configs,
                ...updatedConfigs
            }
        }, this);
    }

    // extract the wizard step configs from the node's "wizardConfigs" prop, e.g. <Step wizardConfigs={{validated: true, ....}} />
    extractStepConfigs(node) {
        return {
            validated: false,
            label: node.props.label || '',
            ...(node.props.wizardConfigs || {})
        };
    }

    render() {
        const {classes} = this.props;
        const {activeStep} = this.state;

        const baseProps = {
            wizard: this,
            activeStep: activeStep,
            isLastStep: this.isLastStep(),
            totalSteps: this.totalSteps(),
        };

        const children = React.Children.map(this.getSteps(), (child, i) => {
            const props = {
                ...child.props,
                active: i === activeStep, // for WizardStep
                wizardProps: {
                    ...baseProps,
                    // specific to this step
                    index: i,
                    updateStep: this.updateStepConfigs.bind(this, i), // pre-bound shortcut helper
                    configs: this.getStepConfigs(i),
                }
            };
            return <WizardStep key={i} {...props}>{
                React.cloneElement(child, props)
            }</WizardStep>
        }, this);
        
        const navProps = {wizardProps: baseProps};
        const topNavElement = this.props.topNav ? React.cloneElement(this.props.topNav, navProps) : null;
        const bottomNavElement = this.props.bottomNav ? React.cloneElement(this.props.bottomNav, navProps) : null;

        return (
            <div className={classes.wrapper}>
                {topNavElement}
                {children}
                {bottomNavElement}
            </div>
        );
    }

}

const styles = theme => ({
    wrapper: {
        position: 'relative'
    }
});


Wizard.propTypes = {
    // step to jump to on initial rendering
    initialStep: PropTypes.number,
    // Render this step, this will override any current manual navigation. 
    // Use this to navigate by props.
    activeStep: PropTypes.number,
    topNav: PropTypes.element,
    bottomNav: PropTypes.element,
    classes: PropTypes.object.isRequired,

    // This gets passed the wizard object to control the wizard.
    // Use this object to control the wizard from outside.
    setWizard: PropTypes.func,
};

Wizard.defaultProps = {
    initialStep: 0,
    activeStep: null,
};

export default withStyles(styles)(Wizard);