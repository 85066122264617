import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { 
    // AUTH_LOGIN,
    // AUTH_LOGOUT,     
    CRUD_FETCH_SUCCESS,
    CRUD_FETCH_FAILURE, 
    // AUTHENTICATION_FAILED,
    // setGlobalParams, 
    redirect,
    notificationShowToast,
    ERROR_NOTIFICATION,
    INFO_NOTIFICATION
} from "@cuda-react/core";

import {getTaskStatus} from 'actions/tasks';

function* crudFetchFailure(action) {
    const error = action.error ||  null;

    if(error) {
        if(error.status === 401) {
            // intercept 401 response and redirect to login screen
            // yield put(setGlobalParams({}));
            yield put(redirect('/login'));
        } else if(error === 'authentication.failed') {
            // this is a special case when a crud fetch happens but user isn't logged in yet
            // Ignore this for now. If we force a logout here, we'll lose the 'origin' for redirect.
        } else {
            // show toast notification instead of dialog notification
            if(!(error instanceof TypeError)) {
                // console.log('crud fetch failure', error, action);
                if(action.resource) {
                    const reponseErrorMessage = error.message || error.error;
                    const msg = `Error loading resource "${action.resource}": ${reponseErrorMessage}`;
                    yield put(notificationShowToast(msg, ERROR_NOTIFICATION));
                } else {                    
                    yield put(notificationShowToast(error.message, ERROR_NOTIFICATION));
                }
            }
        }
    }
}

function* crudFetchSuccess(action) {
    // console.log('crudFetchSuccess', action);
    if(action.restType !== 'GET') {
        const response = action.payload;
        if(response && response.data && response.data.task) {            
            // yield put(notificationShowToast(`Task "${response.data.task}" started`, INFO_NOTIFICATION));
            yield put(getTaskStatus(response.data.task));
        }
    }
}

export default function* () {    
    yield takeLatest(CRUD_FETCH_FAILURE, crudFetchFailure);
    yield takeEvery(CRUD_FETCH_SUCCESS, crudFetchSuccess);
}