import React from "react";
import Dialog from '@material-ui/core/Dialog';

import {
    ConnectedForm,
    TextInput,
    // ButtonDialog, 
    DialogBody,
    useGlobalParam
} from "@cuda-react/core";


export const FeedbackDialog = (props) => {
    // const classes = styles();
    const [userData, ] = useGlobalParam('userData');
    const [currentWorkspaceId, ] = useGlobalParam("workspace");


    const validateForm = (data) => {
        let errors = {};
        if(!data.feedback || !String(data.feedback).trim()) {
            errors.feedback = 'Feedback message required';
        }
        return errors;
    };

    const formatRequestData = (data) => {
        let lines = [
            `Name: ${data.name}`,
            `Contact Info: ${data.contact}`,
            `Current Workspace: ${currentWorkspaceId}`,
            `Current User: ${userData.currentUser}`,
            `Feedback: ${data.feedback}`,
        ];
        return {text: lines.join("\r\n")};
    };

    return (
        <Dialog open={props.open}>
            <DialogBody title="Feedback">
                <ConnectedForm create 
                    onCancel={props.handleClose} 
                    onSubmitSuccess={props.handleClose} 
                    formatRequestData={formatRequestData}
                    validate={validateForm}
                    resource="feedback" 
                    form="feedback-form">
                        <TextInput source="name" label="Name (Optional)" />
                        <TextInput source="contact" label="Contact Info (Optional)" />
                        <TextInput source="feedback" label="Feedback" isRequired options={{multiline: true, rows: 5}}  />
                </ConnectedForm>
            </DialogBody>
        </Dialog>
    );
};

export default FeedbackDialog;