import React from 'react';
import { useHistory } from 'react-router';
import { useRouteMatch } from 'react-router';
import { stringify } from 'query-string';


import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Link from '@material-ui/core/Link';
import MUITextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { DataGrid } from '@material-ui/data-grid';

import DeploymentsDetailsDialog from './DeploymentsDetailsDialog';
import WorkspacesDetailsDialog from './WorkspacesDetailsDialog';

import {
    GET,
    useCrudFetch,
    useDeepCompareEffect
} from '@cuda-react/core';

import {
    getCrudArrayData,
    getCrudData,
    numberFormat
} from 'utils';

import { useQueryParams } from 'hooks';

import Heading from 'components/common/Heading';
import Button from 'components/common/Button';
import RouterLink from 'components/common/RouterLink';

// import {adminCosts} from 'fakedata';


const styles = makeStyles(theme => ({
    grid: {
        marginBottom: 15
    },
    gridUndoCellRenderStyling: {
        display: 'block !important',
    },
    toolbar: {
        display: 'flex',        
        flexDirection: 'row-reverse',
        marginBottom: 10,
    }
}));


const sortModels = {
    costByWorkspace: [{field: 'cost', sort: 'desc'}],
    costByUser: [{field: 'cost', sort: 'desc'}],
};

const intervalChoices = [
    {value: 1, name: '1 day'},
    {value: 7, name: '7 days'},
    {value: 30, name: '30 days'},
    {value: 60, name: '60 days'},
    {value: 90, name: '90 days'},
    {value: 0, name: 'ALL (Beginning of Time)'},
];


export const CostPage = () => {
    const classes = styles();
    const match = useRouteMatch(); 
    const history = useHistory();
    const location = history.location;
    const queryParams = useQueryParams();

    let defaultDays = parseInt(queryParams.days || 7, 10);
    const [dayFilter, setDayFilter] = React.useState(defaultDays);

    let filters = {};
    
    // only filter by days if it's set
    if(dayFilter) {
        filters.days = dayFilter;
    }

    useDeepCompareEffect(() => {
        location.search = '?'.concat(stringify(filters));
        history.push(location);
        fetchAll();
    }, [filters]);



    // --- cost by workspace
    const [costByWorkspaceResponse, costByWorkspaceStatus, fetchCostByWorkspace] = useCrudFetch(GET, 'adminCostByWorkspace', {filter: filters});
    const costByWorkspaceData = getCrudData(costByWorkspaceResponse);
    const costByWorkspaceArray = [];
    if(costByWorkspaceData) {
        Object.keys(costByWorkspaceData).forEach((workspace) => {
            costByWorkspaceArray.push({
                id: costByWorkspaceArray.length+1,   
                ...costByWorkspaceData[workspace],
                workspace
            });
        });
    }

    const [deploymentCostData, setDeploymentCostData] = React.useState(null);
    const viewDeploymentCost = (data) => {
        setDeploymentCostData(data);
    };

    const [workspacesCostData, setWorkspacesCostData] = React.useState(null);
    const viewWorkspacesCost = (data) => {
        setWorkspacesCostData(data);
    };

    const costByWorkspaceGridColumns = [
        {
            field: 'workspace', 
            headerName: 'Workspace', 
            flex: true,
            renderCell: (params) => {
                const record = params.row;
                const workspace = params.value;
                return record.active ? <RouterLink  to={{pathname: `/admin/workspaces/${workspace}`, search: `?return=${match.path}`}}>{workspace}</RouterLink> : `${workspace}`;
            }
        }, {
            field: 'active', 
            headerName: 'Status', 
            width: 100,
            valueGetter: (params) => params.value ? 'Active' : 'Inactive',
            renderCell: (params) => {
                return params.value ? <span style={{color: '#090'}}>Active</span> : <span style={{color: '#aaa'}}>Inactive</span>;
            }
        }, {
            field: 'owner', 
            headerName: 'Owner', 
            width: 350
        }, {
            field: 'deployments_count', 
            headerName: 'Deployments', 
            type: 'number', 
            width: 150,
            cellClassName: classes.gridUndoCellRenderStyling,
            valueGetter: (params) => params.row.deployments.length,
            renderCell: (params) => {
                const deployments = params.row.deployments;
                const count = deployments.length;
                return count > 0 ? <Link component="button" onClick={() => {viewDeploymentCost(deployments)}}>{count}</Link> : ' ';
            }
        }, {
            field: 'cost', 
            type: 'number',
            width: 140,
            headerName: 'Cost', 
            valueFormatter: (params) => {
                return '$' + numberFormat(params.value, 2);
            }
        }, /* {
            field: '',
            headerName: 'Actions',
            width: 100,
            disableClickEventBubbling: true,
            disableColumnMenu: true,
            renderCell: (params) => {
                return <Button variant="text" color="primary">Details</Button>
            }
        } */
    ];

    // --- cost by user
    const [costByUserResponse, costByUserStatus, fetchCostByUser] = useCrudFetch(GET, 'adminCostByUsers', {filter: filters});
    const costByUserData = getCrudData(costByUserResponse);
    const costByUserArray = [];
    if(costByUserData) {        
        Object.keys(costByUserData).forEach((user) => {
            const workspaces = costByUserData[user];
            let totalCost = 0;
            let deployments = [];
            let workspacesArray = [];

            Object.keys(workspaces).forEach((workspace) => {
                workspacesArray.push(workspaces[workspace]);
                totalCost += workspaces[workspace].cost || 0;
                if(Array.isArray(workspaces[workspace].deployments)) {
                    deployments = deployments.concat(workspaces[workspace].deployments);
                }
            });
            costByUserArray.push({
                id: costByUserArray.length+1,   
                user,
                workspaces: workspacesArray,
                deployments,
                cost: totalCost
            });
        });
    }

    const costByUserGridColumns = [
        {
            field: 'user', 
            headerName: 'User', 
            flex: true
        }, {
            field: 'workspaces_count', 
            headerName: 'Workspaces', 
            type: 'number', 
            width: 180,
            cellClassName: classes.gridUndoCellRenderStyling,
            valueGetter: (params) => params.row.workspaces.length,
            renderCell: (params) => {
                const workspaces = params.row.workspaces;
                return workspaces.length > 0 ? <Link component="button" onClick={() => {viewWorkspacesCost(workspaces)}}>{workspaces.length}</Link> : ' ';
            }
        }, {
            field: 'deployments_count', 
            headerName: 'Deployments', 
            type: 'number', 
            width: 150,
            cellClassName: classes.gridUndoCellRenderStyling,
            valueGetter: (params) => params.row.deployments.length,
            renderCell: (params) => {
                const deployments = params.row.deployments;
                const count = deployments.length;
                return count > 0 ? <Link component="button" onClick={() => {viewDeploymentCost(deployments)}}>{count}</Link> : ' ';
            }
        }, {
            field: 'cost', 
            type: 'number',
            width: 140,
            headerName: 'Cost', 
            valueFormatter: (params) => {
                return '$' + numberFormat(params.value, 2);
            }
        }
    ];


    // --- cost by solution
    /*
    const [, costBySolutionStatus, fetchCostBySolution] = useCrudFetch(GET, 'adminCostBySolutions');
    const costBySolutionData = getCrudData(useCrudProps('adminCostBySolutions'));
    const costBySolutionArray = [];
    if(costBySolutionData) {        
        Object.keys(costBySolutionData).forEach((workspace) => {
            costBySolutionArray.push({
                id: costBySolutionArray.length+1,   
            });
        });
    }*/

    // ---- overall cost
    const [costResponse, costStatus, fetchCost] = useCrudFetch(GET, 'adminCost', {filter: filters});
    const costData = getCrudArrayData(costResponse);

    const isLoading = costByWorkspaceStatus > 0         
        || costByUserStatus > 0
        // || costBySolutionStatus > 0
        || costStatus > 0;

    const fetchAll = () => {
        fetchCost();
        fetchCostByWorkspace();
        fetchCostByUser();
        // fetchCostBySolution();
    };

    let costs = {
        total: 0,
        awsTotal: 0,
        azureTotal: 0,
        workspaces: [],
    };

    if(costData) {
        // reset
        costs = {
            total: 0,
            awsTotal: 0,
            azureTotal: 0,
            workspaces: [],
        };
        const workspacesById = {};

        costData.forEach((deployment) => {
            if(deployment.workspace) {
                if(!workspacesById[deployment.workspace]) {
                    workspacesById[deployment.workspace] = {
                        cloud: deployment.cloud,
                        cloud_subscription: deployment.cloud_subscription,
                        workspace: deployment.workspace,
                        cost: deployment.cost,
                        deployments: 1,
                    };
                } else {
                    workspacesById[deployment.workspace].deployments += 1;
                    workspacesById[deployment.workspace].cost += deployment.cost;
                }
            }
            if(deployment.cloud === 'AWS') {
                costs.awsTotal += deployment.cost || 0;
            }
            if(deployment.cloud === 'Azure') {
                costs.azureTotal += deployment.cost || 0;
            }
        });

        Object.keys(workspacesById).forEach((id, i) => {
            costs.workspaces.push({
                id: i+1, // for data grid
                ...workspacesById[id]
            });
        });

        costs.total = costs.awsTotal + costs.azureTotal;
    }


    const onIntervalChange = (event) => {
        let value = event && (event.target ? event.target.value : event);
        value = parseInt(value, 10);
        setDayFilter(isNaN(value) ? 1 : value);
    };


    

    return(
        <React.Fragment>
            <DeploymentsDetailsDialog deployments={deploymentCostData} open={Boolean(deploymentCostData)} onClose={() => {setDeploymentCostData(null);}} />
            <WorkspacesDetailsDialog workspaces={workspacesCostData} open={Boolean(workspacesCostData)} onClose={() => {setWorkspacesCostData(null);}} />
            
            <Heading icon={AttachMoneyIcon}>Cost Explorer</Heading>
            <Grid container spacing={1} alignItems="center" justify="flex-end">
                <Grid item>
                    <MUITextField
                        style={{minWidth: 150, backgroundColor: '#fff'}}
                        select
                        label="Days"
                        variant="outlined"
                        value={dayFilter}
                        onChange={onIntervalChange}
                    >
                        {intervalChoices.map((choice, i) => {
                            return <MenuItem key={i} value={choice.value}>{choice.name}</MenuItem>
                        })}
                    </MUITextField>
                </Grid>
                <Grid item><Button onClick={fetchAll} disabled={isLoading} processing={isLoading}>Refresh</Button></Grid>
            </Grid>

            <br />
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h3">${numberFormat(costs.total, 2)}</Typography>
                            <Typography variant="subtitle2">Total</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h3">${numberFormat(costs.awsTotal, 2)} <small>({numberFormat((costs.awsTotal / costs.total) * 100, 2)}%)</small></Typography>
                            <Typography variant="subtitle2">AWS Total</Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardContent>
                            <Typography variant="h3">${numberFormat(costs.azureTotal, 2)} <small>({numberFormat((costs.azureTotal / costs.total) * 100, 2)}%)</small></Typography>
                            <Typography variant="subtitle2">Azure Total</Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <br />
            <Heading>Cost by Workspace</Heading>
            <Card>                
                <div style={{height: 500, width: '100%'}}>
                    <DataGrid 
                        loading={costByWorkspaceStatus > 0}
                        density="compact" 
                        rows={costByWorkspaceArray} 
                        columns={costByWorkspaceGridColumns} 
                        disableSelectionOnClick={true}
                        sortModel={sortModels.costByWorkspace}
                        pageSize={50} />
                </div>
            </Card>
                
            <br />
            <Heading>Cost by User</Heading>
            <Card>
                <div style={{height: 500, width: '100%'}}>
                    <DataGrid 
                        loading={costByUserStatus > 0}
                        density="compact" 
                        rows={costByUserArray} 
                        columns={costByUserGridColumns} 
                        disableSelectionOnClick={true}
                        sortModel={sortModels.costByUser}
                        pageSize={50} />
                </div>
            </Card>

        </React.Fragment>
    );
};

export default CostPage;
